package com.sludg.util.models

import com.sludg.util.models.DashboardComponentModels.DashboardComponent
import com.sludg.util.models.GroupingModels.{
  RingStats,
  TalkStats,
  TotalDurationStats
}

import cats.Eq
import cats.Show

object DashboardModels {

  sealed trait DashboardType {
    val tenantId: Int
    val subscriberId: Int
    val name: String
    val shared: Boolean
    val favourited: Boolean
  }

  object DashboardType {
    implicit val eq = Eq.fromUniversalEquals[DashboardType]
    implicit val show = Show.fromToString[DashboardType]
  }

  case class ExtensionStats(
      extension: String,
      total: String,
      talkStats: TalkStats,
      ringStats: RingStats,
      durationStats: TotalDurationStats
  )

  object ExtensionStats {
    implicit val eq = Eq.fromUniversalEquals[ExtensionStats]
    implicit val show = Show.fromToString[ExtensionStats]
  }

  case class Dashboard(
      id: Int,
      override val tenantId: Int,
      override val subscriberId: Int,
      override val name: String,
      override val shared: Boolean,
      override val favourited: Boolean
  ) extends DashboardType

  object Dashboard {
    implicit val eq = Eq.fromUniversalEquals[Dashboard]
    implicit val show = Show.fromToString[Dashboard]
  }

  case class DashboardData(
      dashboard: List[DashboardComponent],
      name: String,
      shared: Boolean,
      favourited: Boolean
  )

  object DashboardData {
    implicit val eq = Eq.fromUniversalEquals[DashboardData]
    implicit val show = Show.fromToString[DashboardData]
  }

  case class UnsavedDashboard(
      override val tenantId: Int,
      override val subscriberId: Int,
      override val name: String,
      override val shared: Boolean,
      override val favourited: Boolean
  ) extends DashboardType

  object UnsavedDashboard {
    implicit val eq = Eq.fromUniversalEquals[UnsavedDashboard]
    implicit val show = Show.fromToString[UnsavedDashboard]
  }

}
