package com.sludg.models

import com.sludg.models.Config.{AlmostAuth0Config, ApiConfig, Config, VueColors}
import com.sludg.util.models.DashboardModels.Dashboard
import play.api.libs.json.{Format, JsObject, Json}

object Model {

  import com.sludg.json.Deserializer._


  implicit val analyticsDConfigFormat: Format[DashboardConfig] =
    Json.format[DashboardConfig]

  /**
    *
    * @param auth0     //Auth0 config
    * @param vuetify   //Vuetify theme
    * @param apiConfig //Host and protocol config
    * @param otherApps //List of vtsl applications
    * @param logLevel  //The level of logging, eg error, debug, info
    */

  case class DashboardConfig(
                              auth0: AlmostAuth0Config,
                              vuetify: VueColors,
                              apiConfig: ApiConfig,
                              otherApps: List[JsObject],
                              logLevel: Option[org.log4s.LogLevel]
                            ) extends Config

  sealed trait DashboardSelectorOption

  case class DashboardOption(dashboard: Dashboard) extends DashboardSelectorOption

}
