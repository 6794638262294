package com.sludg.helpers

import com.sludg.auth0.SludgToken
import com.sludg.helpers.AppSetup.VtslAppProps
import com.sludg.models.Models.{AccessForbidden, SubscriberWithAdminStatus}
import com.sludg.pages.mainpage.grid.cells.widget_1.components.InOutCallStats.{
  CallStatsData,
  CallStatsProps,
  TableItems
}
import com.sludg.pages.mainpage.grid.cells.widget_1.components.UserPresence.{
  UserPresenceData,
  UserPresenceProps
}
import com.sludg.pages.mainpage.grid.cells.widget_1.components.UserStats.{
  UserStatsData,
  UserStatsProps
}
import com.sludg.helpers.States.UserType.{AdminUser, StandardUser, SuperUser}
import com.sludg.pages.PageOne.PageOneData
import com.sludg.pages.mainpage.grid.cells.widget_1.components.CallGroup.{
  CallGroupData,
  CallGroupProps
}
import com.sludg.pages.mainpage.grid.cells.widget_1.GenericComponent.DashboardComponentScopedSlots
import com.sludg.pages.mainpage.grid.Grid.{
  GridComponentData,
  GridComponentProps,
  GridComponentComputed
}
import com.sludg.pages.mainpage.toolbar.Toolbar.{ToolbarData, ToolbarProps}
import com.sludg.helpers.States.EditingMode
import com.sludg.helpers.States.EditingMode.Editing
import com.sludg.pages.mainpage.grid.cells.widget_1.ExtensionSelector.{
  ExtensionSelectorEvents,
  ExtensionSelectorProps
}
import org.log4s.getLogger
import com.sludg.vue.RenderHelpers._

import scala.reflect.ClassTag
import scala.scalajs.js.UndefOr
import com.sludg.vuetify.components.{VDataTableItemSlotData, VIconProps}
import com.sludg.vue.{EventBindings, RenderHelpers, RenderOptions, ScopedSlots, VueProps, _}
import com.sludg.vuetify.VuetifyComponents.{vFlex, vIcon}
import com.sludg.vuetify.components.grid.VFlexProps
import scala.collection.immutable.List
import scala.scalajs.js

object Helper {
  private[this] val logger = getLogger

  type VComponent = RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots]
  type DashboardsApp = RenderHelpers.NodeRenderer[VtslAppProps, EventBindings, ScopedSlots]
  type ToolbarType = VueComponent[_ <: ToolbarProps, _ <: Slots, _ <: ScopedSlots]
    with ToolbarData
    with js.Object
    with ToolbarProps
  type UserStatsType =
    VueComponent[_ <: UserStatsProps, _ <: Slots, _ <: DashboardComponentScopedSlots]
      with UserStatsData
      with js.Object
      with UserStatsProps
  type CallGroupType =
    VueComponent[_ <: CallGroupProps, _ <: Slots, _ <: DashboardComponentScopedSlots]
      with CallGroupData
      with js.Object
      with CallGroupProps
  type UserPresenceType =
    VueComponent[_ <: UserPresenceProps, _ <: Slots, _ <: DashboardComponentScopedSlots]
      with UserPresenceData
      with js.Object
      with UserPresenceProps
  type CallStatsType =
    VueComponent[_ <: CallStatsProps, _ <: Slots, _ <: DashboardComponentScopedSlots]
      with CallStatsData
      with js.Object
      with CallStatsProps
  type PageOneType = VueComponent[_ <: VueProps, _ <: Slots, _ <: ScopedSlots]
    with PageOneData
    with js.Object
    with VueProps
    with WithRefs[_ <: Refs]
  type GridType = VueComponent[_ <: GridComponentProps, _ <: Slots, _ <: ScopedSlots]
    with GridComponentData
    with GridComponentComputed
    with GridComponentProps
  type VC = VueComponent[_ <: ToolbarProps, _ <: Slots, _ <: ScopedSlots]
  type ExtensionSelectorType = VueComponent[_, _, _] with ExtensionSelectorProps

  type VueEventUndefined[T] = js.UndefOr[js.Function1[T, Unit]]

  case class VueEvenBuddy[T](v: VueEventUndefined[T], s: String)

  type rawEvent = (VueEventUndefined[_], String)

  def eventProcessor(events: rawEvent*)(bindings: EventBindings): Unit = {
    events.foreach(a =>
      if (a._1.isDefined) {
        bindings.asInstanceOf[js.Dynamic].updateDynamic(a._2)(a._1)
      }
    )
  }

  def checkUserType(sludgToken: SludgToken): States.UserType = {
    (sludgToken.isSuperUser, sludgToken.subscribers) match {
      case (true, _) => SuperUser
      case (false, f) if f.headOption.exists(s => s.admin) => AdminUser
      case _ => StandardUser
    }
  }

  /**
    * Formats time from seconds to Days, Hours, Minutes, Seconds format
    * ex 2h 25m, 6d 2h, 10m 55s, 1d 4h
    * @return
    */

  def formatTime: String => String = (ss: String) => {
    val s = Math.round(ss.toFloat)

    val MINUTE = 60
    val HOUR = 3600
    val DAY = 86400

    lazy val seconds = if (s < MINUTE) s else s % MINUTE
    lazy val minutes = if (s < HOUR) s / MINUTE else s % HOUR / MINUTE
    lazy val hours = if (s < DAY) s / HOUR else s % DAY / HOUR
    lazy val days = s / DAY

    s match {
      case s if s < MINUTE => s"${seconds}s"
      case s if s < HOUR => s"${minutes}m ${seconds}s"
      case s if s < DAY => s"${hours}h  ${minutes}m ${seconds}s"
      case _ => s"${days}d ${hours}h  ${minutes}m ${seconds}s"
    }
  }

  val resultProcessor: Either[AccessForbidden, Unit] => Unit = {
    case Left(error) => {
      error match {
        case AccessForbidden(error) => logger.error("Error: " + error)
        case a => logger.error("Error: " + a)
      }
    }
    case Right(_) => logger.debug("Success.")
  }

  val undefined: UndefOr[Nothing] = js.undefined
  type TableRow = js.UndefOr[js.Function1[js.Dynamic, VNode]]

  /* Helpers that help with processing Api-Call Results */
  def errorCase[T: ClassTag]()
      : PartialFunction[Either[AccessForbidden, Option[T]], Left[AccessForbidden, T]] = {
    case Left(x) => Left(x)
  }

  def successCase[Y: ClassTag]()
      : PartialFunction[Either[AccessForbidden, Option[Y]], Right[AccessForbidden, Y]] = {
    case Right(Some(x: Y)) => Right(x: Y)
  }

  def failCase[Y: ClassTag]()
      : PartialFunction[Either[AccessForbidden, Option[Y]], Left[AccessForbidden, Y]] = { case _ =>
    Left(AccessForbidden("Could not deserialize Json."))
  }

  def processResult[T: ClassTag]
      : PartialFunction[Either[AccessForbidden, Option[T]], Either[AccessForbidden, T]] =
    errorCase[T]().orElse(successCase[T]()).orElse(failCase[T]())

  def lift1[T: ClassTag]()
      : PartialFunction[Either[AccessForbidden, T], Left[AccessForbidden, T]] = { case Left(x) =>
    Left(x)
  }

  def lift2[Y: ClassTag]()
      : PartialFunction[Either[AccessForbidden, Y], Right[AccessForbidden, Y]] = {
    case Right(x: Y) => Right(x: Y)
  }

  def liftType[T: ClassTag]
      : PartialFunction[Either[AccessForbidden, T], Either[AccessForbidden, T]] =
    lift1[T]().orElse(lift2())

  def buildRow(title: String*)(
      addSomeWeight: Boolean = true
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    tr(
      title.map(title =>
        td(
          p(
            title,
            RenderOptions(
              style = Some(
                js.Dynamic.literal(
                  "text-align" -> "center",
                  "font-size" -> (if (addSomeWeight) "20px"
                                  else "")
                )
              )
            )
          )
        )
      )
    )
  }

  def isEditing(editing: EditingMode): Boolean = editing == Editing

  def loadIcons(bool: Boolean) =
    vIcon(
      if (bool) "check" else "close",
      RenderOptions(
        props = Some(
          VIconProps(
            medium = Some(true),
            dark = Some(true),
            color = Some(if (bool) "green" else "red")
          )
        )
      )
    )

  def vFlexRow(
      s: String*
  ): Seq[RenderHelpers.NodeRenderer[VFlexProps, EventBindings, ScopedSlots]] = s.map(a => vFlex(a))

  def buildAdvancedRow(
      items: js.Dynamic,
      keys: String*
  ): List[RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots]] =
    keys
      .map(key =>
        td(
          RenderOptions(
            style = Some(
              js.Dynamic.literal(
                "width" -> "35%",
                "font-size" -> "12px"
              )
            )
          ),
          getItem(items, key)
        )
      )
      .toList

  def getItem(items: js.Dynamic, s: String): String = {
    items.selectDynamic("item").selectDynamic(s).toString
  }

  def getBItem(items: js.Dynamic, s: String): Boolean = {
    items.selectDynamic("item").selectDynamic(s).asInstanceOf[Boolean]
  }

}
