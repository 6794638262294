package com.sludg.models

import com.sludg.util.models.DashboardComponentModels.ComponentType
import com.sludg.util.models.ReportModels.Filter
import com.sludg.util.json.FiltersJsonDeserializers._

import scala.collection.immutable.List
import scala.scalajs.js
import scala.scalajs.js.UndefOr
import scala.scalajs.js.JSConverters._
import play.api.libs.json.Json

object ComponentFacade {

  /* Mapper to allow for tracking of each dashboard component in the grid */
  trait DashboardJSComponentData extends js.Object {
    val i, x, y, h, w, sTid: Int
    val title: UndefOr[String] = js.undefined
    val filters: js.Array[
      String
    ] // Stored as serialised string, otherwise reactive properties go boom
    val componentType: String
    val componentConfig: UndefOr[String] = js.undefined
    val eventSubscription: UndefOr[String] = js.undefined
  }

  object DashboardJSComponentData {
    def apply(
        id: Int,
        xPos: Int,
        yPos: Int,
        width: Int,
        height: Int,
        tenantId: Int,
        titleIn: Option[String],
        filtersIn: js.Array[String] = js.Array(),
        componentTypeIn: ComponentType,
        componentConfigIn: Option[String],
        eventSubscriptionIn: Option[String]
    ): DashboardJSComponentData = {
      js.Dynamic
        .literal(
          i = id,
          x = xPos,
          y = yPos,
          w = width,
          h = height,
          sTid = tenantId,
          title = titleIn.orUndefined,
          filters = filtersIn,
          componentType = ComponentType.objectionToString(componentTypeIn),
          componentConfig = componentConfigIn.orUndefined,
          eventSubscription = eventSubscriptionIn.orUndefined
        )
        .asInstanceOf[DashboardJSComponentData]
    }
  }
}
