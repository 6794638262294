package com.sludg.pages.mainpage.grid.cells.widget_1.components

import com.sludg.FieldExtractor
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.helpers.States.{EditingMode, PriorityOrder}
import com.sludg.scalajs.DynamicHelper
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels.{Subscriber, Tenant}
import org.log4s._

import scala.scalajs.js.JSConverters._
import com.sludg.models.Models.AccessForbidden
import com.sludg.json.JsonDeserialiser._

import scala.scalajs.js
import scala.concurrent.Future
import com.sludg.auth0.SludgToken
import com.sludg.helpers.States.EditingMode.Editing
import com.sludg.helpers.Helper._
import scala.collection.immutable.{::, List, Nil}
import com.sludg.vuetify.components._
import com.sludg.util.models.DashboardModels.ExtensionStats
import com.sludg.util.models.ReportModels.Filter
import play.api.libs.json.Json
import com.sludg.pages.mainpage.config.DashboardConfig.{
  ComponentConfiguration,
  UserStatsComponentConfig
}
import com.sludg.util.models.GroupingModels.{RingStats, TalkStats, TotalDurationStats}
import cats.data.EitherT
import com.sludg.helpers.{LoadingFuture, States}
import play.api.libs.json.{JsValue => _, Reads => _, _}
import cats.implicits._
import com.sludg.pages.mainpage.grid.cells.widget_1.GenericComponent.DashboardComponentScopedSlots
import com.sludg.helpers.States.Ordering.{Ascending, Descending, Neutral}
import com.sludg.pages.mainpage.grid.cells.widget_1.ExtensionSelector.{
  ExtensionSelectorEvents,
  ExtensionSelectorProps
}
import com.sludg.pages.mainpage.grid.cells.widget_1.ExtensionSelector
import com.sludg.util.models.ReportModels.Filter.{
  DirectionFilter,
  ExtensionFilter,
  RelativeDateFilter
}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vuetify.components.grid.VFlexProps
import monix.execution.Scheduler.Implicits.global
import com.sludg.pages.mainpage.grid.cells.widget_1.GenericComponent._
import com.sludg.util.models.CallModels.Direction
import com.sludg.util.models.CallModels.Direction.{Inbound, Internal, Outbound}

object UserStats {

  val logger: Logger = getLogger

  val style: RenderOptions[Nothing, Nothing, Nothing] =
    RenderOptions(style = Some(js.Dynamic.literal("position" -> "relative", "bottom" -> "-13px")))

  def userStatsRenderer(
      registrationName: String
  ): RenderHelpers.NodeRenderer[UserStatsProps, EventBindings, ScopedSlots] =
    namedTag[UserStatsProps, EventBindings, ScopedSlots](registrationName)

  def userStatsComponent(
      loader: Vue,
      apiCalls: ApiCalls
  ): VueComponent[_ <: UserStatsProps, _ <: Slots, _ <: ScopedSlots] = {
    VueComponent.builder
      .withData(new UserStatsData)
      .withScopedSlots[DashboardComponentScopedSlots]
      .withPropsAs[UserStatsProps]
      .build(
        components = js.defined(
          js.Dynamic.literal("extensionSelector" -> ExtensionSelector.extensionSelectorComponent())
        ),
        watch = new EventsWatcher(),
        created = js.defined(u => {
          u.apiCalls = Some(apiCalls)
          u.loader = Some(loader)
          initialiseComponent(
            u.selectedTenant,
            loader,
            apiCalls,
            u.filters,
            u.token,
            u.componentConfig,
            u
          )
        }),
        templateOrRender = Right((c, renderer) =>
          div(
            RenderOptions(`class` = List(Left("background-white-full"))),
            c.$scopedSlots.componentTitle.foldToNode(x => x(c.editing), nothing),
            editButton(c, loader, apiCalls)(c.token),
            if (c.editing && c.editMode == Editing) editScreen(c.height, renderer, c)
            else mainScreen(renderer, c)
          ).render(renderer)
        )
      )
  }

  def initialiseComponent(
      tenantId: Int,
      loader: Vue,
      apiCalls: ApiCalls,
      filters: List[Filter],
      token: SludgToken,
      cc: UserStatsComponentConfig,
      u: UserStatsType
  ): Unit = {
    //Initial call to get all data
    retrieveData(tenantId, loader, filters, apiCalls)(token).map {
      case Left(errorResult) => resultProcessor(Left(errorResult))
      case Right(x) => setData(cc, x._1, x._2, u)
    }
  }

  /**
    * Method to retrieve user stats and subscriber data
    *
    * @param tenantId
    * @param loader
    * @param filters
    * @param apiCalls
    * @param token
    * @return
    */
  def retrieveData(tenantId: Int, loader: Vue, filters: List[Filter], apiCalls: ApiCalls)(implicit
      token: SludgToken
  ): Future[Either[AccessForbidden, (List[ExtensionStats], List[Subscriber])]] =
    LoadingFuture.withLoading(
      loader,
      (for {
        callStats <- EitherT(apiCalls.getInfo(tenantId, filters).map(processResult))
        subscribers <- EitherT(apiCalls.getSubscribers(tenantId))
      } yield {
        (callStats, subscribers)
      }).value
    )

  /**
    * Sets the data of the table, generates any missing user rows
    *
    * @param userStatsComponentConfig
    * @param data
    * @param subscribers
    * @param c
    */
  def setData(
      userStatsComponentConfig: UserStatsComponentConfig,
      data: List[ExtensionStats],
      subscribers: List[Subscriber],
      c: UserStatsType
  ): Unit = {
    c.tableColumnSorting = userStatsComponentConfig.prioritySorting
    c.checkboxInitialValues = userStatsComponentConfig.checkBoxes

    c.rawTableData = data.map(a =>
      a.copy(
        durationStats =
          a.durationStats.copy(average = a.durationStats.average.map(a => Math.round(a).toDouble)),
        talkStats =
          a.talkStats.copy(average = a.talkStats.average.map(a => Math.round(a).toDouble)),
        ringStats = a.ringStats.copy(average = a.ringStats.average.map(a => Math.round(a).toDouble))
      )
    )

    c.selectableSubscribers = subscribers.sortBy(_.extension)
    c.tableColumnSorting = userStatsComponentConfig.prioritySorting

    val extensionFilter = c.filters.collectFirst { case i: ExtensionFilter => i }
    extensionFilter match {
      case Some(e) => {
        val selectedSubscribers =
          c.selectableSubscribers.filter(a => e.extensions.contains(a.extension))
        c.selectedSubscribers = selectedSubscribers.toJSArray

        val statsData = c.rawTableData

        if (selectedSubscribers == Nil) {
          c.tableItems = sortTableData(
            Some(tableItemsListGenerator(c.rawTableData)),
            c.tableColumnSorting,
            c.checkboxInitialValues
          )
        } else {
          val statsDataExtensions = statsData.map(
            _.extension.drop(5).dropRight(1).split("-").headOption.getOrElse("not_found").trim
          )

          val extensionNameDictionary: Map[String, String] = c.selectableSubscribers
            .map(a =>
              a.extension -> (a.extension + " - " + a.firstName.getOrElse("") + " " + a.lastName)
            )
            .toMap

          val stubsForMissingData: List[ExtensionStats] = selectedSubscribers
            .map(_.extension)
            .filterNot(statsDataExtensions.toSet)
            .map(a =>
              ExtensionStats(
                extensionNameDictionary.getOrElse(a, "not_found"),
                "0",
                TalkStats(0, None, None, None),
                RingStats(0L, None, None, None),
                TotalDurationStats(0L, None, None, None)
              )
            )
          c.tableItems = sortTableData(
            Some(
              tableItemsListGenerator(
                statsData.map(a =>
                  a.copy(extension = a.extension.drop(5).dropRight(1))
                ) ::: stubsForMissingData
              )
            ),
            c.tableColumnSorting,
            c.checkboxInitialValues
          )
        }
      }
      case None => print("")
    }
    c.checkboxInitialValues = userStatsComponentConfig.checkBoxes
  }

  //Converts ExtensionStats to TableItems
  def tableItemsListGenerator: List[ExtensionStats] => List[TableItems] = {
    es: List[ExtensionStats] =>
      es.map(a =>
        new TableItems(
          (a.extension),
          (a.total),
          ((a.talkStats.sum.toString)),
          (a.talkStats.min.map(_.toString()).getOrElse("0")),
          (a.talkStats.max.map(_.toString()).getOrElse("0")),
          (a.talkStats.average.map(_.toString()).getOrElse("0")),
          (a.ringStats.sum.toString()),
          (a.ringStats.min.map(_.toString).getOrElse("0")),
          (a.ringStats.max.map(_.toString).getOrElse("0")),
          (a.ringStats.average.map(_.toString).getOrElse("0")),
          (a.durationStats.sum.toString),
          (a.durationStats.min.map(_.toString).getOrElse("0")),
          (a.durationStats.max.map(_.toString).getOrElse("0")),
          (a.durationStats.average.map(_.toString).getOrElse("0"))
        )
      )
  }

  def editButton(c: UserStatsType, loader: Vue, apiCalls: ApiCalls)(implicit
      token: SludgToken
  ): RenderHelpers.NodeRenderer[VFlexProps, EventBindings, ScopedSlots] = {
    vFlex(
      if (c.editMode == EditingMode.Editing) {
        div(
          vButton(
            if (!c.editing) vIcon("edit") else vIcon("save"),
            RenderOptions(
              style = Some(
                js.Dynamic.literal(
                  "height" -> "25px",
                  "width" -> "25px",
                  "position" -> "absolute",
                  "right" -> "50px",
                  "top" -> "6px",
                  "z-index" -> "15"
                )
              ),
              props = Some(VButtonProps(icon = Some(true))),
              on = Some(
                EventBindings(click = js.defined(e => {
                  retrieveData(c.selectedTenant, loader, c.filters, apiCalls).map {
                    case Left(errorResult) => resultProcessor(Left(errorResult))
                    case Right(x) => {
                      setData(c.componentConfig, x._1, x._2, c)
                      c.editing = !c.editing
                    }
                  }
                }))
              )
            )
          )
        )
      } else {
        div()
      }
    )
  }

  case class DataWithPosition(pos: Int, text: String)

  def mainScreen(renderer: CreateElement, c: UserStatsType): RenderFunction[VNode] = {
    div(
      RenderOptions(`class` = List(Left("white-Background-scroll-y"))),
      summaryData(renderer, c.tableItems.getOrElse(List()), c),
      dataTable(renderer, c)
    )
  }

  import com.sludg.vuetify.components._
  import com.sludg.vuetify.components.VDialogProps

  def summaryData(
      renderer: CreateElement,
      tableItems: List[TableItems],
      c: UserStatsType
  ): RenderFunction[VNode] = {
    div(
      vLayout(
        RenderOptions(`class` = List(Left("center-text"))),
        filterSelectedSummaryData(
          c.checkboxInitialValues,
          List(
            ("Total Calls", tableItems.map(_.total.toInt).sum.toString),
            ("Total talk time", tableItems.map(_.totalTalkTime.toInt).sum.toString),
            (
              "Min talk time",
              tableItems.map(_.minTalkTime.toInt).minimumOption.getOrElse(0).toString
            ),
            (
              "Max talk time",
              tableItems.map(_.maxTalkTime.toInt).maximumOption.getOrElse(0).toString
            ),
            ("Avg talk time", (tableItems.map(_.avgTalkTime.toInt).sum / tableItems.size).toString),
            ("Total Ring time", tableItems.map(_.totalRingTime.toInt).sum.toString),
            (
              "Min Ring time",
              tableItems.map(_.minRingTime.toInt).minimumOption.getOrElse(0).toString
            ),
            (
              "Max Ring time",
              tableItems.map(_.maxRingTime.toInt).maximumOption.getOrElse(0).toString
            ),
            ("Avg Ring time", (tableItems.map(_.avgRingTime.toInt).sum / tableItems.size).toString),
            ("Total time", tableItems.map(_.totalDurationTime.toInt).sum.toString),
            (
              "Min time",
              tableItems.map(_.minDurationTime.toInt).minimumOption.getOrElse(0).toString
            ),
            (
              "Max time",
              tableItems.map(_.maxDurationTime.toInt).maximumOption.getOrElse(0).toString
            ),
            ("Avg time", (tableItems.map(_.totalDurationTime.toInt).sum / tableItems.size).toString)
          ).map(a =>
            vLayout(
              vFlex(
                vFlex(a._1),
                vFlex(
                  if (a._1 != "Total Calls") formatTime(a._2) else a._2,
                  RenderOptions(style =
                    Some(js.Dynamic.literal("font-weight" -> "bolder", "font-size" -> "20px"))
                  )
                )
              ),
              vDivider(
                RenderOptions(props = Some(VDividerProps(vertical = Some(true))))
              )
            )
          )
        )
      )
    )
  }

  def dataTable(renderer: CreateElement, c: UserStatsType): RenderFunction[VNode] = {
    vCard(
      RenderOptions(style = Some(js.Dynamic.literal("margin-top" -> "25px"))),
      div(
        VDataTable.vDataTable(
          RenderOptions[VDataTableProps[
            TableItems,
            VDataTableHeader
          ], EventBindings, VDataTableScopedSlots[TableItems, VDataTableHeader]](
            props = Some(
              VDataTableProps[TableItems, VDataTableHeader](
                //Generate headers based on selected column values
                headers = Some(
                  (filterSelected(
                    c.checkboxInitialValues,
                    List(
                      "Name",
                      "Calls",
                      "Total talk time",
                      "Min talk time",
                      "Max talk time",
                      "Avg talk time",
                      "Total Ring time",
                      "Min Ring time",
                      "Max Ring time",
                      "Avg Ring time",
                      "Total time",
                      "Min time",
                      "Max time",
                      "Avg time"
                    )
                  ) ::: List(DataWithPosition(13, ""))).map(title =>
                    new VDataTableHeader(
                      sortable = c.editMode == EditingMode.Editing,
                      text = title.text,
                      value = title.pos.toString
                    )
                  )
                ),
                items = c.tableItems,
                value = c.tableItems,
                `hide-actions` = Some(true),
                dense = Some(true)
              )
            ),
            scopedSlots = Some(
              new VDataTableScopedSlots[TableItems, VDataTableHeader](
                items = js.defined(i =>
                  {
                    tr(
                      filterSelected(
                        c.checkboxInitialValues,
                        List(
                          i.item.name,
                          i.item.total,
                          i.item.totalTalkTime,
                          i.item.minTalkTime,
                          i.item.maxTalkTime,
                          i.item.avgTalkTime,
                          i.item.totalRingTime,
                          i.item.minRingTime,
                          i.item.maxRingTime,
                          i.item.avgRingTime,
                          i.item.totalDurationTime,
                          i.item.minDurationTime,
                          i.item.maxDurationTime,
                          i.item.avgDurationTime
                        )
                      ).map(value =>
                        td(
                          RenderOptions(`class` =
                            if (value.pos > 0) List(Left("style_7")) else List()
                          ),
                          if (value.pos > 1) formatTime(value.text) else value.text
                        )
                      ) ::: rowDeletionButton(
                        c,
                        i
                      )
                    )
                  }.render(renderer)
                ),
                headers = js.defined(props => {

                  val ro = RenderOptions(style =
                    Some(
                      js.Dynamic
                        .literal("height" -> "20px", "max-width" -> "20px", "min-width" -> "15px")
                    )
                  )

                  tr(
                    RenderOptions(style = Some(js.Dynamic.literal("height" -> "45px"))),
                    props.headers.map(header => {
                      val pos: Int = header.value.getOrElse("0").toInt
                      val sortState: PriorityOrder = c.tableColumnSorting(pos.toInt)

                      th(
                        vLayout(
                          vFlex(ro, vIcon("arrow_upward")),
                          vFlex(
                            ro,
                            p((if (isHeaderPriorityVisible(sortState)) {
                                 if (sortState.priority < 7)
                                   div(
                                     RenderOptions(`class` =
                                       List(
                                         Left(
                                           s"zmdi zmdi-n-${sortState.priority.toString()}-square"
                                         )
                                       )
                                     )
                                   )
                                 else div(sortState.priority.toString())
                               } else {
                                 div("")
                               }))
                          ),
                          vFlex(
                            header.text.getOrElse("").toString(),
                            RenderOptions(style =
                              Some(
                                js.Dynamic.literal("font-weight" -> "bold", "text-align" -> "left")
                              )
                            )
                          )
                        ),
                        RenderOptions(
                          props = Some(
                            js.Dynamic
                              .literal("key" -> header.text.get.asInstanceOf[js.Any])
                              .asInstanceOf[VueProps]
                          ),
                          style = Some(js.Dynamic.literal("white-space" -> "normal")),
                          `class` = List(Left("column"), Left("sortable")) ::: (sortState match {
                            case PriorityOrder(Ascending, n) => List(Left("active"), Left("asc"))
                            case PriorityOrder(Descending, n) => List(Left("active"), Left("desc"))
                            case PriorityOrder(Neutral, n) => List()
                          }),
                          on = Some(
                            EventBindings(
                              click = js.defined(d => {
                                val newSortState = changeSortState(sortState, c)
                                logger.debug("New sort: " + newSortState.newState)

                                newSortState.newState match {
                                  case value @ PriorityOrder(Neutral, _) => {
                                    //First we update the header
                                    c.tableColumnSorting =
                                      c.tableColumnSorting.updated(pos.toInt, value)

                                    //Old priority
                                    val oldPriority = newSortState.oldState.priority
                                    logger.debug(
                                      "Old priority: " + oldPriority + " is removed and set to 0."
                                    )

                                    //If we set the header to be neutral, all other header priorities are recalculated
                                    c.tableColumnSorting = c.tableColumnSorting
                                      .updated(pos.toInt, value)
                                      .map(otherHeader =>
                                        if (
                                          otherHeader.priority <= newSortState.oldState.priority
                                        ) {
                                          otherHeader
                                        } else {
                                          otherHeader.copy(priority = otherHeader.priority - 1)
                                        }
                                      )
                                    c.tableItems = sortTableData(
                                      c.tableItems,
                                      c.tableColumnSorting,
                                      c.checkboxInitialValues
                                    )

                                    sendUpdate(c)
                                  }
                                  case value @ PriorityOrder(Descending, _) => {
                                    //First we update the header
                                    c.tableColumnSorting =
                                      c.tableColumnSorting.updated(pos.toInt, value)

                                    c.tableItems = sortTableData(
                                      c.tableItems,
                                      c.tableColumnSorting,
                                      c.checkboxInitialValues
                                    )

                                    sendUpdate(c)
                                  }
                                  case value @ PriorityOrder(Ascending, _) => {
                                    c.tableColumnSorting =
                                      c.tableColumnSorting.updated(pos.toInt, value)
                                    c.tableItems = sortTableData(
                                      c.tableItems,
                                      c.tableColumnSorting,
                                      c.checkboxInitialValues
                                    )

                                    sendUpdate(c)
                                  }
                                }

                              })
                            )
                          )
                        )
                      )
                    })
                  ).render(renderer)
                })
              )
            )
          )
        )
      )
    )
  }

  /**
    * Used to sort the table items by the table headers and their configuration
    * A table header can be sorted by Ascending, Descending or Neutral
    * Each table header can have a priority, (in what priority the data is broken down)
    * (eg. by total calls first, then by extension, then by duration)
    *
    * * We filter out the headers that are not displayed by the user, then we map them to be either Ascending, Descending
    * * and we remove neutral ones from the list as they are not relevant
    * * We then sort by their priority, (eg. which columns are sorted by first), then we return that list into
    * * a sortBy function
    *
    * @param tableData         Unsorted List of TableItems
    * @param priorityOrderList The directionality of each header, and its priority
    * @param checkBoxValues    Which checkboxes are ticked
    * @return Sorted List of TableItems
    */
  def sortTableData(
      tableData: Option[List[TableItems]],
      priorityOrderList: List[PriorityOrder],
      checkBoxValues: List[Boolean]
  ): Option[List[TableItems]] = {
    tableData.map(
      _.sortBy(i =>
        filterSelected(
          checkBoxValues,
          List(
            i.name.toString().split("-").headOption.map(_.trim).getOrElse("0"),
            i.total.toString(),
            i.totalTalkTime,
            i.minTalkTime,
            i.maxTalkTime,
            i.avgTalkTime,
            i.totalRingTime,
            i.minRingTime,
            i.maxRingTime,
            i.avgRingTime,
            i.totalDurationTime,
            i.minDurationTime,
            i.maxDurationTime,
            i.avgDurationTime
          )
        ).flatMap(column =>
          priorityOrderList(column.pos) match {
            case PriorityOrder(Ascending, n) => Some(column.text.toInt, n)
            case PriorityOrder(Descending, n) => Some(-column.text.toInt, n)
            case PriorityOrder(Neutral, _) => None
          }
        ).sortBy(unprioritised => unprioritised._2)
          .map(_._1)
      )
    )
  }

  /**
    * Checks if the header priority numbering should be visible
    */
  def isHeaderPriorityVisible(po: PriorityOrder): Boolean = {
    po match {
      case PriorityOrder(Ascending | Descending, a) => true
      case _ => false
    }
  }

  /**
    * Here we mutate the sort state and the priority of the headers
    * If you set a header to be neutral, we recalculate the header priorities and set the new header priority to 0
    * If you set a header to be ascending, it has enetered the priority rankings, and gets a priority of last priority + 1
    */
  case class States(newState: PriorityOrder, oldState: PriorityOrder)

  def changeSortState(oldState: PriorityOrder, c: UserStatsType): States = {
    oldState match {
      case value @ PriorityOrder(Ascending, _) => States(value.copy(order = Descending), oldState)
      case value @ PriorityOrder(Descending, _) => States(value.copy(order = Neutral, 0), oldState)
      case value @ PriorityOrder(Neutral, _) => {
        logger.debug(
          "Free " + value.copy(
            order = Ascending,
            (c.tableColumnSorting.map(_.priority).sorted.last) + 1
          )
        )
        States(
          value.copy(order = Ascending, (c.tableColumnSorting.map(_.priority).sorted.last) + 1),
          oldState
        )
      }
    }
  }

  /** //TODO Generify for use across components - Zamir 1/4/20
    * Adds a button to remove a row in the table
    *
    * @param c
    * @return
    */
  def rowDeletionButton(
      c: UserStatsType,
      i: VDataTableItemSlotData[TableItems]
  ): List[NodeRenderer[VueProps, EventBindings, ScopedSlots]] = {
    List(
      td(
        RenderOptions(),
        if (c.editMode == EditingMode.Editing) {
          vButton(
            vIcon("clear"),
            RenderOptions(
              style =
                Some(js.Dynamic.literal("background-color" -> "#eaeaea", "margin-left" -> "-35px")),
              props = Some(VButtonProps(icon = Some(true))),
              on = Some(
                EventBindings(
                  click = js.defined(e => {
                    val extension: String =
                      i.item.name.toString().trim().split("-").headOption.getOrElse("not_found")

                    c.tableItems = Some(
                      c.tableItems
                        .getOrElse(List[TableItems]())
                        .filterNot(_.name.contains(i.item.name.toString()))
                    )

                    c.selectedSubscribers = c.selectedSubscribers
                      .filterNot(_.extension == extension.trim())
                      .sortBy(_.extension)

                    sendUpdate(c)
                  })
                )
              )
            )
          )
        } else {
          div()
        }
      )
    )
  }

  /**
    * @param c
    * @param direction        Optional Value which will be added to the filters for the component
    * @param updateInbound    Value to indicate which directional filter to update
    * @param updateInternal
    * @param updateOutbound
    * @return
    */
  def sendUpdate(
      c: UserStatsType,
      direction: Option[Direction] = None,
      updateInbound: Boolean = false,
      updateInternal: Boolean = false,
      updateOutbound: Boolean = false
  ) = {
    def updateOrGet(
        value: Option[Direction],
        typeOfDirection: Direction,
        toUpdate: Boolean
    ): Option[Direction] = if (toUpdate) value else getCurrent(typeOfDirection, c.filters)
    val updatedDirection = List(
      updateOrGet(direction, Inbound, updateInbound),
      updateOrGet(direction, Outbound, updateOutbound),
      updateOrGet(direction, Internal, updateInternal)
    ).flatten

    updateEventSubscriptionn(
      c,
      c.selectedTenant,
      Some(RelativeDateFilter(0)),
      Some(DirectionFilter(updatedDirection)),
      Some(ExtensionFilter(c.selectedSubscribers.map(_.extension).toList)),
      Some(
        Json
          .toJson(UserStatsComponentConfig(c.checkboxInitialValues, c.tableColumnSorting))
          .toString()
      )
    )

    ExtensionFilter(c.selectedSubscribers.map(_.extension).toList)
  }
  def editScreen(height: Int, renderer: CreateElement, c: UserStatsType): RenderFunction[VNode] = {
    div(
      RenderOptions(`class` = List(Left("white-Background-scroll-y"))),
      vTabs(
        RenderOptions(
          props = Some(
            VTabsProps(
              `fixed-tabs` = Some(true),
              grow = Some(true),
              value = Some(Left(c.selectedTab))
            )
          ),
          on = Some(EventBindings(change = js.defined(e => c.selectedTab = e.asInstanceOf[Int])))
        ),
        if (height < 3) {
          List(
            vTab("Extensions"),
            vTab("Call direction"),
            vTab("Columns"),
            vTabItem(div(extensionSelectorScreen(c)), VTabItemProps(value = Some(0))),
            vTabItem(div(disrectionSelectorScreen(c)), VTabItemProps(value = Some(1))),
            vTabItem(div(columnConfigScreen(renderer, c)), VTabItemProps(value = Some(2)))
          )
        } else {
          List(
            vTab("Filters"),
            vTab("Columns"),
            vTabItem(
              div(extensionSelectorScreen(c), disrectionSelectorScreen(c)),
              VTabItemProps(value = Some(0))
            ),
            vTabItem(div(columnConfigScreen(renderer, c)), VTabItemProps(value = Some(1)))
          )
        }
      )
    )
  }

  def columnConfigScreen(renderer: CreateElement, c: UserStatsType) = {
    div(
      vDataTable(
        RenderOptions(
          style = Some(js.Dynamic.literal("width" -> "100%", "position" -> "absolute")),
          props = Some(
            VDataTableProps[HeaderSelectionTable, VDataTableHeader](
              items = Some(
                List(
                  new HeaderSelectionTable("0", "Talk Time"),
                  new HeaderSelectionTable("4", "Ring Time"),
                  new HeaderSelectionTable("8", "Call Duration")
                )
              ),
              `hide-actions` = Some(true),
              headers = Some(
                List("", "Total", "Min", "Max", "Average", "").map(column =>
                  new VDataTableHeader(sortable = false, text = column)
                )
              )
            )
          ),
          scopedSlots = Some(
            new VDataTableScopedSlots[HeaderSelectionTable, VDataTableHeader](
              items = js.defined(i =>
                {
                  tr(
                    td(p(i.item.title)),
                    List.range(0, 4).map(n => td(buildCheckbox(i.item.offset.toInt + n, c)))
                  )
                }.render(renderer)
              )
            )
          )
        )
      )
    )
  }

  def extensionSelectorScreen(
      c: UserStatsType
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    div(
      RenderOptions(style = Some(js.Dynamic.literal("margin-top" -> "25px"))),
      vLayout(
        vFlex(
          RenderOptions(`class` = List(Left("background-white"))),
          extensionSelector(
            RenderOptions(
              props = Some(
                new ExtensionSelectorProps(
                  c.selectableSubscribers,
                  c.selectedSubscribers.toList,
                  Some((c.width * 1.5).toInt)
                )
              ),
              on = Some(
                new ExtensionSelectorEvents(
                  selectionUpdate = js.defined(e => {
                    c.selectedSubscribers = e.sortBy(_.extension).toJSArray
                    sendUpdate(c)
                  })
                )
              )
            )
          )
        )
      )
    )
  }

  def switchMAker(
      title: String,
      subTitle: String,
      value: Boolean,
      c: UserStatsType,
      event: EventBindings
  ) = {
    vListTile(
      vListTileAction(
        vSwitch(
          RenderOptions(
            props = Some(
              VSwitchProps(
                `input-value` = Some(value)
              )
            ),
            on = Some(event)
          )
        )
      ),
      vListTileContent(
        vListTileTitle(title),
        vListTileSubTitle(
          RenderOptions(style = Some(js.Dynamic.literal("font-size" -> "11px"))),
          subTitle
        )
      )
    )
  }

  def getCurrent[A](direction: A, filters: List[Filter]): Option[A] = {
    filters.collectFirst {
      case DirectionFilter(directions) if directions.contains(direction) => direction
    }
  }

  def disrectionSelectorScreen(
      c: UserStatsType
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    div(
      RenderOptions(style = Some(js.Dynamic.literal("margin-top" -> "15px"))),
      vLayout(
        vFlex(
          switchMAker(
            "Inbound",
            "Include Inbound calls",
            getCurrent(Inbound, c.filters).isDefined,
            c,
            switchEvent(Inbound, c.filters, c)
          )
        ),
        vFlex(
          switchMAker(
            "Outbound",
            "Include Outbound calls",
            getCurrent(Outbound, c.filters).isDefined,
            c,
            switchEvent(Outbound, c.filters, c)
          )
        ),
        vFlex(
          switchMAker(
            "Internal",
            "Include Internal calls",
            getCurrent(Internal, c.filters).isDefined,
            c,
            switchEvent(Internal, c.filters, c)
          )
        )
      )
    )
  }

  def switchEvent(direction: Direction, f: List[Filter], c: UserStatsType) = {
    EventBindings(change = js.defined(e => {
      val value = e.asInstanceOf[Boolean]

      direction match {
        case i @ Inbound => sendUpdate(c, if (value) Some(i) else None, true, false, false)
        case o @ Outbound => sendUpdate(c, if (value) Some(o) else None, false, false, true)
        case in @ Internal => sendUpdate(c, if (value) Some(in) else None, false, true, false)
      }

    }))
  }

  def filterSelectedSummaryData[A](b: List[Boolean], a: List[A]) = {
    a.zipWithIndex.flatMap { case (column, i) =>
      if (i < 1) {
        Some(column)
      } else {
        if (b(i - 1)) Some(column) else None
      }
    }
  }

  /**
    * It allows us to remove data from a list based on a list of boolean values
    * We can check which headers are toggled by the user
    * We offset the check from the first two columns as they are fixed on the component
    */
  def filterSelected: (List[Boolean], List[String]) => List[DataWithPosition] =
    (checkboxValues: List[Boolean], data: List[String]) =>
      data.zipWithIndex.flatMap { case (column, i) =>
        if (i < 2) {
          Some(DataWithPosition(i, column))
        } else {
          if (checkboxValues(i - 2)) Some(DataWithPosition(i, column)) else None
        }
      }

  def buildCheckbox(value: Int, c: UserStatsType) = {
    vCheckBox(
      RenderOptions(
        props = Some(VCheckBoxProps(`input-value` = Some(c.checkboxInitialValues(value)))),
        style = Some(js.Dynamic.literal("position" -> "relative", "bottom" -> "-13px")),
        on = Some(EventBindings(change = js.defined(e => {
          c.checkboxInitialValues =
            c.checkboxInitialValues.updated(value, !c.checkboxInitialValues(value))
          sendUpdate(c)
        })))
      )
    )
  }

  def updateData(
      componentConfig: UserStatsComponentConfig,
      tenantId: Int,
      filters: List[Filter],
      loader: Vue,
      apiCalls: ApiCalls,
      u: UserStatsType
  )(implicit token: SludgToken) = {
    retrieveData(tenantId, loader, filters, apiCalls).map {
      case Left(errorResult) => resultProcessor(Left(errorResult))
      case Right(x) => setData(componentConfig, x._1, x._2, u)
    }
  }

  class EventsWatcher extends js.Object {
    def editMode(value: js.Any): Unit = {
      val u = this.asInstanceOf[UserStatsType]

      /**
        * If you click the save button in the toolbar
        * and you are editing this component
        * The components data is reloaded as it exists editmode
        * This is to prevent changes in filters not being saved and used to update data
        */
      if (u.editing) {
        u.editing = false

        (u.apiCalls, u.loader) match {
          case (Some(apiCalls), Some(loader)) =>
            updateData(u.componentConfig, u.selectedTenant, u.filters, loader, apiCalls, u)(u.token)
          case _ => logger.debug("Could not load data in user stats")
        }
      }
    }

    def reset() = {
      val u = this.asInstanceOf[UserStatsType]
      /* table data */
      u.rawTableData = List()
      u.tableItems = Some(List())

      /* dropdown */
      u.selectableSubscribers = List()
      u.selectedSubscribers = js.Array()

      /* customising headers */
      u.checkboxInitialValues = List.fill(12)(false)

      (u.apiCalls, u.loader) match {
        case (Some(a), Some(l)) => {

          updateData(u.componentConfig, u.selectedTenant, u.filters, l, a, u)(u.token)
        }
        case _ => logger.debug("Not found api calls or loader.")
      }
    }

    def refresh(value: js.Any): Unit = {
      val u = this.asInstanceOf[UserStatsType]
      (u.apiCalls, u.loader) match {
        case (Some(a), Some(l)) =>
          updateData(u.componentConfig, u.selectedTenant, u.filters, l, a, u)(u.token)
        case _ => logger.debug("Not found api calls or loader.")
      }
    }
  }

  class TableItems(
      val name: String,
      val total: String,
      val totalTalkTime: String,
      val minTalkTime: String,
      val maxTalkTime: String,
      val avgTalkTime: String,
      val totalRingTime: String,
      val minRingTime: String,
      val maxRingTime: String,
      val avgRingTime: String,
      val totalDurationTime: String,
      val minDurationTime: String,
      val maxDurationTime: String,
      val avgDurationTime: String
  ) extends js.Object

  class HeaderSelectionTable(
      val offset: String,
      val title: String,
      val c1: String = "",
      val c2: String = "",
      val c3: String = "",
      val c4: String = ""
  ) extends js.Object

  class UserStatsData extends js.Object {
    var editing = true

    var selectedTab = 0
    /* table data */
    var rawTableData: List[ExtensionStats] = List()
    var tableItems: Option[List[TableItems]] = Some(List())

    /* dropdown */
    var selectableSubscribers: List[Subscriber] = List()
    var selectedSubscribers: js.Array[Subscriber] = js.Array()

    /* customising headers */
    var checkboxInitialValues: List[Boolean] = List.fill(12)(false)
    var tableColumnSorting: List[PriorityOrder] = List.fill(14)(PriorityOrder(Neutral, 0))

    /* loader and apiCalls*/
    var apiCalls: Option[ApiCalls] = None
    var loader: Option[Vue] = None
  }

  class UserStatsProps(
      val reset: Boolean,
      val editMode: EditingMode,
      val selectedTenant: Int,
      val id: Int,
      val refresh: Boolean,
      val subscriber: Subscriber,
      val height: Int,
      val width: Int,
      val filters: List[Filter],
      val componentConfig: UserStatsComponentConfig,
      val token: SludgToken
  ) extends VueProps
}
