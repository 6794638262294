package com.sludg.pages.mainpage.grid.cells.widget_1

import com.sludg.FieldExtractor
import com.sludg.helpers.Helper.ExtensionSelectorType
import com.sludg.util.PresenterSyntax._
import com.sludg.util.SilhouettePresenters._
import com.sludg.util.models.SilhouetteModels.Subscriber
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._

import scala.collection.immutable.List
import scala.scalajs.js
import com.sludg.vue.{RenderHelpers, _}
import scala.collection.immutable.{::, List, Nil}

object ExtensionSelector {
  def ExtensionSelectorRenderer(
      registrationName: String
  ): RenderHelpers.NodeRenderer[VueProps, ExtensionSelectorEvents, ScopedSlots] =
    namedTag[VueProps, ExtensionSelectorEvents, ScopedSlots](registrationName)

  def extensionSelectorComponent(): VueComponent[_ <: VueProps, _ <: Slots, _ <: ScopedSlots] =
    VueComponent.builder
      .withPropsAs[ExtensionSelectorProps]
      .build(templateOrRender =
        Right((component, renderer) => div(extensionSelector(component, renderer)).render(renderer))
      )

  private def extensionSelector(es: ExtensionSelectorType, renderer: CreateElement) = {
    div(
      RenderOptions(style =
        Some(js.Dynamic.literal("margin-left" -> "30px", "margin-right" -> "30px"))
      ),
      vAutocomplete[Subscriber](
        vList(
          RenderOptions(slot = Some("prepend-item")),
          vListTile(
            /**
              * The select-all checkbox will always either deselect all, or select all.
              *
              * Select-all  ✅
              * Samir       ✅
              * Peter       ✅
              * Ali         ✅
              *
              * Lets assume that select-all checkbox and all of the other check-boxes are ✅.
              * If I click the select-all checkbox now, I check if the total number of tenant subscribers is equal to the selected subscribers. (3 == 3)
              * If this is equal, then I know that all of the check-boxes are selected, so I subsequently deselect them all.
              */
            RenderOptions(on =
              Some(
                EventBindings(click =
                  js.defined(e =>
                    if (es.selectedSubscribers.size == es.tenantSubscribers.size)
                      emitSelectedSubscribers(es, List())
                    else emitSelectedSubscribers(es, es.tenantSubscribers)
                  )
                )
              )
            ),
            vListTileAction(
              vCheckBox(
                RenderOptions(props =
                  Some(
                    VCheckBoxProps(
                      `input-value` =
                        Some(es.selectedSubscribers.size == es.tenantSubscribers.size),
                      color = Some("primary")
                    )
                  )
                )
              )
            ),
            vListTileContent(vListTileTitle("Select all"))
          )
        ),
        RenderOptions(
          props = Some(
            VAutocompleteProps[Subscriber](
              label = Some("Select users"),
              items = Some(es.tenantSubscribers),
              itemText = Some(
                Right(x =>
                  (x: Any) match {
                    case a: List[_] => a.collect { case sub: Subscriber => sub.present }.present
                    case b: Subscriber => b.present
                    case c => c.toString
                  }
                )
              ),
              value = Some(Right(es.selectedSubscribers)),
              chips = Some(true),
              `small-chips` = Some(true),
              multiple = Some(true),
              `return-object` = Some(true)
            )
          ),
          on = Some(
            VAutocompleteEventBindings(input =
              js.defined(e =>
                emitSelectedSubscribers(
                  es,
                  (e: Any) match {
                    case list: List[_] => list.collect { case s: Subscriber => s }
                    case currentlySelected: js.Array[_] =>
                      currentlySelected.toList.collect { case s: Subscriber => s }
                    case sub: Subscriber => List(sub)
                    case _ => Nil
                  }
                )
              )
            )
          ),
          scopedSlots = Some(
            new VAutocompleteScopedSlots[Subscriber](
              /** If there is no overflow limit provided, we display all data chips
                * Otherwise we check if the current chip exceeds the overflow limit, and we display it
                * If we reach the overflow limit, we display a value indicating how many chips exceed the limit (unless its 0)
                * If the chip is over the overflow limit, we omit that chip
                */
              selection = js.defined(data =>
                (es.overflowLimit match {
                  case None => chipGenerator(data, es)
                  case Some(overflowLimit) if data.index < overflowLimit => chipGenerator(data, es)
                  case Some(overflowLimit)
                      if data.index == overflowLimit && es.selectedSubscribers.size - overflowLimit != 0 =>
                    span(s"(+${es.selectedSubscribers.size - overflowLimit})")(
                      RenderOptions(`class` = List(Left("grey--text caption")))
                    )
                  case _ => div(nothing)
                }).render(renderer)
              )
            )
          )
        )
      )
    )
  }

  def chipGenerator(
      data: VAutocompleteScopedSlotsSelectionData[Subscriber],
      es: ExtensionSelectorType
  ): RenderHelpers.NodeRenderer[VTooltipProps, EventBindings, ScopedSlots] = {
    val fontStyle =
      RenderOptions(style = Some(js.Dynamic.literal("color" -> "black", "margin-bottom" -> "-4px")))

    vToolTip(
      div(
        p(data.item.firstName.map(_ + " ").getOrElse("") + data.item.lastName, fontStyle),
        p("Extension: " + data.item.extension, fontStyle),
        p("Email: " + data.item.email, fontStyle)
      ),
      RenderOptions(props =
        Some(
          VTooltipProps(
            `max-width` = Some(Left(220)),
            color = Some("white"),
            `open-delay` = Some(Left(250)),
            light = Some(true),
            bottom = Some(true)
          )
        )
      ),
      div(
        RenderOptions(slot = Some("activator")),
        vChip(
          data.item.extension + " - " + data.item.firstName
            .getOrElse("") + " " + data.item.lastName,
          RenderOptions(
            props = Some(VChipProps(close = Some(true), small = Some(true))),
            on = Some(
              EventBindings(input =
                js.defined(_ =>
                  emitSelectedSubscribers(
                    es,
                    es.selectedSubscribers.filterNot(_.extension == data.item.extension)
                  )
                )
              )
            )
          )
        )
      )
    )
  }

  //Emits the selected subscribers to the parent component
  private def emitSelectedSubscribers(
      es: ExtensionSelectorType,
      subscribers: List[Subscriber]
  ): Unit = es.$emit("selectionUpdate", subscribers)

  class ExtensionSelectorEvents(
      val selectionUpdate: js.UndefOr[js.Function1[List[Subscriber], Unit]]
  ) extends js.Object

  class ExtensionSelectorProps(
      val tenantSubscribers: List[Subscriber],
      val selectedSubscribers: List[Subscriber] = Nil,
      val overflowLimit: Option[Int] = None
  ) extends VueProps
}
