package com.sludg.pages.mainpage.toolbar

import com.sludg.models.Model.{DashboardConfig, DashboardOption, DashboardSelectorOption}
import com.sludg.helpers.States.{AccessPermission, EditingMode, UserType}
import com.sludg.services.ApiCalls
import com.sludg.util.models.DashboardModels
import com.sludg.util.models.DashboardModels.{Dashboard, UnsavedDashboard}
import com.sludg.util.models.SilhouetteModels.{Subscriber, Tenant}
import com.sludg.vue.RenderHelpers.{div, namedTag, p, td, tr, _}
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.VuetifyComponents.vBadge
import com.sludg.vuetify.components._
import com.sludg.helpers.Helper.{isEditing, _}

import scala.scalajs.js
import com.sludg.auth0.SludgToken
import com.sludg.pages.mainpage.toolbar.Toolbar.ToolbarEvents.{CreateType, UpdateType}
import org.log4s.getLogger

import scala.scalajs.js.JSConverters._
import scala.collection.immutable.List
import com.sludg.helpers.States.UserType.SuperUser
import com.sludg.vuetify.components.grid.VFlexProps
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|
import com.sludg.FieldExtractor

object Toolbar {
  def toolbarRenderer(
      registrationName: String
  ): RenderHelpers.NodeRenderer[ToolbarProps, EventBindings, ScopedSlots] =
    namedTag[ToolbarProps, EventBindings, ScopedSlots]("Toolbar")

  val logger = getLogger

  def ToolbarComponent(
      apiCalls: ApiCalls,
      loader: Vue,
      config: DashboardConfig,
      token: SludgToken
  ): VC = {
    VueComponent.builder
      .withData(new ToolbarData)
      .withPropsAs[ToolbarProps]
      .build(
        templateOrRender = Right((t, renderer) => {
          addButtonCard(
            t.userType == SuperUser,
            isUpdatedDashboardNameValid(t),
            !isDashboardFound(t),
            isEditing(t.editMode),
            isDashboardMine(t.selectedDashboard, t.subscriberId),
            apiCalls,
            loader,
            renderer,
            token,
            t,
            t.hasUnsavedChanges
          ).render(renderer)
        })
      )
  }

  def addButtonCard(
      isSuperUser: Boolean,
      isDashboardNameValid: Boolean,
      isDashboardMissing: Boolean,
      isEditing: Boolean,
      isDashboardMine: Boolean,
      apiCalls: ApiCalls,
      loader: Vue,
      renderer: VueInstanceProperties.CreateElement,
      token: SludgToken,
      t: ToolbarType,
      unsavedChanges: Boolean
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    div(
      vLayout(
        table(
          if (isEditing) {
            td(
              RenderOptions(`class` = List(Left("width-limit-dropdown"))),
              createNewDashboardTextfield(
                (name: String) => t.updatedDashboardName = name,
                "Update name",
                t.selectedDashboard.map(_.name),
                "Update name",
                isDashboardNameValid,
                t
              )
            )
          } else {
            td(
              RenderOptions(`class` = List(Left("width-limit-dropdown"))),
              buildCombobox(isSuperUser, isDashboardMine, t, renderer, isDashboardMissing)
            )
          },
          td(
            RenderOptions(`class` = List(Left("width-limit-80px"))),
            vToolTip(
              p("Create dashboard"),
              RenderOptions(
                props = Some(
                  VTooltipProps(
                    `open-on-hover` = Some(true),
                    `max-width` = Some(Left(100)),
                    `open-delay` = Some(Left(1000)),
                    bottom = Some(true)
                  )
                )
              ),
              template(
                vButton(
                  "New",
                  RenderOptions(
                    props = Some(
                      VButtonProps(
                        disabled = Some(isEditing),
                        outline = Some(isDashboardMissing),
                        flat = Some(true)
                      )
                    ),
                    on = Some(EventBindings(click = js.defined(e => {
                      //Initialize new name to ""
                      t.createDashboardName = ""
                      t.newDialogVisible = true
                      // When a super user, sets the selected subscriber to be the first one in the list
                      if (isSuperUser) {
                        t.subscriberSelect =
                          t.subscribersOfTenantSuperUserIsViewing.flatMap(_.headOption)
                      }
                    }))),
                    `class` = List(Left("button"))
                  )
                ),
                RenderOptions(slot = Some("activator"))
              )
            )
          ),
          td(
            RenderOptions(`class` = List(Left("width-limit-80px"))),
            vToolTip(
              p("Clone dashboard"),
              RenderOptions(
                props = Some(
                  VTooltipProps(
                    `open-on-hover` = Some(true),
                    `max-width` = Some(Left(100)),
                    `open-delay` = Some(Left(1000)),
                    bottom = Some(true)
                  )
                )
              ),
              template(
                vButton(
                  "Clone",
                  RenderOptions(
                    props = Some(
                      VButtonProps(
                        flat = Some(true),
                        disabled = Some(isDashboardMissing || isEditing)
                      )
                    ),
                    on = Some(EventBindings(click = js.defined(e => t.cloneDialogVisible = true))),
                    `class` = List(Left("button"))
                  )
                ),
                RenderOptions(slot = Some("activator"))
              )
            )
          ),
          td(
            vToolTip(
              p("Edit/save"),
              RenderOptions(
                props = Some(
                  VTooltipProps(
                    `open-on-hover` = Some(true),
                    `max-width` = Some(Left(100)),
                    `open-delay` = Some(Left(1000)),
                    bottom = Some(true)
                  )
                )
              ),
              template(
                if (isEditing) {
                  vButton(
                    vBadge(
                      RenderOptions(props =
                        Some(
                          VBadgeProps(
                            color = Some("primary"),
                            overlap = Some(true),
                            value = Some(unsavedChanges)
                          )
                        )
                      ),
                      template(
                        RenderOptions(slot = Some("badge")),
                        span("!")
                      ),
                      vIcon("save")
                    ),
                    RenderOptions(
                      props = Some(
                        VButtonProps(
                          icon = Some(true),
                          flat = Some(true),
                          disabled = Some(!isDashboardNameValid || isDashboardMissing)
                        )
                      ),
                      on = updateDashboard(
                        t: ToolbarType,
                        t.selectedDashboard.exists(_.favourited),
                        t.selectedDashboard.exists(_.shared),
                        t.updatedDashboardName,
                        true,
                        true
                      ),
                      `class` = List(Left("button"))
                    )
                  )
                } else {
                  vButton(
                    vIcon("edit"),
                    RenderOptions(
                      props = Some(
                        VButtonProps(
                          flat = Some(true),
                          icon = Some(true),
                          disabled = Some(isDashboardMissing || (!isDashboardMine && !isSuperUser))
                        )
                      ),
                      on = Some(
                        EventBindings(
                          click = js.defined(e => {
                            t.$emit("activateEditMode", true)
                            t.updatedDashboardName =
                              t.selectedDashboard.map(_.name).getOrElse("No name found")
                          })
                        )
                      ),
                      `class` = List(Left("button"))
                    )
                  )
                },
                RenderOptions(slot = Some("activator"))
              )
            )
          ),
          if (isEditing) {
            td(
              RenderOptions(`class` = List(Left("width-limit-80px"))),
              div(
                vButton(
                  vIcon("cancel"),
                  RenderOptions(
                    props = Some(VButtonProps(icon = Some(true), flat = Some(true))),
                    on = Some(
                      EventBindings(
                        click = js.defined(e => {
                          t.$emit("cancelEditing", 0)
                        })
                      )
                    ),
                    `class` = List(Left("button"))
                  )
                )
              )
            )
          } else nothing,
          td(
            RenderOptions(`class` = List(Left("width-limit-80px"))),
            vToolTip(
              p("Favourite"),
              RenderOptions(
                props = Some(
                  VTooltipProps(
                    `open-on-hover` = Some(true),
                    `max-width` = Some(Left(100)),
                    `open-delay` = Some(Left(1000)),
                    bottom = Some(true)
                  )
                )
              ),
              template(
                vButton(
                  if (t.selectedDashboard.exists(_.favourited)) vIcon("favorite")
                  else vIcon("favorite_border"),
                  RenderOptions(
                    props = Some(
                      VButtonProps(
                        flat = Some(true),
                        icon = Some(true),
                        disabled = Some(isDashboardMissing || isEditing)
                      )
                    ),
                    on = updateDashboard(
                      t: ToolbarType,
                      !t.selectedDashboard.exists(_.favourited),
                      t.selectedDashboard.exists(_.shared),
                      t.selectedDashboard.map(_.name).getOrElse("No Name")
                    ),
                    `class` = List(Left("button"))
                  )
                ),
                RenderOptions(slot = Some("activator"))
              )
            )
          ),
          td(
            RenderOptions(`class` = List(Left("width-limit-80px"))),
            vToolTip(
              p("Share/Unshare."),
              RenderOptions(
                props = Some(
                  VTooltipProps(
                    `open-on-hover` = Some(true),
                    `max-width` = Some(Left(100)),
                    `open-delay` = Some(Left(1000)),
                    bottom = Some(true)
                  )
                )
              ),
              template(
                vButton(
                  if (t.selectedDashboard.exists(_.shared)) "Unshare" else "Share",
                  RenderOptions(
                    props = Some(
                      VButtonProps(
                        depressed = Some(t.selectedDashboard.exists(_.shared)),
                        flat = Some(true),
                        disabled = Some(isDashboardMissing || isEditing)
                      )
                    ),
                    /**
                      * If you own a dashboard, you can unshare or share
                      * If you do not own the dashboard, you are given a warning to force un-share it
                      */
                    on = if (isDashboardMine || isSuperUser) {
                      updateDashboard(
                        t: ToolbarType,
                        t.selectedDashboard.exists(_.favourited),
                        !t.selectedDashboard.exists(_.shared),
                        t.selectedDashboard.map(_.name).getOrElse("No Name")
                      )
                    } else {
                      Some(EventBindings(click = js.defined(e => {
                        t.forceUnshareDialogDeletionWarning = false
                        t.forceUnshareDialogVisible = true
                      })))
                    },
                    `class` = List(Left("button"))
                  )
                ),
                RenderOptions(slot = Some("activator"))
              )
            )
          ),
          td(RenderOptions(`class` = List(Left("width-limit-toolbar"))))
        ),
        deletionDialog(isDashboardMine, apiCalls, loader, t),
        createNewDashboardDialog(isNewDashboardNameValid(t), t, apiCalls, token, loader),
        cloneNewDashboardDialog(isNewDashboardNameValid(t), t, apiCalls, loader),
        forceUnshareDialog(t, apiCalls, loader)
      )
    )
  }

  def updateDashboard(
      c: ToolbarType,
      favourite: Boolean,
      share: Boolean,
      newName: String,
      recalculateAdders: Boolean = false,
      savingNewComponents: Boolean = false
  ): Option[EventBindings] =
    Some(EventBindings(click = js.defined(e => {
      c.selectedDashboard match {
        case Some(x) => {
          val unsaved: Dashboard = x.copy(favourited = favourite, shared = share, name = newName)
          c.$emit(
            "updateDashboardEvent",
            UpdateType(unsaved, recalculateAdders, savingNewComponents)
          )
        }
        case None => logger.info("No dashboard selected.")
      }
    })))

  def forceUnshareDialog(
      t: ToolbarType,
      apiCalls: ApiCalls,
      loader: Vue
  ): RenderHelpers.NodeRenderer[VDialogProps, EventBindings, ScopedSlots] =
    dialogGenerator(
      t.forceUnshareDialogVisible,
      EventBindings(input = js.defined(e => t.forceUnshareDialogVisible = e.asInstanceOf[Boolean])),
      vCard(
        vCardTitle("Unshare dashboard", RenderOptions(`class` = List(Left("headline")))),
        vDivider,
        vFlex(
          if (t.forceUnshareDialogDeletionWarning) {
            div(
              p(
                s"You do not have permission to delete dashboard ",
                RenderOptions(style =
                  Some(
                    js.Dynamic.literal(
                      "padding-left" -> "40px",
                      "padding-right" -> "40px",
                      "padding-top" -> "35px"
                    )
                  )
                )
              ),
              p(
                s"Would you like to Unshare it instead?",
                RenderOptions(style =
                  Some(js.Dynamic.literal("padding-left" -> "40px", "padding-right" -> "40px"))
                )
              )
            )
          } else {
            div(
              p(
                s"Would you like to un-share dashboard " + t.selectedDashboard
                  .map(_.name)
                  .getOrElse("") + "?",
                RenderOptions(style =
                  Some(js.Dynamic.literal("padding-left" -> "40px", "padding-right" -> "40px"))
                )
              )
            )
          },
          p(
            s"You will lose access to this dashboard, but the owner will still have access.",
            RenderOptions(style =
              Some(js.Dynamic.literal("padding-left" -> "40px", "padding-right" -> "40px"))
            )
          ),
          vCardText(vList(VListProps(`two-line` = Some(true)))),
          vCardActions(
            vSpacer,
            vButton(
              "Cancel",
              EventBindings(click = js.defined(e => {
                t.forceUnshareDialogVisible = false
                t.forceUnshareDialogDeletionWarning = false
              }))
            ),
            vButton(
              "Unshare",
              RenderOptions(
                props = Some(VButtonProps(color = Some("red"))),
                on = Some(EventBindings(click = js.defined(e => {
                  t.forceUnshareDialogVisible = false
                  t.forceUnshareDialogDeletionWarning = false

                  t.dashboardToDelete match {
                    case Some(x) => t.$emit("forceUnshare", x)
                    case None => logger.info("No dashboard selected.")
                  }
                })))
              )
            )
          )
        )
      )
    )

  def deletionDialog(
      isDashboardMine: Boolean,
      apiCalls: ApiCalls,
      loader: Vue,
      t: ToolbarType
  ): RenderHelpers.NodeRenderer[VDialogProps, EventBindings, ScopedSlots] =
    dialogGenerator(
      t.deleteDialogVisible,
      EventBindings(input = js.defined(e => t.deleteDialogVisible = e.asInstanceOf[Boolean])),
      vCard(
        vCardTitle("Delete Dashboard", RenderOptions(`class` = List(Left("headline")))),
        vDivider,
        vFlex(
          p(s"Are you sure you want to delete " + t.dashboardToDelete.map(_.name).getOrElse("")),
          RenderOptions(
            style = Some(
              js.Dynamic.literal(
                "padding-left" -> "40px",
                "padding-right" -> "40px",
                "padding-top" -> "35px"
              )
            )
          )
        ),
        vCardText(vList(VListProps(`two-line` = Some(true)))),
        vCardActions(
          vSpacer,
          vButton("Cancel", EventBindings(click = js.defined(e => t.deleteDialogVisible = false))),
          vButton(
            "Delete",
            RenderOptions(
              props = Some(VButtonProps(color = Some("red"))),
              on = Some(EventBindings(click = js.defined(e => {
                t.forceUnshareDialogDeletionWarning = false
                t.deleteDialogVisible = false
                t.$emit("deleteDashboard", t.dashboardToDelete)
              })))
            )
          )
        )
      )
    )

  def cloneNewDashboardDialog(
      isNewDashboardNameValid: Boolean,
      t: ToolbarType,
      apiCalls: ApiCalls,
      loader: Vue
  ): RenderHelpers.NodeRenderer[VDialogProps, EventBindings, ScopedSlots] =
    dialogGenerator(
      t.cloneDialogVisible,
      EventBindings(input = js.defined(e => t.cloneDialogVisible = e.asInstanceOf[Boolean])),
      vCard(
        vCardTitle("Clone Dashboard", RenderOptions(`class` = List(Left("headline")))),
        vDivider,
        div(
          RenderOptions(style =
            Some(
              js.Dynamic.literal(
                "padding-left" -> "40px",
                "padding-right" -> "40px",
                "padding-top" -> "35px"
              )
            )
          ),
          createNewDashboardTextfield(
            (name: String) => t.createDashboardName = name,
            "Dashboard name",
            None,
            "Update name",
            isNewDashboardNameValid,
            t
          )
        ),
        small(
          "Cloned dashboards are set to private by default.",
          RenderOptions(style = Some(js.Dynamic.literal("padding-left" -> "40px")))
        ),
        selectDashboardOwneru(t),
        vCardText(vList(VListProps(`two-line` = Some(true)))),
        vCardActions(
          vSpacer,
          vButton(
            "Cancel",
            EventBindings(click = js.defined(e => {
              t.createDashboardName = "";
              t.cloneDialogVisible = false
            }))
          ),
          vButton(
            "Clone",
            RenderOptions(
              props = Some(VButtonProps(disabled = Some(!isNewDashboardNameValid))),
              on = Some(EventBindings(click = js.defined(e => {
                t.cloneDialogVisible = false
                val tid = t.tenantSuperUserIsViewing.map(_.id).getOrElse(t.tenant.id)
                val sid =
                  t.subscriberSuperUserIsViewing.map(_.subscriberId).getOrElse(t.subscriberId)
                t.$emit(
                  "createDashboardEvent",
                  CreateType(
                    UnsavedDashboard(
                      tid,
                      sid,
                      t.createDashboardName,
                      shared = false,
                      favourited = false
                    ),
                    true
                  )
                )
              })))
            )
          )
        )
      )
    )

  def dialogGenerator(
      vis: Boolean,
      event: EventBindings,
      card: RenderHelpers.NodeRenderer[VCardProps, EventBindings, ScopedSlots]
  ): RenderHelpers.NodeRenderer[VDialogProps, EventBindings, ScopedSlots] =
    vDialog(
      RenderOptions(
        style = Some(js.Dynamic.literal("text-align" -> "center", "box-shadow" -> "0px")),
        props = Some(
          VDialogProps(
            value = Some(vis),
            width = Some(Right(200)),
            scrollable = Some(false),
            `max-width` = Some(Right(550))
          )
        ),
        on = Some(event)
      ),
      card
    )

  /** Allows you to create a textfield with name validation
    *
    * @param updateNameValue
    * @param placeholder
    * @param inValue
    * @param label
    * @param isDashboardNameValid
    * @param t
    * @return
    */
  def createNewDashboardTextfield(
      updateNameValue: (String => Unit),
      placeholder: String,
      inValue: Option[String],
      label: String,
      isDashboardNameValid: Boolean,
      t: ToolbarType
  ): RenderHelpers.NodeRenderer[VFlexProps, EventBindings, ScopedSlots] = {
    vFlex(
      vTextField(
        RenderOptions(
          props = Some(
            VTextFieldProps(
              placeholder = Some(placeholder),
              value = inValue.map(_.asInstanceOf[scalajs.js.Any]),
              label = Some(label),
              rules = List(
                /**
                  * Validating the updated dashboard name
                  * If you try to enter a name which is blank, the save button is disabled and a warning is displayed
                  */
                (a: (Option[String])) => {
                  if (isDashboardNameValid) None else Some("Invalid dashboard name entered.")
                }
              )
            )
          ),
          //Updating a new dashboard name
          on = Some(EventBindings(input = js.defined(e => updateNameValue(e.toString))))
        )
      )
    )
  }

  def createNewDashboardDialog(
      isNewDashboardNameValid: Boolean,
      t: ToolbarType,
      apiCalls: ApiCalls,
      token: SludgToken,
      loader: Vue
  ): RenderHelpers.NodeRenderer[VDialogProps, EventBindings, ScopedSlots] =
    dialogGenerator(
      t.newDialogVisible,
      EventBindings(input = js.defined(e => t.newDialogVisible = e.asInstanceOf[Boolean])),
      vCard(
        vCardTitle("Create Dashboard", RenderOptions(`class` = List(Left("headline")))),
        vDivider,
        div(
          RenderOptions(style =
            Some(
              js.Dynamic.literal(
                "padding-left" -> "40px",
                "padding-right" -> "40px",
                "padding-top" -> "35px"
              )
            )
          ),
          createNewDashboardTextfield(
            (name: String) => t.createDashboardName = name,
            "Dashboard name",
            None,
            "Update name",
            isNewDashboardNameValid,
            t
          )
        ),
        small(
          "New dashboards are set to private by default.",
          RenderOptions(style = Some(js.Dynamic.literal("padding-left" -> "40px")))
        ),
        selectDashboardOwneru(t),
        vCardText(vList(VListProps(`two-line` = Some(true)))),
        vCardActions(
          vSpacer,
          vButton(
            "Cancel",
            RenderOptions(
              on = Some(
                EventBindings(
                  click = js.defined(e => {
                    t.newDialogVisible = false
                  })
                )
              )
            )
          ),
          vButton(
            "Create",
            RenderOptions(
              props = Some(VButtonProps(disabled = Some(!isNewDashboardNameValid))),
              on = Some(
                EventBindings(
                  click = js.defined(e => {
                    t.$emit(
                      "createDashboardEvent",
                      CreateType(
                        UnsavedDashboard(
                          t.tenantSuperUserIsViewing.map(_.id).getOrElse(t.tenant.id),
                          t.subscriberSelect
                            .map(_.subscriberId)
                            .getOrElse(t.subscriberId),
                          t.createDashboardName,
                          t.newDashboardshared,
                          favourited = false
                        )
                      )
                    )
                    //UpdatedDashboardName initialized to the new created value
                    t.updatedDashboardName = t.createDashboardName
                    t.$emit("activateEditMode", true)
                    t.newDialogVisible = false
                  })
                )
              )
            )
          )
        )
      )
    )

  def selectDashboardOwneru(t: ToolbarType) = {
    (t.subscriberSelect, t.subscribersOfTenantSuperUserIsViewing, t.userType) match {
      case (Some(selectedSubscriber), Some(s), SuperUser) =>
        div()
        div(
          vAutocomplete(
            RenderOptions[VAutocompleteProps[
              Subscriber
            ], VAutocompleteEventBindings, VAutocompleteScopedSlots[Subscriber]](
              style = Some(
                js.Dynamic.literal(
                  "width" -> "100%",
                  "padding-left" -> "40px",
                  "padding-right" -> "40px",
                  "padding-top" -> "35px"
                )
              ),
              props = Some(
                js.Dynamic
                  .literal(
                    "label" -> "Select dashboard owner",
                    "items" -> t.subscribersOfTenantSuperUserIsViewing.getOrElse(List()).toJSArray,
                    "item-text" -> (((itemText) => {
                      val s = itemText.asInstanceOf[Subscriber]
                      s.firstName.getOrElse("No Name") + " " + s.lastName.headOption
                        .getOrElse("") + ": " + s.extension
                    }): js.Function1[js.Array[Subscriber] | Subscriber, String]),
                    "value" -> selectedSubscriber.asInstanceOf[js.Any],
                    "return-object" -> true,
                    "autocomplete" -> true,
                    "placeholder" -> "Select user"
                  )
                  .asInstanceOf[VAutocompleteProps[Subscriber]]
              ),
              on = Some(
                VAutocompleteEventBindings(
                  input = js.defined(e => t.subscriberSelect = Some(e.asInstanceOf[Subscriber]))
                )
              )
            )
          ),
          small(
            "As a Super User, you can create a dashboard for someone else.",
            RenderOptions(style = Some(js.Dynamic.literal("padding-left" -> "40px")))
          )
        )
      case _ => div()
    }
  }

  def buildCombobox(
      isSuperUser: Boolean,
      isSelectedDashboardMine: Boolean,
      component: ToolbarType,
      renderer: VueInstanceProperties.CreateElement,
      isThereADashboardAtm: Boolean
  ): RenderHelpers.NodeRenderer[VComboboxProps[
    DashboardSelectorOption
  ], VAutocompleteEventBindings, ScopedSlots] =
    vCombobox[DashboardSelectorOption](
      RenderOptions(
        props = Some(
          VComboboxProps[DashboardSelectorOption](
            disabled = Some(isThereADashboardAtm),
            dense = Some(true),
            items = Some(categorizeDashboards(component)),
            `item-text` = Some(Right { case DashboardOption(Dashboard(_, _, _, name, _, _)) =>
              name
            }),
            value = component.selectedDashboard.map(d => Right(DashboardOption(d))),
            flat = Some(true),
            `solo-inverted` = Some(true),
            `hide-details` = Some(true),
            placeholder = Some("Select Dashboard"),
            `item-value` = Some(Right(r => r))
          )
        ),
        scopedSlots = Some(new VComboboxScopedSlots[DashboardSelectorOption] {
          override val item: js.UndefOr[
            js.Function1[VComboboxItemSlotScope[DashboardSelectorOption], VNode | js.Array[VNode]]
          ] =
            js.defined(_.item match {
              case DashboardOption(dashboard) =>
                dashBoardRenderer(isSuperUser, dashboard, component).map(_.render(renderer))
            })
        }),
        on = Some(
          VAutocompleteEventBindings(
            input = js.defined((e) => {
              val dashboard = e.asInstanceOf[DashboardOption].dashboard
              component.$router.push(s"${dashboard.id}")
              component.$emit("selectedDashboard", dashboard)
            })
          )
        )
      )
    )

  def dashBoardRenderer(
      isSuperUser: Boolean,
      dashboard: Dashboard,
      t: ToolbarType
  ): js.Array[RenderFunction[VNode]] = {
    //Dashboard name
    js.Array(
      vListTileContent(
        dashboard.name,
        RenderOptions(style =
          Some(js.Dynamic.literal("margin-left" -> "15px", "font-weight" -> "bold"))
        )
      ),
      //If you own this dashboard, you get a symbol beside it
      td(
        if (isDashboardMine(Some(dashboard), t.subscriberId)) div(vIcon("account_circle"))
        else div()
      ),
      td(
        RenderOptions(style = Some(js.Dynamic.literal("float" -> "right"))),
        vButton(
          vIcon(
            if (isDashboardMine(Some(dashboard), t.subscriberId) || isSuperUser) "delete"
            else "share"
          ),
          RenderOptions(
            props = Some(
              VButtonProps(
                icon = Some(true),
                small = Some(true),
                flat = Some(true),
                color =
                  if (isDashboardMine(Some(dashboard), t.subscriberId) || isSuperUser) None
                  else Some("red")
              )
            ),
            `class` = List(Left("button")),
            on =
              /**
                * If you own a dashboard, you go to the deletion dialog
                * If you do not own the dashboard, you go to the unshare dialog
                * If you own the dashboard, or you are a super user, then you can delete
                * otherwise you can only force-unshare
                */
              Some(EventBindings(click = js.defined(e => {
                t.dashboardToDelete = Some(dashboard)
                e.stopPropagation() // to prevent parent elements click event from triggering
                if (isDashboardMine(Some(dashboard), t.subscriberId) || isSuperUser) {
                  t.deleteDialogVisible = true
                } else {
                  t.forceUnshareDialogDeletionWarning = true
                  t.forceUnshareDialogVisible = true
                }
              })))
          )
        )
      )
    )
  }

  def categorizeDashboards(
      c: ToolbarType
  ): List[Either[VComboboxProps.StaticItem, DashboardSelectorOption]] = {
    def process(dashboard: List[Dashboard]) =
      dashboard.map((DashboardOption.apply _).andThen(r => Right(r)))

    val dashboards = c.dashboards
    val subId = c.subscriberId
    ///TODO clean up the code here
    val sortedList0: List[DashboardModels.Dashboard] =
      dashboards.filter(_.favourited).sortBy(_.name)
    val sortedList1: List[DashboardModels.Dashboard] =
      dashboards.filter(x => x.subscriberId == subId && !x.shared).sortBy(_.name)
    val sortedList2: List[DashboardModels.Dashboard] =
      dashboards.filter(x => x.subscriberId == subId && x.shared).sortBy(_.name)
    val sortedList3: List[DashboardModels.Dashboard] =
      dashboards.filter(x => x.shared && x.subscriberId != subId).sortBy(_.name)
    val sortedList4: List[DashboardModels.Dashboard] =
      dashboards.filter(x => !x.shared && x.subscriberId != subId).sortBy(_.name)

    Left(new VComboboxProps.StaticItem("Favourites")) :: process(sortedList0) :::
      Left(new VComboboxProps.StaticItem("Private")) :: process(sortedList1 ::: sortedList4) :::
      Left(new VComboboxProps.StaticItem("Shared")) :: process(sortedList2 ::: sortedList3) :::
      List(Left(new VComboboxProps.StaticItem("Samples")))
  }

  /**
    * Checks if a dashboard is selected or not
    *
    * @return boolean
    */
  def isDashboardFound(c: ToolbarType): Boolean = c.selectedDashboard.nonEmpty

  /**
    * Checks if the dashboard selected is owned by you
    *
    * @return boolean
    */
  def isDashboardMine(dashboard: Option[Dashboard], subscriberId: Int): Boolean = {
    dashboard match {
      case Some(dashboard) => dashboard.subscriberId == subscriberId
      case None => false
    }
  }

  /**
    * Updates a dashboard name value, as you type it into a textfield
    * This is for when you are creating a new dashboard
    *
    * @return boolean
    */
  def updateNewDashboardName(c: ToolbarType): Option[EventBindings] =
    Some(EventBindings(input = js.defined(e => c.createDashboardName = e.toString)))

  /**
    * Checks if the updated dashboard name is empty or not
    *
    * @return boolean
    */
  def isUpdatedDashboardNameValid(c: ToolbarType): Boolean = c.updatedDashboardName.trim.length > 0

  /**
    * Checks if the dashboard name for creating a new dashboard is empty or not
    *
    * @return boolean
    */
  def isNewDashboardNameValid(c: ToolbarType): Boolean = c.createDashboardName.trim.length > 0

  trait TableItems extends js.Object {
    val name: js.UndefOr[String] = js.undefined
  }

  trait ToolbarProps extends VueProps {
    val tenant: Tenant
    val tenantSuperUserIsViewing: Option[Tenant]
    val subscriberId: Int
    val subscriberSuperUserIsViewing: Option[Subscriber]
    val dashboards: List[Dashboard]
    val editMode: EditingMode
    val selectedDashboard: Option[Dashboard]
    val userType: UserType
    val subscribersOfTenantSuperUserIsViewing: Option[List[Subscriber]]
    val hasUnsavedChanges: Boolean
  }

  object ToolbarProps {
    def apply(
        tenant: Tenant,
        tenantSuperUserIsViewing: Option[Tenant],
        subscriberId: Int,
        subscriberSuperUserIsViewing: Option[Subscriber],
        dashboards: List[Dashboard],
        editMode: EditingMode,
        selectedDashboard: Option[Dashboard],
        userType: UserType,
        subscribersOfTenantSuperUserIsViewing: Option[List[Subscriber]],
        token: SludgToken,
        hasUnsavedChanges: Boolean
    ): ToolbarProps = {
      js.Dynamic
        .literal(
          "tenant" -> tenant.asInstanceOf[js.Any],
          "tenantSuperUserIsViewing" -> tenantSuperUserIsViewing.asInstanceOf[js.Any],
          "subscriberId" -> subscriberId.asInstanceOf[js.Any],
          "subscriberSuperUserIsViewing" -> subscriberSuperUserIsViewing.asInstanceOf[js.Any],
          "dashboards" -> dashboards.asInstanceOf[js.Any],
          "editMode" -> editMode.asInstanceOf[js.Any],
          "selectedDashboard" -> selectedDashboard.asInstanceOf[js.Any],
          "userType" -> userType.asInstanceOf[js.Any],
          "subscribersOfTenantSuperUserIsViewing" -> subscribersOfTenantSuperUserIsViewing
            .asInstanceOf[js.Any],
          "token" -> token.asInstanceOf[js.Any],
          "hasUnsavedChanges" -> hasUnsavedChanges.asInstanceOf[js.Any]
        )
        .asInstanceOf[ToolbarProps]
    }
  }

  class ToolbarData extends js.Object {
    var dashboardToDelete: Option[Dashboard] = None
    var accessState: AccessPermission = AccessPermission.Edit

    var subscriberSelect: Option[Subscriber] = None

    //Updating a dashboard name
    var updatedDashboardName = ""

    //Creating a dashboard name
    var createDashboardName = ""

    //Dialog visibility
    var newDialogVisible = false
    var cloneDialogVisible = false
    var deleteDialogVisible = false
    var forceUnshareDialogVisible = false
    //Toggle this if you want to show a warning in the force un-share menu
    var forceUnshareDialogDeletionWarning = false

    var newDashboardshared = false
    var cloneDashboardshared = false
    var deleteButtonDisabled: Boolean = false
    var editButtonDisabled: Boolean = false
    var visibilityButtonDisabled: Boolean = false
    var visibilityButtonSetAsPrivate = false
  }

  trait ToolbarEvents extends EventBindings {}

  object ToolbarEvents {

    case class CreateType(unsavedDashboard: UnsavedDashboard, _2: Boolean = false)

    case class UpdateType(
        dashboard: Dashboard,
        recalculateAdders: Boolean,
        savingNewComponents: Boolean = false
    )

    def apply(
        bindings: EventBindings = EventBindings(),
        createDashboardEvent: VueEventUndefined[CreateType] = (),
        updateDashboardEvent: VueEventUndefined[UpdateType] = (),
        deleteDashboard: VueEventUndefined[Option[Dashboard]] = (),
        selectedDashboard: VueEventUndefined[Dashboard] = (),
        activateEditMode: VueEventUndefined[Boolean] = (),
        forceUnshare: VueEventUndefined[Dashboard] = (),
        cancelEditing: VueEventUndefined[Int] = ()
    ): ToolbarEvents = {

      eventProcessor(
        (createDashboardEvent, "createDashboardEvent"),
        (updateDashboardEvent, "updateDashboardEvent"),
        (deleteDashboard, "deleteDashboard"),
        (selectedDashboard, "selectedDashboard"),
        (activateEditMode, "activateEditMode"),
        (forceUnshare, "forceUnshare"),
        (cancelEditing, "cancelEditing")
      )(bindings)

      bindings.asInstanceOf[ToolbarEvents]
    }
  }

}
