package com.sludg.util.models

import com.sludg.util.models.CallGroupCounterModels.CallGroupCallStats
import cats.Eq
import cats.Show

/**
  * @author dpoliakas
  *         Date: 2019-11-07
  *         Time: 13:02
  */
object Events {

  trait Event {
    val tenantUrl: String
    val timestamp: Long
  }

  object Event {
    implicit val eq = Eq.fromUniversalEquals[Event]
    implicit val show = Show.fromToString[Event]
  }

  trait SilhouetteCallEvent {
    val silhouetteCallId: String
  }

  object SilhouetteCallEvent {
    implicit val eq = Eq.fromUniversalEquals[SilhouetteCallEvent]
    implicit val show = Show.fromToString[SilhouetteCallEvent]
  }

  case class CallGroupStatsEvent(
      callGroupExtension: String,
      timestamp: Long,
      tenantUrl: String,
      callGroupStats: CallGroupCallStats
  ) extends Event

  object CallGroupStatsEvent {
    implicit val eq = Eq.fromUniversalEquals[CallGroupStatsEvent]
    implicit val show = Show.fromToString[CallGroupStatsEvent]
  }

  case class UserPresenceEvent(
      timestamp: Long,
      tenantUrl: String,
      extension: String,
      groupExt: Option[String],
      event_type: UserPresenceEventType
  ) extends Event

  object UserPresenceEvent {
    implicit val eq = Eq.fromUniversalEquals[UserPresenceEvent]
    implicit val show = Show.fromToString[UserPresenceEvent]
  }

  case class CallOriginatedEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long,
      sigCallIdOrg: Option[String],
      orgClientId: Option[String],
      sigProtocolOrg: Option[SigProtocolOrg],
      orgEndpointType: String,
      orgDisplayName: Option[String]
  ) extends SilhouetteCallEvent
      with Event

  object CallOriginatedEvent {
    implicit val eq = Eq.fromUniversalEquals[CallOriginatedEvent]
    implicit val show = Show.fromToString[CallOriginatedEvent]
  }

  case class CallTermAttemptEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long,
      orgClientId: Option[String],
      termEndpointType: Option[String],
      termEndpointExtension: Option[String],
      origDisplayName: Option[String]
  ) extends SilhouetteCallEvent
      with Event

  object CallTermAttemptEvent {
    implicit val eq = Eq.fromUniversalEquals[CallTermAttemptEvent]
    implicit val show = Show.fromToString[CallTermAttemptEvent]
  }

  case class CallAlertingEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long
  ) extends SilhouetteCallEvent
      with Event

  object CallAlertingEvent {
    implicit val eq = Eq.fromUniversalEquals[CallAlertingEvent]
    implicit val show = Show.fromToString[CallAlertingEvent]
  }

  case class CallAccountCode(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long,
      accountCode: Int
  ) extends SilhouetteCallEvent
      with Event

  object CallAccountCode {
    implicit val eq = Eq.fromUniversalEquals[CallAccountCode]
    implicit val show = Show.fromToString[CallAccountCode]
  }

  case class CallAnsweredEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long,
      sigCallIdTerm: Option[String],
      answeringExtension: Option[String],
      answeringEndpointType: Option[String]
  ) extends SilhouetteCallEvent
      with Event

  object CallAnsweredEvent {
    implicit val eq = Eq.fromUniversalEquals[CallAnsweredEvent]
    implicit val show = Show.fromToString[CallAnsweredEvent]
  }

  case class CallTransferredEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long,
      orgCliId: Option[String],
      consultationTarget: Option[String],
      transferType: TransferType
  ) extends SilhouetteCallEvent
      with Event

  object CallTransferredEvent {
    implicit val eq = Eq.fromUniversalEquals[CallTransferredEvent]
    implicit val show = Show.fromToString[CallTransferredEvent]
  }

  case class CallReleasedEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long,
      releaseCause: String
  ) extends SilhouetteCallEvent
      with Event

  object CallReleasedEvent {
    implicit val eq = Eq.fromUniversalEquals[CallReleasedEvent]
    implicit val show = Show.fromToString[CallReleasedEvent]
  }

  case class CallCodecNegotiatedEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long,
      codecType: CodecType
  ) extends SilhouetteCallEvent
      with Event

  object CallCodecNegotiatedEvent {
    implicit val eq = Eq.fromUniversalEquals[CallCodecNegotiatedEvent]
    implicit val show = Show.fromToString[CallCodecNegotiatedEvent]
  }

  case class CallTreatmentEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long,
      treatmentDescription: String
  ) extends SilhouetteCallEvent
      with Event

  object CallTreatmentEvent {
    implicit val eq = Eq.fromUniversalEquals[CallTreatmentEvent]
    implicit val show = Show.fromToString[CallTreatmentEvent]
  }

  case class CallRetrievedEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long
  ) extends SilhouetteCallEvent
      with Event

  object CallRetrievedEvent {
    implicit val eq = Eq.fromUniversalEquals[CallRetrievedEvent]
    implicit val show = Show.fromToString[CallRetrievedEvent]
  }

  case class CallHeldEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long
  ) extends SilhouetteCallEvent
      with Event

  object CallHeldEvent {
    implicit val eq = Eq.fromUniversalEquals[CallHeldEvent]
    implicit val show = Show.fromToString[CallHeldEvent]
  }

  case class CallForwardedEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long,
      forwardReason: String,
      orgClientId: Option[String]
  ) extends SilhouetteCallEvent
      with Event

  object CallForwardedEvent {
    implicit val eq = Eq.fromUniversalEquals[CallForwardedEvent]
    implicit val show = Show.fromToString[CallForwardedEvent]
  }

  case class CallProgressingEvent(
      override val silhouetteCallId: String,
      override val tenantUrl: String,
      override val timestamp: Long,
      sigCallIdTerm: Option[String],
      sigProtocolTerm: Option[SigProtocolTerm]
  ) extends SilhouetteCallEvent
      with Event

  object CallProgressingEvent {
    implicit val eq = Eq.fromUniversalEquals[CallProgressingEvent]
    implicit val show = Show.fromToString[CallProgressingEvent]
  }

  sealed trait UserPresenceEventType

  object UserPresenceEventType {
    implicit val eq = Eq.fromUniversalEquals[UserPresenceEventType]
    implicit val show = Show.fromToString[UserPresenceEventType]

    case object Busy extends UserPresenceEventType

    case object Idle extends UserPresenceEventType

    case object DndOn extends UserPresenceEventType

    case object DndOff extends UserPresenceEventType

    case object Available extends UserPresenceEventType

    case object UnAvailable extends UserPresenceEventType

    case object JoinedGroup extends UserPresenceEventType

    case object LeftGroup extends UserPresenceEventType

    case object SignedInAcd extends UserPresenceEventType

    case object SignedOutAcd extends UserPresenceEventType

    val strToObj: Map[String, UserPresenceEventType] = Map(
      "account_busy" -> Busy,
      "account_idle" -> Idle,
      "account_dnd_on" -> DndOn,
      "account_dnd_off" -> DndOff,
      "account_available" -> Available,
      "account_unavailable" -> UnAvailable,
      "account_left_group" -> LeftGroup,
      "account_joined_group" -> JoinedGroup,
      "account_signed_in_acd" -> SignedInAcd,
      "account_signed_out_Acd" -> SignedOutAcd
    )
  }

  sealed trait OrgEndpointType

  object OrgEndpointType {
    implicit val eq = Eq.fromUniversalEquals[OrgEndpointType]
    implicit val show = Show.fromToString[OrgEndpointType]

    case object Group extends OrgEndpointType

    case object Subscriber extends OrgEndpointType

    case object AutoAttendant extends OrgEndpointType

    val strToObj = Map(
      "group" -> Group,
      "subscriber" -> Subscriber,
      "AutoAttendant" -> AutoAttendant
    )
  }

  sealed trait SigProtocolOrg

  object SigProtocolOrg {
    implicit val eq = Eq.fromUniversalEquals[SigProtocolOrg]
    implicit val show = Show.fromToString[SigProtocolOrg]

    case object SIP extends SigProtocolOrg

    case object Minet extends SigProtocolOrg

    val strToObj = Map("SIP" -> SIP, "Minet" -> Minet)
  }

  sealed trait SigProtocolTerm

  object SigProtocolTerm {
    implicit val eq = Eq.fromUniversalEquals[SigProtocolTerm]
    implicit val show = Show.fromToString[SigProtocolTerm]

    case object SIP extends SigProtocolTerm

    case object Minet extends SigProtocolTerm

    val strToObj = Map("SIP" -> SIP, "Minet" -> Minet)
  }

  sealed trait TransferType

  object TransferType {
    implicit val eq = Eq.fromUniversalEquals[TransferType]
    implicit val show = Show.fromToString[TransferType]

    case object Blind extends TransferType

    case object Supervised extends TransferType

    val strToObj = Map(
      "blind" -> Blind,
      "supervised" -> Supervised
    )
  }

  sealed trait CodecType

  object CodecType {
    implicit val eq = Eq.fromUniversalEquals[CodecType]
    implicit val show = Show.fromToString[CodecType]

    case object G711u extends CodecType

    case object G711a extends CodecType

    case object G729a extends CodecType

    case object G729 extends CodecType

    case object Unsupported extends CodecType

    case object NegotiationFailure extends CodecType

    val strToObj = Map(
      "G711u" -> G711u,
      "G711a" -> G711a,
      "G729a" -> G729a,
      "G729" -> G729,
      "unsupported" -> Unsupported,
      "NegotiationFailure" -> NegotiationFailure
    )
  }

  sealed trait TreatmentDescription

  object TreatmentDescription {
    implicit val eq = Eq.fromUniversalEquals[TreatmentDescription]
    implicit val show = Show.fromToString[TreatmentDescription]

    case object RoutingError extends TreatmentDescription

    case object InvalidNumber extends TreatmentDescription

    case object InactiveSubscriber extends TreatmentDescription

    case object SubscriberUnavailable extends TreatmentDescription

    case object DeviceOnline extends TreatmentDescription

    case object DeviceDisabled extends TreatmentDescription

    case object DeviceNotSupported extends TreatmentDescription

    case object MaxNumbersOfCallForwardsReached extends TreatmentDescription

    case object NetworkBusy extends TreatmentDescription

    case object TenantInactive extends TreatmentDescription

    case object SubscriberBusy extends TreatmentDescription

    case object AllPhonesBusy extends TreatmentDescription

    case object ServiceUnavailable extends TreatmentDescription

    case object PRIPortsUnavailable extends TreatmentDescription

    case object EndPointUnavailable extends TreatmentDescription

    case object OwnNumberDialed extends TreatmentDescription

    case object CodecMismatch extends TreatmentDescription

    case object CallTimeOut extends TreatmentDescription

    case object UnableToIntercom extends TreatmentDescription

    case object SIPInvalidNumber extends TreatmentDescription

    case object SIPNetworkProblem extends TreatmentDescription

    case object NoLinesAvailable extends TreatmentDescription

    case object MaxNumberOfInboundCalls extends TreatmentDescription

    case object MaxNumberOfOutboundCalls extends TreatmentDescription

    case object UnknownError extends TreatmentDescription

  }

  sealed trait UserPresenceState

  object UserPresenceState {
    implicit val eq = Eq.fromUniversalEquals[UserPresenceState]
    implicit val show = Show.fromToString[UserPresenceState]

    case object Unavailable extends UserPresenceState

    case object DndOn extends UserPresenceState

    case object Busy extends UserPresenceState

    case object Idle extends UserPresenceState

    val strToObj: Map[String, UserPresenceState] = Map(
      "busy" -> Busy,
      "idle" -> Idle,
      "dnd_on" -> DndOn,
      "unavailable" -> Unavailable
    )
  }

}
