package com.sludg.pages.mainpage.grid.cells.widget_1

import com.sludg.helpers.Helper.{
  CallStatsType,
  UserPresenceType,
  UserStatsType,
  VueEventUndefined,
  eventProcessor,
  undefined
}
import com.sludg.pages.mainpage.config.DashboardConfig.{
  ComponentConfiguration,
  UserStatsComponentConfig
}
import com.sludg.pages.mainpage.grid.cells.widget_1.components.UserPresence.{
  TableItems,
  updateTable
}
import com.sludg.services.ApiCalls
import com.sludg.util.models.Events.UserPresenceState
import com.sludg.util.models.ReportModels.Filter.{
  DirectionFilter,
  ExtensionFilter,
  RelativeDateFilter
}
import com.sludg.vue.{EventBindings, RenderHelpers, ScopedSlots, VNode, Vue, VueProps}
import play.api.libs.json.Json
import com.sludg.json.JsonDeserialiser._

import scala.collection.immutable.List
import scala.scalajs.js

object GenericComponent {
  /*
   * The beginings of a generified dashboard component
   * This component has 2 parts, the main screen and the component config screen
   * The main screen has 2 parts, the title, and the data section
   * Maintains the state of the component
   * */

  def extensionSelector = ExtensionSelector.ExtensionSelectorRenderer("extensionSelector")

  //TODO Generify updateEventSubscription methods
  def updateEventSubscriptionn(
      c: UserStatsType,
      tenantId: Int,
      relativeDateFilter: Option[RelativeDateFilter] = None,
      directionFilter: Option[DirectionFilter] = None,
      extensionFilter: Option[ExtensionFilter] = None,
      design: Option[String] = None
  ): Unit = {
    val directionFilterUpdated = directionFilter.filter(_.direction.nonEmpty)
    c.$emit(
      "updateComponentEventSubscription",
      (
        c.id,
        ComponentConfiguration(
          tenantId,
          List(relativeDateFilter, directionFilterUpdated, extensionFilter).flatten,
          componentDesign = design
        )
      )
    )
  }

  def updateEventSubscription(c: UserPresenceType, apiCalls: ApiCalls, loader: Vue): Unit = {

    val extensionFilter = ExtensionFilter(c.selectedSubscribers.map(_.extension).toList)

    c.$emit(
      "updateComponentEventSubscription",
      (c.id, ComponentConfiguration(c.selectedTenant, List(extensionFilter)))
    )

    updateTable(c.selectedSubscribers.toList, c, apiCalls, loader, c.selectedTenant, c.token)
    c.tableItems = Some(
      c.selectedSubscribers.toList
        .sortBy(_.extension)
        .filterNot(_.firstName.getOrElse("") == "")
        .map(a =>
          new TableItems(a.extension, a.firstName.getOrElse("-"), UserPresenceState.Unavailable)
        )
    )
  }

  def updateEventSubscription(c: CallStatsType): Unit = {
    c.$emit(
      "updateComponentEventSubscription",
      (
        c.id,
        ComponentConfiguration(
          c.selectedTenant,
          List(
            RelativeDateFilter(0),
            ExtensionFilter(c.selectedSubscribers.map(_.extension).toList)
          )
        )
      )
    )
  }

  // Events
  trait DashboardComponentEvents extends EventBindings {}

  object DashboardComponentEvents {
    case class UpdateComponentConfig(
        componentId: Int,
        componentConfiguration: ComponentConfiguration
    )

    def apply(
        bindings: EventBindings = EventBindings(),
        updateComponentEventSubscription: VueEventUndefined[UpdateComponentConfig] = ()
    ): DashboardComponentEvents = {
      eventProcessor((updateComponentEventSubscription, "updateComponentEventSubscription"))(
        bindings
      )
      bindings.asInstanceOf[DashboardComponentEvents]
    }
  }

  // Scoped Slots
  trait DashboardComponentScopedSlots extends ScopedSlots {
    val componentTitle: js.UndefOr[js.Function1[Boolean, VNode]]
  }

}
