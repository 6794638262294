package com.sludg.pages.mainpage.grid.cells.widget_1.components
import com.sludg.FieldExtractor
import com.sludg.vue.{EventBindings, RenderHelpers, RenderOptions, Vue, _}
import cats.implicits._
import monix.execution.Scheduler.Implicits.global
import cats.data.EitherT
import com.sludg.helpers.LoadingFuture
import com.sludg.models.Models
import com.sludg.helpers.{Helper, States}
import com.sludg.helpers.States.EditingMode
import com.sludg.scalajs.DynamicHelper
import com.sludg.services.ApiCalls
import com.sludg.util.models.CallModels._
import com.sludg.util.models.ReportModels.Filter
import com.sludg.util.models.SilhouetteModels.{Subscriber, Tenant}
import com.sludg.vue.RenderHelpers._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import org.log4s._

import scala.concurrent.Future
import scala.scalajs.js.JSConverters._
import scala.scalajs.js
import com.sludg.auth0.SludgToken
import Helper._
import com.sludg.pages.mainpage.grid.cells.widget_1.GenericComponent.{DashboardComponentScopedSlots, extensionSelector}
import com.sludg.pages.mainpage.config.DashboardConfig.{ComponentConfiguration, UserStatsComponentConfig}
import com.sludg.helpers.States.EditingMode.Editing
import com.sludg.pages.mainpage.grid.cells.widget_1.ExtensionSelector.{ExtensionSelectorEvents, ExtensionSelectorProps}
import com.sludg.pages.mainpage.grid.cells.widget_1.ExtensionSelector
import com.sludg.util.models.ReportModels.Filter.{ExtensionFilter, RelativeDateFilter}
import com.sludg.vue.VueInstanceProperties.CreateElement
import play.api.libs.json.{JsValue => _, Reads => _, _}
import com.sludg.pages.mainpage.grid.cells.widget_1.GenericComponent._

import scala.collection.immutable.List

object InOutCallStats {
  val logger = getLogger

  def callStatsRenderer(registrationName: String): RenderHelpers.NodeRenderer[CallStatsProps, EventBindings, ScopedSlots] = namedTag[CallStatsProps, EventBindings, ScopedSlots](registrationName)

  def statsComponent(loader: Vue, apiCalls: ApiCalls) = {
    VueComponent.builder
      .withData(new CallStatsData)
      .withScopedSlots[DashboardComponentScopedSlots]
      .withPropsAs[CallStatsProps]
      .build(
        components = js.defined(js.Dynamic.literal("extensionSelector" -> ExtensionSelector.extensionSelectorComponent())),
        watch = new EventsWatcher(),
        created = js.defined(c => {
          c.a = Some(apiCalls)
          c.l = Some(loader)
          initializeComponent(c.filters, c.selectedTenant, c.statsType, loader: Vue, apiCalls: ApiCalls, c.token, c)
        }),
        templateOrRender = Right((c, renderer) => {
          div(
            RenderOptions(`class` = List(Left("background-white-full"))),
            c.$scopedSlots.componentTitle.foldToNode(x => x(c.editing), nothing),
            editButton(apiCalls, loader, renderer, c),
            if (c.editing && c.editMode == Editing) editScreen(c) else mainScreen(apiCalls, loader, renderer, c)
          ).render(renderer)
        })
      )
  }

  def editScreen(c: CallStatsType) = {
    div(
      RenderOptions(`class` = List(Left("white-Background-scroll-y"))),
      vLayout(
        RenderOptions(`class` = List(Left("full-width-bottom"))),
        vFlex(
          RenderOptions(`class` = List(Left("background-white"))),
          extensionSelector(
            RenderOptions(
              props = Some(new ExtensionSelectorProps(c.selectableSubscribers, c.selectedSubscribers.toList, Some((c.width * 1.5).toInt))),
              on = Some(
                new ExtensionSelectorEvents(
                  selectionUpdate = js.defined(e => {
                    c.selectedSubscribers = e.sortBy(_.extension).toJSArray
                    c.newlySelectedSubscribers = e.toJSArray
                    updateEventSubscription(c)
                  })
                )
              )
            )
          )
        )
      )
    )
  }

  def editButton(apiCalls: ApiCalls, loader: Vue, renderer: CreateElement, c: CallStatsType) = {
    div(
      if (c.editMode == Editing) {
        vButton(
          if (!c.editing) vIcon("edit") else vIcon("save"),
          RenderOptions(
            style = Some(
              js.Dynamic.literal(
                "height"   -> "25px",
                "width"    -> "25px",
                "position" -> "absolute",
                "right"    -> "30px",
                "top"      -> "6px",
                "z-index"  -> "15"
              )
            ),
            props = Some(VButtonProps(icon = Some(true))),
            on = Some(EventBindings(click = js.defined(e => {
              c.configMenuVisible = false
              updateFilterMenuValues(c)
              c.editing = !c.editing

              updateComponentData(c.selectedTenant, c.statsType, c.configMenuFilters, loader, apiCalls, c)(c.token)

              val extensionFilter = ExtensionFilter(c.newlySelectedSubscribers.map(_.extension).toList)
              c.$emit("updateComponentEventSubscription", (c.id, ComponentConfiguration(c.selectedTenant, List(RelativeDateFilter(0), extensionFilter))))
            })))
          )
        )
      } else {
        div()
      }
    )
  }

  def mainScreen(apiCalls: ApiCalls, loader: Vue, renderer: CreateElement, c: CallStatsType): RenderFunction[VNode] = {
    div(
      summaryData(c),
      dataTable(renderer, c)
    )
  }

  def summaryData(c: CallStatsType) = {
    val res   = (c.callsServiced.toDouble / c.totalCalls.toDouble * 100).toInt.toString + "%"
    val style = Some(js.Dynamic.literal("margin-top" -> "7px"))

    if (c.statsType == States.IncomingStats) {
      if (c.height == 2 && c.width == 2) {
        div(
          vLayout(
            RenderOptions(`class` = List(Left("center-text")), style = style),
            vFlexRow("Total Calls", "Answered/Missed")
          ),
          vLayout(
            RenderOptions(`class` = List(Left("center-text2"))),
            vFlexRow(c.totalCalls.toString, c.callsServiced.toString + "/" + c.callsDropped.toString)
          )
        )
      } else {
        div(
          vLayout(
            RenderOptions(
              style = style,
              `class` = List(Left("center-text"))
            ),
            vFlexRow("Total Calls", "Service", "Calls Serviced", "Calls Dropped")
          ),
          vLayout(
            RenderOptions(`class` = List(Left("center-text2"))),
            vFlexRow(c.totalCalls.toString, res, c.callsServiced.toString, c.callsDropped.toString)
          )
        )
      }
    } else {
      div(
        vLayout(
          RenderOptions(`class` = List(Left("center-text")), style = style),
          vFlexRow("Calls made", "Calls Answered")
        ),
        vLayout(
          RenderOptions(`class` = List(Left("center-text2"))),
          vFlexRow(c.totalCalls.toString, c.callsServiced.toString)
        )
      )
    }
  }

  def dataTable(renderer: CreateElement, c: CallStatsType) = {
    div(
      if (c.height > 2) {
        div(
          VDataTable.vDataTable(
            RenderOptions[VDataTableProps[TableItems, VDataTableHeader], EventBindings, VDataTableScopedSlots[TableItems, VDataTableHeader]](
              style = Some(js.Dynamic.literal("height" -> "100%")),
              props = Some(
                VDataTableProps[TableItems, VDataTableHeader](
                  dense = Some(true),
                  headers = Some(
                    List(
                      new VDataTableHeader(sortable = false, text = ""),
                      new VDataTableHeader(sortable = false, text = "Total"),
                      new VDataTableHeader(sortable = false, text = "Average"),
                      new VDataTableHeader(sortable = false, text = "Max")
                    )
                  ),
                  items = Some(c.tableItems),
                  `hide-actions` = Some(true)
                )
              ),
              scopedSlots = Some(
                new VDataTableScopedSlots[TableItems, VDataTableHeader](
                  items = js.defined(i =>
                    tr(
                      List(i.item.key, i.item.total, i.item.average, i.item.max).map(key =>
                        td(
                          RenderOptions(style = Some(
                            js.Dynamic.literal(
                              "width"     -> "35%",
                              "font-size" -> "12px"
                            )
                          )
                          ),
                          key
                        )
                      )
                    ).render(renderer)
                  )
                )
              )
            )
          )
        )
      } else {
        div()
      }
    )
  }

  def initializeComponent(
      filters: List[Filter],
      tenantId: Int,
      statsType: States.CallStats,
      loader: Vue,
      apiCalls: ApiCalls,
      token: SludgToken,
      c: CallStatsType
  ): Future[Either[Models.AccessForbidden, Unit]] = {
    val extensionFilter = filters.collectFirst { case i: ExtensionFilter => i }
    extensionFilter match {
      case Some(exFilter) => {
        /* set extension filter */
        if (extensionFilter.nonEmpty) {
          c.configMenuFilters = List(exFilter, RelativeDateFilter(0))
        }
        getRefreshData(statsType: States.CallStats, apiCalls, loader, c, tenantId, token, exFilter)
      }
      case None => getRefreshData(statsType: States.CallStats, apiCalls, loader, c, tenantId, token)
    }
  }

  def updateFilterMenuValues(c: CallStatsType) = {
    if (c.newlySelectedSubscribers.nonEmpty) {
      c.configMenuFilters = List(Filter.ExtensionFilter(c.newlySelectedSubscribers.map(_.extension).toList), RelativeDateFilter(0))
    } else {
      c.configMenuFilters = List(RelativeDateFilter(0))
    }
    c.selectedSubscribers = c.newlySelectedSubscribers.toJSArray
  }

  def getRefreshData(
      statsType: States.CallStats,
      apiCalls: ApiCalls,
      loader: Vue,
      c: CallStatsType,
      tenantId: Int,
      token: SludgToken,
      exFilter: ExtensionFilter = ExtensionFilter(Nil)
  ): Future[Either[Models.AccessForbidden, Unit]] = {
    LoadingFuture.withLoading(
      loader,
      (for {
        callStats   <- EitherT(statsApiCall(statsType, c.configMenuFilters, apiCalls, tenantId)(token))
        subscribers <- EitherT(apiCalls.getSubscribers(tenantId)(implicitly, token))
      } yield {
        logger.debug("all my subs " + subscribers)
        val loadedSubscribers = subscribers.filterNot(_.firstName.getOrElse("") == "")
        c.selectableSubscribers = loadedSubscribers.sortBy(_.extension)
        c.selectedSubscribers = loadedSubscribers.filter { case x if exFilter.extensions.contains(x.extension) => true; case _ => false }.sortBy(_.extension).toJSArray

        c.newlySelectedSubscribers = c.selectedSubscribers
        setCallStats(callStats, c)
      }).value
    )
  }

  def updateComponentData(tenantId: Int, statsType: States.CallStats, filters: List[Filter], loader: Vue, apiCalls: ApiCalls, c: CallStatsType)(implicit token: SludgToken) = {
    LoadingFuture.withLoading(
      loader,
      statsApiCall(statsType, filters, apiCalls, tenantId).map {
        case Left(error) => logger.debug("Stats data cannot be parsed from api" + error)
        case Right(data) => setCallStats(data, c)
      }
    )
  }

  def statsApiCall(c: States.CallStats, filters: List[Filter], apiCalls: ApiCalls, tenantId: Int)(implicit token: SludgToken): Future[Either[Models.AccessForbidden, Option[CallStats]]] = {
    // Api return no result if an empty extension filter is present.
    val processedFilters = if (filters.contains(ExtensionFilter(Nil))) filters.filterNot(a => a.isInstanceOf[ExtensionFilter]) else filters
    if (c == States.IncomingStats) apiCalls.getIncomingCallStats(tenantId, processedFilters) else apiCalls.getOutgoingCallStats(tenantId, processedFilters)
  }

  def setCallStats(callStats: Option[CallStats], c: CallStatsType): Unit = {
    callStats match {
      case Some(callStat) => setData(c, callStat)
      case None           => logger.debug("Data from the api could not be parsed.");
    }
  }

  def setData(stats: CallStatsType, x: CallStats): Unit = {
    stats.totalCalls = x.totalsCalls
    stats.callsServiced = x.answered
    stats.callsDropped = x.notAnswered

    val a = new TableItems(
      ("Ring"),
      (formatTime(x.ringStats.sum.toString)),
      (formatTime(x.ringStats.average.getOrElse(0.0).toInt.toString)),
      (formatTime(x.ringStats.max.map(_.toString).getOrElse("0")))
    )

    val b = new TableItems(
      ("Talk"),
      (formatTime(x.talkStats.sum.toString)),
      (formatTime(x.talkStats.average.getOrElse(0.0).toInt.toString)),
      (formatTime(x.talkStats.max.map(_.toString).getOrElse("0")))
    )

    stats.tableItems = List(a, b)
  }

  class EventsWatcher extends js.Object {
    def editMode(value: js.Any) = {
      val i = this.asInstanceOf[CallStatsType]

      /**
        * If you click the save button in the toolbar
        * and you are editing this component
        * The components data is reloaded as it exists editmode
        * This is to prevent changes in filters not being saved and used to update data
        */
      if (i.editing) {
        i.editing = false

        (i.a, i.l) match {
          case (Some(a), Some(l)) => updateComponentData(i.selectedTenant, i.statsType, i.configMenuFilters, l, a, i)(i.token)
          case _                  => logger.debug("Could not load data in user stats")
        }
      }
    }

    def reset() = {
      val c = this.asInstanceOf[CallStatsType]
      c.configMenuFilters = List[Filter](RelativeDateFilter(0))
      c.selectableSubscribers = List()
      c.selectedSubscribers = js.Array()
      c.newlySelectedSubscribers = js.Array()
      c.totalCalls = 0
      c.callsServiced = 0
      c.callsDropped = 0
      c.tableItems = List(new TableItems("Ring"), new TableItems("Talk"))

      (c.a, c.l) match {
        case (Some(apiCalls), Some(loader)) => {
          initializeComponent(c.filters, c.selectedTenant, c.statsType, loader, apiCalls, c.token, c)
        }
        case _ => logger.debug("Could not load data in user stats")
      }
    }

    def refresh(value: Boolean): Unit = {
      val u = this.asInstanceOf[CallStatsType]

      (u.a, u.l) match {
        case (Some(a), Some(l)) => {
          updateComponentData(u.selectedTenant, u.statsType, u.configMenuFilters, l, a, u)(u.token)
        }
        case _ => logger.debug("Not found api calls or loader.")
      }
    }
  }

  class CallStatsData extends js.Object {
    var configMenuVisible                              = false
    var configMenuFilters: List[Filter]                = List[Filter](RelativeDateFilter(0))
    var selectableSubscribers: List[Subscriber]        = Nil
    var selectedSubscribers, newlySelectedSubscribers: js.Array[Subscriber]      = List[Subscriber]().toJSArray

    var editing       = true
    var totalCalls, callsServiced, callsDropped   = 0
    var tableItems: List[TableItems] = List(new TableItems("Ring"), new TableItems("Talk"))
    var a: Option[ApiCalls] = None
    var l: Option[Vue]      = None
  }

  class TableItems(val key: String = "", val total: String= "0", val average: String = "0", val max: String = "0") extends js.Object

  class CallStatsProps(val reset: Boolean, val editMode: EditingMode, val selectedTenant: Int, val id: Int, val refresh: Boolean, val height: Int, val width: Int, val statsType: States.CallStats, val filters: List[Filter], val token: SludgToken) extends VueProps

}
