package com.sludg.pages.mainpage.grid.cells.widget_1.components

import com.sludg.FieldExtractor
import com.sludg.scalajs.DynamicHelper
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import com.sludg.vue.{EventBindings, RenderHelpers, RenderOptions, ScopedSlots, Vue, _}
import com.sludg.helpers.LoadingFuture
import com.sludg.models.Models
import com.sludg.helpers.States.EditingMode
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels.{Subscriber, Tenant}
import com.sludg.vue.RenderHelpers.{div, namedTag, p, td, tr, _}
import org.log4s.getLogger
import com.sludg.vue.RenderHelpers.{p, _}

import scala.concurrent.Future
import scala.scalajs.js.JSConverters._
import scala.scalajs.js
import com.sludg.auth0.SludgToken
import com.sludg.pages.mainpage.config.DashboardConfig.ComponentConfiguration
import com.sludg.helpers.Helper._
import com.sludg.helpers.Helper.UserPresenceType
import com.sludg.util.models.Events.UserPresenceState.{Idle, Unavailable}
import com.sludg.util.models.Events.{UserPresenceEvent, UserPresenceEventType, UserPresenceState}
import com.sludg.util.models.ReportModels.Filter
import com.sludg.util.models.ReportModels.Filter.ExtensionFilter
import com.sludg.helpers.States.EditingMode.Editing
import com.sludg.vue.VueInstanceProperties.CreateElement
import cats.implicits._
import com.sludg.pages.mainpage.grid.cells.widget_1.ExtensionSelector
import com.sludg.pages.mainpage.grid.cells.widget_1.GenericComponent._
import com.sludg.pages.mainpage.grid.cells.widget_1.ExtensionSelector.{ExtensionSelectorEvents, ExtensionSelectorProps}

import scala.collection.immutable.List
import com.sludg.pages.mainpage.grid.cells.widget_1._
object UserPresence {

  import monix.execution.Scheduler.Implicits.global

  private[components] val logger = getLogger

  def userPresenceRenderer(registrationName: String): RenderHelpers.NodeRenderer[UserPresenceProps, EventBindings, ScopedSlots] =
    namedTag[UserPresenceProps, EventBindings, ScopedSlots](registrationName)

  def userPresenceComponent(loader: Vue, apiCalls: ApiCalls): VueComponent[_ <: UserPresenceProps, _ <: Slots, _ <: DashboardComponentScopedSlots] = {
    VueComponent.builder
      .withData(new UserPresenceData)
      .withPropsAs[UserPresenceProps]
      .withScopedSlots[DashboardComponentScopedSlots]
      .build(components = js.defined(js.Dynamic.literal("extensionSelector" -> ExtensionSelector.extensionSelectorComponent())),
        watch = new EventsWatcher(),
        created = js.defined(c => {
          c.api = Some(apiCalls)
          c.loader = Some(loader)
          initialiseComponent(c, c.selectedTenant, loader, c.token, apiCalls)
        }),
        templateOrRender = Right((c, renderer) => {
          div(
            RenderOptions(`class` = List(Left("background-white-full"))),
            c.$scopedSlots.componentTitle.foldToNode(x => x(c.editing), nothing),
            editButton(c),
            if (c.editing && c.editMode == Editing) editScreen(apiCalls, loader, renderer, c) else mainScreen(apiCalls, loader, renderer, c)
          ).render(renderer)
        })
      )
  }

  def editScreen(apiCalls: ApiCalls, loader: Vue, renderer: CreateElement, c: UserPresenceType) = {

    div(RenderOptions(`class` = List(Left("white-Background-scroll-y"))),
      vLayout(
        vFlex(
          RenderOptions(`class` = List(Left("background-white"))),
          extensionSelector(RenderOptions(
            props = Some(new ExtensionSelectorProps(c.selectableSubscribers, c.selectedSubscribers.toList, Some((c.width * 1.5).toInt))),
            on = Some(
              new ExtensionSelectorEvents(
                selectionUpdate = js.defined(e => {
                  c.selectedSubscribers = e.sortBy(_.extension).toJSArray
                  updateEventSubscription(c, apiCalls, loader)
                })
              )
            )
          )
          )
        )
      )
    )
  }

  def editButton(c: UserPresenceType) = {
    if (c.editMode == Editing) {
      vButton(
        if (!c.editing) vIcon("edit") else vIcon("save"),
        RenderOptions(
          style = Some(
            js.Dynamic.literal(
              "height"   -> "30px",
              "width"    -> "30px",
              "position" -> "absolute",
              "right"    -> "30px",
              "top"      -> "3px",
              "z-index"  -> "15"
            )
          ),
          props = Some(VButtonProps(icon = Some(true))),
          on = Some(EventBindings(click = js.defined(e => c.editing = !c.editing)))
        )
      )
    } else {
      div()
    }
  }

  def mainScreen(apiCalls: ApiCalls, loader: Vue, renderer: CreateElement, c: UserPresenceType): RenderFunction[VNode] = {
    vCard(
      RenderOptions(`class` = List(Left("white-Background-scroll-y"))),
      vSlideXTransition(
        div(
          VDataTable.vDataTable(
            RenderOptions[VDataTableProps[TableItems, VDataTableHeader], EventBindings, VDataTableScopedSlots[TableItems, VDataTableHeader]](
              style = Some(js.Dynamic.literal("height" -> "100%")),
              props = Some(
                VDataTableProps[TableItems, VDataTableHeader](
                  dense = Some(true),
                  headers = Some(
                    List(
                      new VDataTableHeader(sortable = false, text = "Extension"),
                      new VDataTableHeader(sortable = false, text = "Name"),
                      new VDataTableHeader(sortable = false, text = "Status"),
                      new VDataTableHeader(sortable = false, text = "")
                    )
                  ),
                  items = c.tableItems,
                  `hide-actions` = Some(true)
                )
              ),
              scopedSlots = Some(
                new VDataTableScopedSlots[TableItems, VDataTableHeader](
                  items = js.defined(i => {
                    tr(
                      td(RenderOptions(`class` = List(Left("style_7"))), i.item.extension),
                      td(RenderOptions(`class` = List(Left("style_7"))), i.item.name),
                      td(RenderOptions(`class` = List(Left("nine-width"))), userStatus(i.item.status)),
                      td(
                        RenderOptions(`class` = List(Left("two-width"))),
                        if (isEditing(c.editMode)) {
                          vButton(
                            vIcon("clear"),
                            RenderOptions(
                              `class` = List(Left("style_19")),
                              style = Some(js.Dynamic.literal("background-color" -> "#eaeaea")),
                              props = Some(VButtonProps(icon = Some(true))),
                              on = Some(
                                EventBindings(
                                  click = js.defined(e => {
                                    c.tableItems = Some(
                                      c.tableItems
                                        .getOrElse(List[TableItems]())
                                        .filterNot(_.extension == i.item.extension)
                                    )

                                    // Filter the selected based on tableItem extensions
                                    c.selectedSubscribers = c.selectedSubscribers.filterNot(_.extension == i.item.extension).sortBy(_.extension)

                                    val extensionFilter = ExtensionFilter(c.selectedSubscribers.toList.map(_.extension))

                                    c.$emit("updateComponentEventSubscription", (c.id, ComponentConfiguration(c.selectedTenant, List(extensionFilter))))
                                  })
                                )
                              )
                            )
                          )
                        } else {
                          div()
                        }
                      )
                    )
                  }.render(renderer))
                )
              )
            )
          )
        )
      )
    )
  }

  def userStatus(userStatus: UserPresenceState): RenderFunction[VNode] = {
    userStatus match {
      case UserPresenceState.Unavailable =>
        vIcon("phone_disabled", RenderOptions(`class` = List(Left("style_16"))))
      case UserPresenceState.DndOn =>
        vIcon("do_not_disturb_on", RenderOptions(props = Some(VIconProps(color = Some("yellow darken-2"))), `class` = List(Left("style_16"))))
      case UserPresenceState.Busy =>
        vIcon("phone_in_talk", RenderOptions(props = Some(VIconProps(color = Some("red darken-2"))), `class` = List(Left("style_16"))))
      case UserPresenceState.Idle =>
        vIcon("call_end", RenderOptions(props = Some(VIconProps(color = Some("green darken-2"))), `class` = List(Left("style_16"))))
    }
  }

  def updateTable(selectedSubscribers: List[Subscriber], component: UserPresenceType, apiCalls: ApiCalls, loader: Vue, tenantId: Int, token: SludgToken): Unit = {

    component.selectedSubscribers = selectedSubscribers.sortBy(_.extension).filterNot(_.firstName.getOrElse("") == "").sortBy(_.extension).toJSArray

    val extensions: List[String]                                                              = selectedSubscribers.map(_.extension)
    val apiCallsList: Future[List[Either[Models.AccessForbidden, Option[UserPresenceState]]]] = extensions.map(sub => apiCalls.getUserPresence(tenantId, sub)(implicitly, token)).sequence

    val myFutureDictionary: Future[Map[String, Option[UserPresenceState]]] =
      apiCallsList.map(statusCalls =>
        (extensions zip statusCalls).collect {
          case (extension, Right(status)) if status.isDefined => (extension, status)
        }.toMap
      )

    myFutureDictionary.foreach(myDictionary => {
      component.tableItems = Some(selectedSubscribers.map(a => new TableItems((a.extension), a.firstName.getOrElse(""), myDictionary.get(a.extension).flatten.getOrElse(Idle))))
    })
  }

  class TableItems(val extension: String = "", val name: String= "", val status: UserPresenceState = Idle) extends js.Object

  def initialiseComponent(component: UserPresenceType, tenantId: Int, loader: Vue, token: SludgToken, apiCalls: ApiCalls): Unit = {
    LoadingFuture.withLoading(
      loader,
      apiCalls.getSubscribers(tenantId)(implicitly, token).map {
        case Left(x) => logger.debug("No access.")
        case Right(result) => {
          component.selectableSubscribers = result.filterNot(_.firstName.getOrElse("") == "").sortBy(_.extension)
          applyFilters(apiCalls, component, loader, tenantId, component.filters, component.selectedTenant, token)
        }
      }
    )
  }

  // Gets saved subscribers from db and updates table with them
  def applyFilters(apiCalls: ApiCalls, component: UserPresenceType, loader: Vue, tenantId: Int, filters: List[Filter], sTid: Int, token: SludgToken) = {
    filters.collectFirst { case i: ExtensionFilter => i } match {
      case Some(extensionFilter) => {
        component.selectedSubscribers = component.selectableSubscribers
          .filter(sub => extensionFilter.extensions.contains(sub.extension)).sortBy(_.extension)
          .toJSArray
        updateTable(component.selectableSubscribers.filter(sub => extensionFilter.extensions.contains(sub.extension)), component, apiCalls, loader, tenantId, token)
      }
      case None => {
        logger.debug("No filter.")
      }
    }
  }

  class EventsWatcher() extends js.Object {
    def editMode(value: js.Any) = this.asInstanceOf[UserPresenceType].editing = false

    def userPresenceEvent(value: js.Any) = {
      val component = this.asInstanceOf[UserPresenceType]
      val newEvent  = value.asInstanceOf[js.UndefOr[UserPresenceEvent]]

      newEvent.toOption match {
        case None    => logger.debug("Event is bad.")
        case Some(x) => updateList(component, x)
      }
    }

    def reset() = {
      val c = this.asInstanceOf[UserPresenceType]
      UserPresence.logger.debug("Resetting User Presence")
      // Resets component
      (c.api, c.loader) match {
        case (Some(apiCalls), Some(loader)) =>
          initialiseComponent(c, c.selectedTenant, loader, c.token, apiCalls)
        case _ => logger.debug("Could not load data in user stats")
      }
    }
  }

  def updateList(component: UserPresenceType, userPresenceEvent: UserPresenceEvent): Unit = {
    component.tableItems = Some(
      component.tableItems
        .getOrElse(List[TableItems]())
        .map(x =>
          if (x.extension == userPresenceEvent.extension) {
            new TableItems(
              x.extension,
              x.name,
              userPresenceEvent.event_type match {
                case UserPresenceEventType.UnAvailable => UserPresenceState.Unavailable
                case UserPresenceEventType.Busy        => UserPresenceState.Busy
                case UserPresenceEventType.Idle        => UserPresenceState.Idle
                case UserPresenceEventType.DndOn       => UserPresenceState.DndOn
                case UserPresenceEventType.Available   => UserPresenceState.Idle
                case UserPresenceEventType.DndOff      => UserPresenceState.Idle
                case _                                 => UserPresenceState.Idle
              }
            )
          } else {
            x
          }
        )
    )
  }

  class UserPresenceProps(
                          val reset: Boolean,
                          val editMode: EditingMode,
                          val selectedTenant: Int,
                          val id: Int,
                          val height: Int,
                          val width: Int,
                          val filters: List[Filter],
                          val userPresenceEvent: UserPresenceEvent,
                          val token: SludgToken
                      ) extends VueProps

  class UserPresenceData extends js.Object {
    var tableItems: Option[List[TableItems]]      = Some(Nil)
    var selectableSubscribers: List[Subscriber]   = Nil
    var selectedSubscribers: js.Array[Subscriber] = js.Array[Subscriber]() // value field doesn't work with non-js type // needs to be a prop
    var editing                                   = true

    /* loader and apiCalls*/
    var api: Option[ApiCalls] = None
    var loader: Option[Vue]   = None
  }

}
