package com.sludg.json

import com.sludg.pages.mainpage.config.DashboardConfig.UserStatsComponentConfig
import com.sludg.helpers.States
import com.sludg.helpers.States.{Ordering, PriorityOrder}
import com.sludg.util.JsonUtils.formatViaMap
import play.api.libs.json._

object JsonDeserialiser {

  implicit val ordering: Format[States.Ordering] = formatViaMap(States.Ordering.strToObj)
  implicit val por: Reads[PriorityOrder] = Json.reads[PriorityOrder]
  implicit val pow: Writes[PriorityOrder] = Json.writes[PriorityOrder]

  implicit val usecr: Reads[UserStatsComponentConfig] = Json.reads[UserStatsComponentConfig]
  implicit val usecw: Writes[UserStatsComponentConfig] = Json.writes[UserStatsComponentConfig]
}
