package buildinfo

import scala.Predef._

/** This object was generated by sbt-buildinfo. */
case object sbtinfo {
  /** The value is "dashboards". */
  val name: String = "dashboards"
  /** The value is "1.0.7". */
  val version: String = "1.0.7"
  /** The value is "2.13.5". */
  val scalaVersion: String = "2.13.5"
  /** The value is "1.4.6". */
  val sbtVersion: String = "1.4.6"
  override val toString: String = {
    "name: %s, version: %s, scalaVersion: %s, sbtVersion: %s".format(
      name, version, scalaVersion, sbtVersion
    )
  }
}
