package com.sludg.util.json

import play.api.libs.json._
import com.sludg.util.models.Events._
import play.api.libs.json
import com.sludg.util.JsonUtils.formatViaMap
import com.sludg.util.models.CallGroupCounterModels.CallGroupCallStats

import scala.reflect.ClassTag

/**
  * @author dpoliakas
  *         Date: 2019-11-07
  *         Time: 13:01
  */
object EventsDeserializer {
  implicit val a: Format[SigProtocolOrg] = formatViaMap(SigProtocolOrg.strToObj)
  implicit val b: Format[SigProtocolTerm] = formatViaMap(
    SigProtocolTerm.strToObj
  )
  implicit val c: Format[TransferType] = formatViaMap(TransferType.strToObj)
  implicit val d: Format[CodecType] = formatViaMap(CodecType.strToObj)
  implicit val upe: Format[UserPresenceEventType] = formatViaMap(
    UserPresenceEventType.strToObj
  )

  implicit val useClassReads: Reads[UserPresenceEvent] =
    Json.reads[UserPresenceEvent]
  implicit val useClassWrites: Writes[UserPresenceEvent] =
    Json.writes[UserPresenceEvent]

  private implicit val callOriginRead: Reads[CallOriginatedEvent] =
    Json.reads[CallOriginatedEvent]
  private implicit val callTermRead: Reads[CallTermAttemptEvent] =
    Json.reads[CallTermAttemptEvent]
  private implicit val callAnsweredRead: Reads[CallAnsweredEvent] =
    Json.reads[CallAnsweredEvent]
  private implicit val callAlrtRead: Reads[CallAlertingEvent] =
    Json.reads[CallAlertingEvent]
  private implicit val callTransferedRead: Reads[CallTransferredEvent] =
    Json.reads[CallTransferredEvent]
  private implicit val callReleasedRead: Reads[CallReleasedEvent] =
    Json.reads[CallReleasedEvent]
  private implicit val callCodecRead: Reads[CallCodecNegotiatedEvent] =
    Json.reads[CallCodecNegotiatedEvent]
  private implicit val callTreatmentRead: Reads[CallTreatmentEvent] =
    Json.reads[CallTreatmentEvent]
  private implicit val callRetrievedRead: Reads[CallRetrievedEvent] =
    Json.reads[CallRetrievedEvent]
  private implicit val callHeldRead: Reads[CallHeldEvent] =
    Json.reads[CallHeldEvent]
  private implicit val callForwardedRead: Reads[CallForwardedEvent] =
    Json.reads[CallForwardedEvent]
  private implicit val callProcessingRead: Reads[CallProgressingEvent] =
    Json.reads[CallProgressingEvent]
  private implicit val callAccountCodeRead: Reads[CallAccountCode] =
    Json.reads[CallAccountCode]
  implicit val callGroupStatsRead: Reads[CallGroupCallStats] =
    Json.reads[CallGroupCallStats]
  private implicit val callGroupStatsEventRead: Reads[CallGroupStatsEvent] =
    Json.reads[CallGroupStatsEvent]

  private implicit val callOriginWrite: Writes[CallOriginatedEvent] =
    Json.writes[CallOriginatedEvent]
  private implicit val callTermW: Writes[CallTermAttemptEvent] =
    Json.writes[CallTermAttemptEvent]
  private implicit val wcallAnsweredRead: Writes[CallAnsweredEvent] =
    Json.writes[CallAnsweredEvent]
  private implicit val wcallAlrtRead: Writes[CallAlertingEvent] =
    Json.writes[CallAlertingEvent]
  private implicit val wcallTransferedRead: Writes[CallTransferredEvent] =
    Json.writes[CallTransferredEvent]
  private implicit val wcallReleasedRead: Writes[CallReleasedEvent] =
    Json.writes[CallReleasedEvent]
  private implicit val wcallCodecRead: Writes[CallCodecNegotiatedEvent] =
    Json.writes[CallCodecNegotiatedEvent]
  private implicit val wcallTreatmentRead: Writes[CallTreatmentEvent] =
    Json.writes[CallTreatmentEvent]
  private implicit val wcallRetrievedRead: Writes[CallRetrievedEvent] =
    Json.writes[CallRetrievedEvent]
  private implicit val wcallHeldRead: Writes[CallHeldEvent] =
    Json.writes[CallHeldEvent]
  private implicit val wcallForwardedRead: Writes[CallForwardedEvent] =
    Json.writes[CallForwardedEvent]
  private implicit val wcallProcessingRead: Writes[CallProgressingEvent] =
    Json.writes[CallProgressingEvent]
  private implicit val wcallAccountCodeWrite: Writes[CallAccountCode] =
    Json.writes[CallAccountCode]
  implicit val callGroupStatsWrite: Writes[CallGroupCallStats] =
    Json.writes[CallGroupCallStats]
  private implicit val callGroupStatsEventWrite: Writes[CallGroupStatsEvent] =
    Json.writes[CallGroupStatsEvent]

  implicit object CallEventReads extends Reads[Event] {
    val list = List(
      "account_busy",
      "account_idle",
      "account_dnd_on",
      "account_dnd_off",
      "account_available",
      "account_unavailable",
      "account_left_group",
      "account_joined_group",
      "account_signed_out_Acd"
    )

    def reads(json: JsValue): JsResult[Event] = {
      (json \ "event_type").getOrElse(JsString("Not found")) match {
        case JsString("call_originated") =>
          Json.fromJson[CallOriginatedEvent](json)
        case JsString("call_term_attempt") =>
          Json.fromJson[CallTermAttemptEvent](json)
        case JsString("call_alerting") => Json.fromJson[CallAlertingEvent](json)
        case JsString("call_answered") => Json.fromJson[CallAnsweredEvent](json)
        case JsString("call_transferred") =>
          Json.fromJson[CallTransferredEvent](json)
        case JsString("call_released") => Json.fromJson[CallReleasedEvent](json)
        case JsString("call_codec_negotiated") =>
          Json.fromJson[CallCodecNegotiatedEvent](json)
        case JsString("call_treatment") =>
          Json.fromJson[CallTreatmentEvent](json)
        case JsString("call_retrieved") =>
          Json.fromJson[CallRetrievedEvent](json)
        case JsString("call_held") => Json.fromJson[CallHeldEvent](json)
        case JsString("call_forwarded") =>
          Json.fromJson[CallForwardedEvent](json)
        case JsString("call_progressing") =>
          Json.fromJson[CallProgressingEvent](json)
        case JsString("call_account_code") =>
          Json.fromJson[CallAccountCode](json)
        case JsString(userPresenceEvent) if list.contains(userPresenceEvent) =>
          Json.fromJson[UserPresenceEvent](json)
        case JsString("call_group") => Json.fromJson[CallGroupStatsEvent](json)
        case _                      => JsError("Event could not be parsed.")
      }
    }
  }

  def writeMe[T](item: T, et: String)(implicit tjs: Writes[T]): JsObject =
    Json.toJson(item).as[JsObject] + ("event_type" -> Json.toJson(et))

  implicit val CallEventWrites = new Writes[Event] {

    def writes(call: Event): JsValue =
      call match {
        case item: CallOriginatedEvent  => writeMe(item, "call_originated")
        case item: CallTermAttemptEvent => writeMe(item, "call_term_attempt")
        case item: CallAlertingEvent    => writeMe(item, "call_alerting")
        case item: CallAnsweredEvent    => writeMe(item, "call_answered")
        case item: CallTransferredEvent => writeMe(item, "call_transferred")
        case item: CallReleasedEvent    => writeMe(item, "call_released")
        case item: CallCodecNegotiatedEvent =>
          writeMe(item, "call_codec_negotiated")
        case item: CallTreatmentEvent   => writeMe(item, "call_treatment")
        case item: CallRetrievedEvent   => writeMe(item, "call_retrieved")
        case item: CallHeldEvent        => writeMe(item, "call_held")
        case item: CallForwardedEvent   => writeMe(item, "call_forwarded")
        case item: CallProgressingEvent => writeMe(item, "call_progressing")
        case item: CallAccountCode      => writeMe(item, "call_account_code")
        case item: CallGroupStatsEvent  => writeMe(item, "call_group")
        case item: UserPresenceEvent    => Json.toJson(item)

      }
  }

}
