package com.sludg.helpers

object States {

  sealed trait CallStats

  case object IncomingStats extends CallStats

  case object OutgoingStats extends CallStats

  sealed trait SelectionMode

  object SelectionMode {

    case object Inactive extends SelectionMode

    case object SelectComponent extends SelectionMode

  }

  /* States the user can be */
  sealed trait UserType

  object UserType {

    case object SuperUser extends UserType

    case object AdminUser extends UserType

    case object StandardUser extends UserType

    case object UnlinkedUser extends UserType

    case object UndefinedUser extends UserType

  }

  sealed trait AccessPermission

  object AccessPermission {

    case object Edit extends AccessPermission

    case object Lock extends AccessPermission

  }

  sealed trait EditingMode

  object EditingMode {

    case object Fixed extends EditingMode

    case object Editing extends EditingMode

  }

  /**
    * States of table header ordering
    * Can be ordered as ascending, descending or neutral if not ordered
    */

  case class PriorityOrder(order: Ordering, priority: Int)

  sealed trait Ordering

  object Ordering {

    case object Ascending extends Ordering

    case object Descending extends Ordering

    case object Neutral extends Ordering


    val strToObj: Map[String, Ordering] = Map(
      "ascending" -> Ascending,
      "descending" -> Descending,
      "neutral" -> Neutral
    )

  }

}
