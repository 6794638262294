package com.sludg.helpers

import buildinfo.sbtinfo
import com.sludg.Security
import com.sludg.components.VTSLApp
import com.sludg.helpers.AppSetup.{VtslAppProps, VtslAppScopedSlots}
import com.sludg.models.Config.AppLink
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue.RenderHelpers.{div, namedTag}
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents.{vIcon, vList, vListTile, vListTileAction}
import org.log4s.getLogger
import com.sludg.FieldExtractor

import scala.scalajs.js

object DashboardsApp {
  private[this] val logger = getLogger

  def dashboardsAppRenderer(
      registrationName: String
  ): RenderHelpers.NodeRenderer[VtslAppProps, EventBindings, ScopedSlots] =
    namedTag[VtslAppProps, EventBindings, ScopedSlots](registrationName)

  val appRenderer
      : RenderHelpers.NodeRenderer[VueProps, EventBindings, AppSetup.VtslAppScopedSlots] =
    VTSLApp.appRenderer("VtslApp")

  def dashboardsAppComponent(
      apiCalls: ApiCalls,
      security: Security,
      apiCallBus: Vue,
      otherApps: List[AppLink]
  ): VueComponent[_ <: VtslAppProps, _ <: Slots, _ <: VtslAppScopedSlots] =
    VueComponent.builder
      .withScopedSlots[VtslAppScopedSlots]
      .withPropsAs[VtslAppProps]
      .build(
        components = js.defined(
          js.Dynamic.literal(
            "VtslApp" -> VTSLApp.createApp(
              "Dashboards",
              apiCalls,
              apiCallBus,
              otherApps,
              Some(sbtinfo.version),
              None,
              _ => (security.logout()),
              showLogo = true,
              showFooter = true
            )
          )
        ),
        updated = js.defined(c => logger.debug("App updated")),
        templateOrRender = Right((component, renderer) =>
          div(
            appRenderer(
              RenderOptions(
                props = Some(
                  new VtslAppProps(
                    title = component.title,
                    spacedToolbar = component.spacedToolbar,
                    vContentIsFluid = true,
                    temporaryDrawer = true,
                    token = component.token,
                    displayTenantSelector = true
                  )
                ),
                scopedSlots = Some(new VtslAppScopedSlots {
                  override val toolbar: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    component.$scopedSlots.toolbar
                  override val toolbarExtension: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    component.$scopedSlots.toolbarExtension
                  override val drawer: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    js.defined(tenant => {
                      vList(
                        vListTile(
                          vListTileAction(vIcon("dashboard")),
                          vListTileAction("Dashboards")
                        )
                      ).render(renderer)
                    })
                  override val default: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    component.$scopedSlots.default
                }),
                `class` = List(Left("main-style")),
                style = Some(js.Dynamic.literal("background-color" -> "#6F90A7"))
              )
            )
          ).render(renderer)
        )
      )
}
