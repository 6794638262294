package com.sludg.pages.mainpage.grid

import com.sludg.auth0.SludgToken
import com.sludg.pages.mainpage.config.DashboardConfig.{
  ComponentConfiguration,
  UserStatsComponentConfig,
  getComponentInitialSize
}
import com.sludg.helpers.Helper.{GridType, _}
import com.sludg.helpers.States.SelectionMode.{Inactive, SelectComponent}
import com.sludg.services.ApiCalls
import com.sludg.util.models.DashboardComponentModels.{ComponentType, DashboardComponent}
import com.sludg.util.models.SilhouetteModels.CallGroupType.BroadcastGroup
import play.api.libs.json.{JsValue, Json, Reads}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import com.sludg.vue.RenderHelpers.{div, namedTag, p, td, tr, _}
import com.sludg.vue.{RenderHelpers, _}

import scala.scalajs.js
import scala.scalajs.js.|
import Grid._
import com.sludg.pages.mainpage.grid.Grid.GridComponentEvents.UpdateComponents
import com.sludg.helpers.States.UserType.SuperUser
import com.sludg.util.models.DashboardComponentModels.ComponentType._
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vuetify.components.grid.VFlexProps
import com.sludg.json.JsonDeserialiser._
import com.sludg.util.models.ReportModels.Filter.RelativeDateFilter

import scala.scalajs.js.JSConverters._
import monix.execution.Scheduler.Implicits.global

object ComponentSelector {
  //TODO Chance how call group selection is done
  /* the call group selection is done in this file which is inconsistent with how for all other configuration */

  def addComponentDialog(g: GridType, apiCalls: ApiCalls, loader: Vue)(implicit
      token: SludgToken
  ): RenderHelpers.NodeRenderer[VDialogProps, EventBindings, ScopedSlots] = {
    vDialog(
      RenderOptions(
        style = Some(js.Dynamic.literal("background-color" -> "aliceblue")),
        `class` = List(Left("center-text")),
        props = Some(
          VDialogProps(
            value = Some(g.selectionMode != Inactive),
            width = Some(Right(200)),
            scrollable = Some(false),
            `max-width` = Some(Right(700))
          )
        ),
        on = Some(EventBindings(input = js.defined(e => {
          g.selectionMode = Inactive
        })))
      ),
      vCard(
        RenderOptions(
          style = Some(
            js.Dynamic.literal(
              "height" -> (if (g.userType == SuperUser && isViewingOwnTenant(g))
                             "530px"
                           else "460px"),
              "width" -> "100%",
              "background-color" -> "aliceblue"
            )
          )
        ),
        vLayout(
          g.selectionMode match {
            case SelectComponent => {
              val selectedTid = g.addingComponentSelectedTenant
                .map(_.id)
                .getOrElse(g.tenantSuperUserIsViewing.map(_.id).getOrElse(g.tenant.id))
              selectComponentScreen(g, selectedTid)
            }
            case Inactive => nothing
            case _ => nothing
          }
        ),
        /**
          * If you are a SuperUser, you can create dashboards spanning multiple tenants
          */
        if (g.userType == SuperUser && isViewingOwnTenant(g)) superUserTenantSelection(g, apiCalls)
        else div()
      )
    )
  }

  def selectComponentScreen(g: GridType, tenantId: Int): VComponent = {
    div(
      vLayout(
        RenderOptions(style =
          Some(js.Dynamic.literal("padding-top" -> "110px", "padding-bottom" -> "40px"))
        ),
        vFlex(
          vListTile(
            buildSelection(
              tenantId,
              UserPresence,
              "UserPresencePreview",
              "User Presence",
              ComponentConfiguration(tenantId),
              g
            ),
            buildSelection(
              tenantId,
              IncomingStats,
              "IncomingStatsPreview",
              "Incoming call stats",
              ComponentConfiguration(tenantId, filter = List(RelativeDateFilter(0))),
              g
            ),
            buildSelection(
              tenantId,
              OutgoingStats,
              "OutgoingStatsPreview",
              "Outgoing call stats",
              ComponentConfiguration(tenantId, filter = List(RelativeDateFilter(0))),
              g
            )
          )
        )
      ),
      vLayout(
        RenderOptions(style =
          Some(js.Dynamic.literal("padding-top" -> "110px", "padding-bottom" -> "40px"))
        ),
        vFlex(
          vListTile(
            buildSelection(
              tenantId,
              UserStats,
              "UserStatsPreview",
              "User Stats",
              ComponentConfiguration(
                tenantId,
                List(RelativeDateFilter(0)),
                componentDesign = Some(
                  Json
                    .toJson(UserStatsComponentConfig(List.fill(3)(true) ++ List.fill(9)(false)))
                    .toString()
                )
              ),
              g
            ),
            buildSelection(
              tenantId,
              CallGroup,
              "CallGroupPreview",
              "Call Groups",
              ComponentConfiguration(tenantId),
              g
            ),
            vFlex(
              RenderOptions(style =
                Some(js.Dynamic.literal("width" -> "225px", "height" -> "140px"))
              )
            )
          )
        )
      )
    )
  }

  def buildSelection(
      tenantId: Int,
      componentType: ComponentType,
      imageName: String,
      componentName: String,
      componentConfiguration: ComponentConfiguration,
      g: GridType,
      disableButton: Boolean = false
  ): NodeRenderer[VFlexProps, EventBindings, ScopedSlots] = {
    vFlex(
      vButton(
        vImage(
          RenderOptions(
            props = Some(
              VImageProps(
                src = Left(s"/images/components/${imageName}.png"),
                width = Some(Left(175))
              )
            )
          )
        ),
        RenderOptions(
          `class` = List(Left("growme")),
          style = Some(js.Dynamic.literal("width" -> "85%", "height" -> "140px")),
          props = Some(VButtonProps(flat = Some(true), disabled = Some(disableButton))),
          on = Some(
            EventBindings(
              click = js.defined(e => {
//                if (componentType == ComponentType.CallGroup) {
//                  g.selectionMode = SelectCallGroup
//                } else {
                g.selectedDashboard match {
                  case Some(x) =>
                    fireUpdateComponentEvent(
                      g,
                      g.dashboardComponents :+ buildWidgetAndValidatePosition(
                        tenantId,
                        getComponentInitialSize(componentType),
                        g,
                        x.id,
                        componentConfiguration,
                        g.selectedXPosition,
                        g.selectedYPosition
                      )
                    )
                    g.selectionMode = Inactive

                  case None => logger.debug("No dashboard selected")
                }
//                }
              })
            )
          )
        )
      ),
      p(componentName, RenderOptions(`class` = List(Left("center-text2"))))
    )
  }

  def generateCell(
      cellTitle: String,
      disabled: Boolean = false,
      Event: Option[EventBindings] = None
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    td(
      vButton(
        cellTitle,
        RenderOptions(
          `class` = List(Left("style_8")),
          props = Some(VButtonProps(disabled = Some(disabled), flat = Some(true))),
          on = Event
        )
      )
    )
  }

  def superUserTenantSelection(g: GridType, apiCalls: ApiCalls)(implicit
      sludgToken: SludgToken
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    div(
      RenderOptions(style = Some(js.Dynamic.literal("padding-top" -> "25px"))),
      vFlex(
        small(
          "As a super user, you can create super-user level dashboards with components spanning different tenants.",
          RenderOptions(style =
            Some(
              js.Dynamic
                .literal("width" -> "100%", "padding-left" -> "40px", "padding-top" -> "20px")
            )
          )
        )
      ),
      //      vFlex(small("Note: Dashboards spanning multiple tenants are private and are ony viewable by other super users.", RenderOptions(style = Some(js.Dynamic.literal("width" -> "100%", "padding-left" -> "40px"))))),
      vAutocomplete(
        RenderOptions[VAutocompleteProps[
          Tenant
        ], VAutocompleteEventBindings, VAutocompleteScopedSlots[Tenant]](
          style = Some(
            js.Dynamic.literal(
              "width" -> "100%",
              "padding-left" -> "40px",
              "padding-right" -> "40px",
              "padding-top" -> "10px"
            )
          ),
          props = Some(
            js.Dynamic
              .literal(
                "label" -> "Select component tenant",
                "items" -> g.tenants.toJSArray,
                "item-text" -> ((itemText => itemText.asInstanceOf[Tenant].name): js.Function1[
                  js.Array[Tenant] | Tenant,
                  String
                ]),
                "value" -> {
                  val myTenant: Option[Tenant] = g.tenants.collectFirst {
                    case i if i.id == g.tenant.id => i
                  }

                  /**
                    * Pre-selects the tenant selected by the super-user, if super-user has not selected one then
                    * Select the addingComponentSelectedTenant/tenant instead, otherwise
                    * as a fallback, preselect with the first item in the list
                    */
                  (
                    g.tenantSuperUserIsViewing,
                    g.addingComponentSelectedTenant,
                    myTenant,
                    g.tenants.headOption
                  ) match {
                    case (Some(tenant_1), _, _, _) => tenant_1.asInstanceOf[js.Any]
                    case (None, Some(tenant_2), _, _) => tenant_2.asInstanceOf[js.Any]
                    case (_, _, Some(tenant_3), _) => tenant_3.asInstanceOf[js.Any]
                    case (_, _, _, Some(tenant_4)) => tenant_4.asInstanceOf[js.Any]
                    case _ => js.undefined
                  }

                },
                "return-object" -> true,
                "autocomplete" -> true,
                "placeholder" -> "Select tenant"
              )
              .asInstanceOf[VAutocompleteProps[Tenant]]
          ),
          on = Some(
            VAutocompleteEventBindings(
              input = js.defined(e => {
                g.addingComponentSelectedTenant = Some(e.asInstanceOf[Tenant])

                g.loaded = true
                apiCalls.getCallGroups(e.asInstanceOf[Tenant].id).map {
                  case Left(x) => x
                  case Right(x) => {
                    g.callGroups = x
                  }
                }
              })
            )
          )
        )
      )
    )
  }

}
