package com.sludg

import com.sludg.models.Model.DashboardConfig
import com.sludg.auth0.SludgToken
import com.sludg.helpers.PageManager
import com.sludg.models.Config.AppLink
import com.sludg.services.ApiCalls
import fr.hmil.roshttp.HttpRequest
import org.log4s.getLogger
import play.api.libs.json.Json

import scala.util.{Failure, Success}
import monix.execution.Scheduler.Implicits.global
import com.sludg.json.Deserializer._
import org.scalajs.dom.ext.LocalStorage
import com.sludg.pages.mainpage.config.DashboardConfig.lastAccessedUrlStoreKey

object Entry extends App {

  private[this] val logger = getLogger

  val originUrl = org.scalajs.dom.window.location.origin

  def getConfig = HttpRequest(s"$originUrl/config").send().map { r =>
    Json.parse(r.body).validate[DashboardConfig].asOpt
  }

  (for (configOpt <- getConfig) yield configOpt match {
    case Some(config) =>
      // Setup log level
      helpers.Entry.initLogging(config.logLevel)

      (for {
        maybeSecurity <- Security.initialise(
          Auth0Config(
            config.auth0.domain,
            config.auth0.clientID,
            config.auth0.audience,
            originUrl.get
          )
        )
      } yield {
        maybeSecurity match {
          case Left(Security.NotLoggedIn(redirectToLogin)) =>
            logger.info("Saving accessed url.")
            LocalStorage.update(lastAccessedUrlStoreKey, org.scalajs.dom.window.location.href)

            logger.info("User not logged in. Redirecting.")
            redirectToLogin.run()
          case Right(sec) =>
            logger.info("Security session initialised. Bootings...")

            sec.checkSession().onComplete {
              case Success(tokenJs) =>
                LocalStorage(lastAccessedUrlStoreKey) match {
                  case Some(url) =>
                    LocalStorage.remove(lastAccessedUrlStoreKey)
                    org.scalajs.dom.window.location.href = url;
                  case None => logger.debug("No last accessed url found")
                }
                PageManager.initialise(
                  sec,
                  config,
                  config.otherApps.map(_.as[AppLink]),
                  new ApiCalls(config.apiConfig),
                  "main",
                  new SludgToken(sec.token)
                )
              case Failure(error) =>
                logger.debug("Could not refresh the token")
                error.printStackTrace()
                logger.debug("Logging out.")
                sec.logout()
            }
        }
      }).onComplete {
        case Success(_) => logger.debug("Running application")
        case Failure(e) =>
          logger.error("failed to start the application")
          e.printStackTrace()
          logger.debug(e.getMessage)
      }

    case None => logger.error("Config not supplied or could not be parsed")

  }).onComplete {
    case Success(_) => logger.debug("running")
    case Failure(e) =>
      logger.error("failed to start the application")
      e.printStackTrace()
      println(e.getMessage)
  }
}
