package com.sludg.services

import java.util.TimerTask

import com.sludg.auth0.SludgToken
import com.sludg.models.Model.DashboardConfig
import org.log4s.getLogger
import org.scalajs.dom
import com.sludg.helpers.Helper.{GridType, PageOneType}
import com.sludg.util.models.Events.{CallGroupStatsEvent, Event, UserPresenceEvent}
import play.api.libs.json.{Json, _}
import com.sludg.services.Websockets._

import scala.language.postfixOps
import com.sludg.util.json.EventsDeserializer._
import com.sludg.util.models.DashboardComponentModels.ComponentType.{CallGroup, UserPresence}
import com.sludg.util.models.DashboardComponentModels.{ComponentType, DashboardComponent}
import org.scalajs.dom.WebSocket

import scala.concurrent.ExecutionContext
import scala.concurrent.duration._

import cats.effect._
import cats.data.NonEmptyList
import cats.implicits._

import retry._

object Websocket {

  def extractEventTypes(dc: List[ComponentType]): List[EventType] =
    List(
      if (dc.contains(UserPresence) || dc.contains(CallGroup)) Some(EventType.Presence) else None,
      if (dc.contains(CallGroup)) Some(EventType.CallGroup) else None
    ).flatten

  def extractTenantIds(dashboardComponents: List[DashboardComponent]): List[Int] = {
    val dc = dashboardComponents.filterNot(_.eventSubscription.contains("[stub]"))
    if (dc.nonEmpty) dc.map(_.subscribedTenantId) else Nil
  }

  def makeConnection(
      config: DashboardConfig,
      tenantIds: List[Int],
      eventTypes: List[EventType],
      p: PageOneType
  )(implicit token: SludgToken, ec: ExecutionContext): Unit = {

    val logger = getLogger

    for {
      tenans <- NonEmptyList.fromList(tenantIds)
      eventTypes <- NonEmptyList.fromList(eventTypes)
    } yield {

      val socket = new Websockets(config.apiConfig).general(tenans, eventTypes)

      def hek: fs2.Stream[IO, Unit] = socket.evalMap {
        case WebsocketMessage.Closed => IO.raiseError(new Exception("Boom"))
        case WebsocketMessage.Open => IO.unit
        case WebsocketMessage.Error(e) => IO.raiseError(new Exception(e))
        case WebsocketMessage.Message(a: UserPresenceEvent) => IO { p.userPresenceEvent = a }
        case WebsocketMessage.Message(a: CallGroupStatsEvent) => IO { p.callGroupStatsEvent = a }
        case WebsocketMessage.Message(_) => IO.unit
      }

      implicit val ioTimer = IO.timer(ec)
      implicit val sleep = retry.Sleep.sleepUsingTimer[IO]

      p.socketCloser = Some(
        retryingOnAllErrors(
          RetryPolicies.capDelay(1.minute, RetryPolicies.exponentialBackoff[IO](1.second)),
          (e: Throwable, r) => IO { logger.error(e)(s"Failed (${e.getMessage()}): $r") }
        )(hek.compile.drain)
          .unsafeRunCancelable {
            case Left(e) => logger.error(e)("An error occured when running the socket")
            case Right(_) => ()
          }
      )

    }

  }

  def closeConnection(p: PageOneType): Unit = {
    p.socketCloser match {
      case Some(x) => x.unsafeRunAsyncAndForget()
      case None => {
        println("No connection exists!")
      }
    }
  }
}
