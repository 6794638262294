package com.sludg.pages.mainpage.grid

import com.sludg.FieldExtractor
import java.util.TimerTask

import com.sludg.auth0.SludgToken
import com.sludg.models.Model.DashboardConfig
import com.sludg.pages.mainpage.config.DashboardConfig._
import com.sludg.helpers.Helper._
import com.sludg.helpers.States.SelectionMode.Inactive
import com.sludg.helpers.States.{EditingMode, SelectionMode, UserType}
import com.sludg.services.ApiCalls
import com.sludg.util.models.DashboardComponentModels.{ComponentType, DashboardComponent}
import com.sludg.util.models.DashboardModels.Dashboard
import com.sludg.util.models.Events.{CallGroupStatsEvent, UserPresenceEvent}
import com.sludg.util.models.SilhouetteModels.{CallGroup, Subscriber, Tenant}
import com.sludg.vue.RenderHelpers._
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents.vueGridLayout
import com.sludg.vuetify.components.gridSystem.{VueGridItemProps, VueGridLayoutProps}
import org.log4s.getLogger
import org.scalajs.dom.Event

import scala.collection.immutable.List
import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import ComponentSelector._
import com.sludg.pages.mainpage.grid.Grid.GridComponentEvents.UpdateComponents
import com.sludg.models.ComponentFacade.DashboardJSComponentData
import com.sludg.helpers.States.UserType.SuperUser
import com.sludg.FieldExtractor
import com.sludg.pages.mainpage.grid.cells.EmptyCell.buildEmptyCell
import com.sludg.pages.mainpage.grid.cells.widget_1.components
import com.sludg.pages.mainpage.grid.cells.widget_1.components.CallGroup.CallGroupProps
import com.sludg.pages.mainpage.grid.cells.widget_1.components.InOutCallStats.CallStatsProps
import com.sludg.pages.mainpage.grid.cells.widget_1.components.UserPresence.UserPresenceProps
import com.sludg.pages.mainpage.grid.cells.widget_1.components.UserStats.UserStatsProps
import com.sludg.pages.mainpage.grid.cells.widget_1.components.{
  InOutCallStats,
  UserPresence,
  UserStats
}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.helpers.GridLayout
import com.sludg.helpers.GridItem
import play.api.libs.json.Json
import com.sludg.util.models.ReportModels
import com.sludg.util.json.FiltersJsonDeserializers._

object Grid {

  val logger = getLogger

  def GridComponentRenderer(
      registrationName: String
  ): RenderHelpers.NodeRenderer[GridComponentProps, GridComponentEvents, ScopedSlots] =
    namedTag[GridComponentProps, GridComponentEvents, ScopedSlots]("Grid")

  val userPresence: RenderHelpers.NodeRenderer[UserPresenceProps, EventBindings, ScopedSlots] =
    UserPresence.userPresenceRenderer("UserPresence")
  val callStats: RenderHelpers.NodeRenderer[CallStatsProps, EventBindings, ScopedSlots] =
    InOutCallStats.callStatsRenderer("CallStats")
  val callGroup: RenderHelpers.NodeRenderer[CallGroupProps, EventBindings, ScopedSlots] =
    components.CallGroup.callGroupRenderer("CallGroup")
  val userStats: RenderHelpers.NodeRenderer[UserStatsProps, EventBindings, ScopedSlots] =
    UserStats.userStatsRenderer("UserStats")

  def GridComponentComponent(
      apiCalls: ApiCalls,
      loader: Vue,
      config: DashboardConfig,
      token: SludgToken
  ): VueComponent[_ <: GridComponentProps, _ <: Slots, _ <: ScopedSlots] = {
    VueComponent.builder
      .withData(new GridComponentData)
      .withPropsAs[GridComponentProps]
      .withComputed(GridComponentComputed())
      .build(
        created = js.defined(c => {
          import monix.execution.Scheduler.Implicits.global
          updateData(loader, apiCalls, c)
          apiCalls
            .getAllTenants()(implicitly, c.token)
            .map({
              case Left(value) =>
              case Right(value) => c.tenants = value
            })
        }),
        watch = new GridWatcher(),
        components = js.Dynamic.literal(
          "UserPresence" -> UserPresence.userPresenceComponent(loader, apiCalls),
          "CallStats" -> InOutCallStats.statsComponent(loader, apiCalls),
          "CallGroup" -> components.CallGroup.callGroupComponent(loader, apiCalls),
          "UserStats" -> UserStats.userStatsComponent(loader, apiCalls),
          "grid-layout" -> GridLayout,
          "grid-item" -> GridItem
        ),
        templateOrRender = Right((grid, renderer) => {
          div(
            RenderOptions(
              style = Some(
                js.Dynamic.literal(
                  "background-color" -> "rgb(33, 91, 125);",
                  "height" -> s"${gridHeight * gridRowHeight}px",
                  "width" -> "100%"
                )
              )
            ),
            grid.$root.$vuetify.breakpoint.name.toString match {
              case "sm" | "xs" =>
                div(
                  p(
                    RenderOptions(style = Some(js.Dynamic.literal("font-size" -> "30px"))),
                    "Please use a larger screen to administer dashboards."
                  )
                )
              case _ => {
                div(
                  vueGridLayout(
                    RenderOptions(
                      props = Some(
                        VueGridLayoutProps(
                          maxRows = Some(maxHeight),
                          verticalCompact =
                            Some(false), // Keep this false otherwise dashboard will freeze //
                          responsive = Some(true),
                          useCssTransforms =
                            Some(true), // Setting this to false will cause custom css to break //
                          autoSize = Some(false),
                          isMirrored = Some(false),
                          isResizable = Some(isEditing(grid.editMode)),
                          isDraggable = Some(isEditing(grid.editMode)),
                          rowHeight = Some(gridRowHeight),
                          // This prop of positions and heights MUST be in sync with the components that you have added
                          layout = Some(grid.fullLayout)
                            .map(
                              _.map(_.asInstanceOf[js.Object]).toJSArray
                            ), //todo this is kinda odd
                          preventCollision = Some(false),
                          margin = Some(Array(4, 4).toJSArray)
                        )
                      )
                    ),
                    /* Adding all of the components which sync to the grid */
                    addComponentCells(apiCalls, grid, renderer),
                    /* Adding all of the empty cells which DO NOT sync to the grid */
                    addEmptyCells(grid.emptyCells, apiCalls, grid.token, grid)
                  )
                )
              }
            },
            /* Adding the selection menu */
            addComponentDialog(grid, apiCalls, loader)(grid.token)
          ).render(renderer)
        })
      )
  }

  //Adding empty cells
  def addEmptyCells(
      emptyCells: js.Array[DashboardJSComponentData],
      apiCalls: ApiCalls,
      token: SludgToken,
      g: GridType
  ): js.Array[RenderFunction[VNode]] = {
    emptyCells.map(componentData => buildEmptyCell(componentData, apiCalls, token, g))
  }

  /**
    * Adding the dashboard component onto the grid system
    * Any dashboard components marked as [stub] are ignored, as these are previously deleted components
    * from the grid system
    * //TODO Try replace [stub] components with None
    */
  import com.sludg.pages.mainpage.grid.cells.ComponentCell.buildComponentCell

  //Adding dashboard components
  def addComponentCells(apiCalls: ApiCalls, g: GridType, renderer: CreateElement): Seq[
    RenderHelpers.NodeRenderer[_ >: VueGridItemProps <: VueProps, EventBindings, ScopedSlots]
  ] = {
    gridToComponentType(g).map(component =>
      if (!component.eventSubscription.contains("[stub]"))
        buildComponentCell(component, g, renderer)
      else div()
    )
  }

  /**
    * Removes a dashboard component by filtering the component by its position from the list of components.
    * The empty cells are then reloaded
    */
  def deleteDashboardComponent(grid: GridType, a: DashboardComponent): Option[EventBindings] = {
    Some(EventBindings(click = js.defined(e => {
      grid.deletionDialogVisible = false

      val componentRemoved = grid.dashboardComponents.map(a =>
        if (a.x == grid.selectedXPosition && a.y == grid.selectedYPosition) {
          a.copy(height = 0, width = 0, x = 0, y = 0, eventSubscription = Some("[stub]"))
        } else {
          a
        }
      )

      fireUpdateComponentEvent(grid, componentRemoved)
    })))
  }

  /**
    * Sends an updated list of components to PageOne
    * Contains a flag to indicate weather or now this should be persisted imediated
    */
  def fireUpdateComponentEvent(
      grid: GridType,
      data: Iterable[DashboardComponent],
      save: Boolean = false
  ): Unit = {
    grid.$emit("updateComponents", UpdateComponents(data.toList, save))
  }

  /**
    * Dashboard updates every 5 minutes by default
    * Some components may update themselves at a different rate
    */
  def updateData(loader: Vue, apiCalls: ApiCalls, c: GridType): Unit = {
    val task: TimerTask = new java.util.TimerTask {
      def run(): Unit = {
        logger.debug("Firing.")
        c.refresh = !c.refresh
      }
    }
    val t = new java.util.Timer()
    t.schedule(task, 30000L, 30000L)
  }

  class GridWatcher() extends js.Object {

    // // When switching between dashboards, everything is reloaded */
    // def dashboardComponents(value: js.Any): Unit = {
    //   val grid = this.asInstanceOf[GridType]

    //   val newComponentList = value.asInstanceOf[js.Array[DashboardComponent]].toList

    //   // recalculates positions of empty cells */
    //   reloadEmptyCells(grid, Some(newComponentList))

    //   // recalculating the position of components */
    //   grid.componentCells = componentTypeToGrid(newComponentList).toJSArray
    // }

    //def componentCells(value: js.Any): Unit = {
    //  val grid = this.asInstanceOf[GridType]
    //  //updateDataConnection(grid, grid.tenant.id, c)

    //  // recalculates positions of empty cells */
    //  reloadEmptyCells(grid, Some(gridToComponentType(grid)))

    //  // updates grid positions */
    //  fireUpdateComponentEvent(grid)
    //}

    // def reloadAdders(value: js.Any): Unit = {
    //   logger.info("Reloading empty cells! ")
    //   val grid = this.asInstanceOf[GridType]
    //   reloadEmptyCells(grid, None)
    // }

    /**
      * Watching the Tenant Selector prop for changes
      * If it changes, an event is emit to the parent
      * The parent then updates the dashboard
      *
      * @param newTenant
      * @param oldValue
      */
    def tenantSuperUserIsViewing(newTenant: Option[Tenant], oldValue: Option[Tenant]): Unit = {
      if (newTenant != oldValue) {
        logger.info("New Tenant Selected! " + newTenant.toString())
        this.asInstanceOf[GridType].$emit("updateSuperUserSelectedTenant", newTenant)
      }
    }
  }

  /**
    * Checks if a user is a super user
    * Returns a boolean
    *
    * @return
    */
  def isSuperUser(): Boolean = this.asInstanceOf[GridType].userType == SuperUser

  def calculateEmptyCells(
      components: Iterable[DashboardComponent]
  ): js.Array[DashboardJSComponentData] = {

    def generateOccupiedPositions(width: Int, height: Int, x: Int, y: Int): List[(Int, Int)] = {
      def generateRange(num: Int, displacement: Int): List[Int] =
        List.range(0, num).map(num => num + displacement)

      for (x <- generateRange(width, x); y <- generateRange(height, y)) yield (y, x)
    }

    val occupiedPositions = components
      .filterNot(_.eventSubscription.contains("[stub]"))
      .flatMap(widget => generateOccupiedPositions(widget.width, widget.height, widget.x, widget.y))

    val stubLocations: List[DashboardComponent] = entireGridLocations
      .filterNot(occupiedPositions.toSet)
      .map(a =>
        DashboardComponent(
          0,
          a._2,
          a._1,
          1,
          1,
          1,
          None,
          List(),
          None,
          None,
          ComponentType.UserPresence,
          0
        )
      )

    val stubComponentsList: List[DashboardJSComponentData] =
      componentTypeToGrid(stubLocations)

    stubComponentsList.toJSArray

  }

  import scala.scalajs.js.JSConverters._

  /*
   * Converts a list of DashboardJSComponentData to a list of DashboardComponents
   */
  def componentTypeToGrid(
      componentRemoved: Iterable[DashboardComponent]
  ): List[DashboardJSComponentData] = {
    componentRemoved
      .map(a =>
        DashboardJSComponentData(
          a.id,
          a.x,
          a.y,
          a.width,
          a.height,
          a.subscribedTenantId,
          a.title,
          a.filters.map { f =>
            val res = Json.toJson(f).toString()
            res
          }.toJSArray,
          a.componentType,
          a.componentConfig,
          a.eventSubscription
        )
      )
      .toList
  }

  /*
   * Converts a list of DashboardComponents to a list of DashboardJSComponentData
   */

  def gridToComponentType(grid: GridType): List[DashboardComponent] = {
    grid.componentCells.toList.map(item =>
      DashboardComponent(
        item.i,
        item.x,
        item.y,
        item.h,
        item.w,
        item.sTid,
        item.title.toOption,
        item.filters.map { f =>
          Json.parse(f).as[ReportModels.Filter]
        }.toList,
        item.componentConfig.toOption,
        item.eventSubscription.toOption,
        ComponentType.stringToObjection(item.componentType),
        grid.selectedDashboard.map(_.id).getOrElse(0)
      )
    ): List[DashboardComponent]
  }

  /**
    * Due to the nature of being able to place dashboard component of varying size, we need to check if a component
    * with a certain size can fit into the location selected on the grid. If the component is out of bounds on the x axis,
    * we set it to a location where it can fit (by moving it to the left).
    * If a component is out of bounds on the Y axis, we move it up such that it can fit. This prevents a user from added
    * a 4x4 sized component in the far corner of the grid system
    */
  def buildWidgetAndValidatePosition(
      tenantId: Int,
      component: ComponentConfig,
      grid: GridType,
      id: Int,
      componentConfiguration: ComponentConfiguration,
      proposedXPosition: Int,
      proposedYPosition: Int
  ): DashboardComponent = {

    val componentXPosition =
      if ((gridWidth - proposedXPosition) < component.startWidth) gridWidth - component.startWidth
      else proposedXPosition
    val componentYPosition =
      if ((gridHeight - proposedYPosition) < component.startHeight)
        gridHeight - component.startHeight
      else proposedYPosition

    grid.selectedXPosition = componentXPosition
    DashboardComponent(
      scala.util.Random.nextInt(10000000),
      componentXPosition,
      componentYPosition,
      component.startHeight,
      component.startWidth,
      tenantId,
      None,
      componentConfiguration.filter,
      componentConfiguration.componentDesign,
      componentConfiguration.eventSubscription,
      component.componentType,
      id
    )
  }

  def isViewingOwnTenant(g: GridType) = {
    (g.tenantSuperUserIsViewing.isEmpty || g.tenantSuperUserIsViewing
      .map(_.id)
      .contains(g.tenant.id))
  }

  class GridComponentData extends js.Object {

    // Current state of the dashboard selection screen */
    var selectionMode: SelectionMode = Inactive
    var deletionDialogVisible = false

    // Configuration Dropdowns
    var callGroups: List[CallGroup] = List()
    var subscribers: List[Subscriber] = List()

    //Super user selected tenant for component adding screen
    var addingComponentSelectedTenant: Option[Tenant] = None
    var tenants: List[Tenant] = List()

    var selectedCallGroupsWithIds: Map[Int, CallGroup] = Map()

    // Configuration selections
    var selectedCallGroup: Option[CallGroup] = None
    var selectedSubscriber: Subscriber = Subscriber(0, "", 0, Some(""), "", "", false)
//    var selectedCallGroup: Option[CallGroup] = None

    // The position in the grid of your selected Cell
    var selectedXPosition, selectedYPosition = 0

    // Events
    var refresh: Boolean = false

    var fireEventOnlyOnceGuard = false

    // other */
    var loaded = false

  }

  class GridComponentProps(
      val subscriberId: Int,
      val subscriberSuperUserIsViewing: Option[Subscriber],
      val reloadAdders: Boolean,
      val dashboardComponents: js.Array[DashboardComponent],
      val selectedDashboard: Option[Dashboard],
      val editMode: EditingMode,
      val tenant: Tenant,
      val tenantSuperUserIsViewing: Option[Tenant],
      val userType: UserType,
      val userPresenceEvent: UserPresenceEvent,
      val callGroupStatsEvent: CallGroupStatsEvent,
      val reset: Boolean,
      val token: SludgToken
  ) extends VueProps

  trait GridComponentEvents extends EventBindings {}

  object GridComponentEvents {

    case class UpdateComponents(
        dashboardComponents: List[DashboardComponent],
        saveImmediately: Boolean
    )

    /* Events to update components */
    def apply(
        bindings: EventBindings = EventBindings(),
        updateComponents: VueEventUndefined[UpdateComponents] = (),
        updateSuperUserSelectedTenant: VueEventUndefined[Option[Tenant]] = ()
    ): GridComponentEvents = {
      eventProcessor(
        (updateComponents, "updateComponents"),
        (updateSuperUserSelectedTenant, "updateSuperUserSelectedTenant")
      )(bindings)
      bindings.asInstanceOf[GridComponentEvents]
    }
  }

  trait GridComponentComputed extends js.Object {
    def fullLayout: js.Array[DashboardJSComponentData]
    def emptyCells: js.Array[DashboardJSComponentData]
    def componentCells: js.Array[DashboardJSComponentData]
  }

  object GridComponentComputed {

    // Grid computed function
    private type GCF[A] = js.ThisFunction0[GridType, A]

    def fullLayout: GCF[js.Array[DashboardJSComponentData]] = (grid) => {
      grid.emptyCells.concat(grid.componentCells)
    }

    def emptyCells: GCF[js.Array[DashboardJSComponentData]] = (grid) => {
      calculateEmptyCells(grid.dashboardComponents)
    }

    def componentCells: GCF[js.Array[DashboardJSComponentData]] = (grid) => {
      componentTypeToGrid(grid.dashboardComponents).toJSArray
    }

    def apply(): GridComponentComputed = {
      js.Dynamic
        .literal(
          "fullLayout" -> fullLayout,
          "emptyCells" -> emptyCells,
          "componentCells" -> componentCells
        )
        .asInstanceOf[GridComponentComputed]
    }
  }

}
