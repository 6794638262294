package com.sludg.util.models

import cats.Eq
import cats.Show

/**
  * @author dpoliakas
  *         Date: 2019-11-07
  *         Time: 13:02
  */
object CallGroupCounterModels {

  case class CallGroupStatsRequest(
      tenantUrl: String,
      callGroupExtension: String
  )

  object CallGroupStatsRequest {
    implicit val eq = Eq.fromUniversalEquals[CallGroupStatsRequest]
    implicit val show = Show.fromToString[CallGroupStatsRequest]
  }

  sealed trait CallGroupStatsResponse

  object CallGroupStatsResponse {
    implicit val eq = Eq.fromUniversalEquals[CallGroupStatsResponse]
    implicit val show = Show.fromToString[CallGroupStatsResponse]
  }

  case class CallGroupCallStats(ringing: Int, waiting: Int, talking: Int)
      extends CallGroupStatsResponse

  object CallGroupCallStats {
    implicit val eq = Eq.fromUniversalEquals[CallGroupCallStats]
    implicit val show = Show.fromToString[CallGroupCallStats]
  }

  case class CallGroupStatsErrorResponse(
      errorReason: String,
      request: CallGroupStatsRequest
  ) extends CallGroupStatsResponse

  object CallGroupStatsErrorResponse {
    implicit val eq = Eq.fromUniversalEquals[CallGroupStatsErrorResponse]
    implicit val show = Show.fromToString[CallGroupStatsErrorResponse]
  }

}
