package com.sludg.helpers

import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.models.Config.AppLink
import com.sludg.models.Model.DashboardConfig
import com.sludg.pages.PageOne
import com.sludg.services.ApiCalls
import com.sludg.vue._
import com.sludg.vuetify.{Vuetify, VuetifyOptions}
import org.log4s.getLogger

import scala.scalajs.js

object PageManager {

  private[this] val logger = getLogger

  private val vuetifyAssets = com.sludg.vuetify.VuetifyAssets

  def initialise(
      security: Security,
      config: DashboardConfig,
      otherApps: List[AppLink],
      apiCalls: ApiCalls,
      appContainer: String,
      sludgToken: SludgToken
  ): Vue = {
    logger.debug("Client processes. We don't care about security >:)")

    Vue.use(
      Vuetify,
      new VuetifyOptions {
        override val theme = js.defined(config.vuetify.toVuetifyColors)
      }
    )
    Vue.use(VueRouter)
    Vue.use(VueSortable)
    Vue.use(VueGridLayout)

    val apiCallBus = new Vue(js.undefined)
    val pageOne =
      PageOne.pageOneComponent(config, apiCalls, security, apiCallBus, otherApps, sludgToken)

    val routes = List(
      VueRoute("/:dashboardId?", name = Some("Dashboards"), component = Some(pageOne)),
      VueRoute("*", redirect = Some("/"))
    )

    val router: VueRouter = new VueRouter(
      VueRouterOptions(
        routes,
        Some("history")
      )
    )
    new Vue(App.props(appContainer, router))
  }
}

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport

//move to spa utils
@js.native
@JSImport("vue-grid-layout", JSImport.Default)
object VueGridLayout extends js.Object {}

@js.native
@JSImport("vue-grid-layout", "GridLayout")
object GridLayout extends js.Object

@js.native
@JSImport("vue-grid-layout", "GridItem")
object GridItem extends js.Object
