package com.sludg.util.json

import com.sludg.util.models.DashboardModels.{
  Dashboard,
  DashboardData,
  ExtensionStats,
  UnsavedDashboard
}
import play.api.libs.json._
import CallJsonDeserializers._
import com.sludg.util.models.CallGroupCounterModels.CallGroupCallStats
import com.sludg.util.models.Events.{
  CallGroupStatsEvent,
  UserPresenceEvent,
  UserPresenceState
}
import com.sludg.util.models.{DashboardModels, SilhouetteModels}
import com.sludg.util.json.GroupingJsonDeserializers._
import DashboardComponentDeserializers._

object DashboardJsonDeserializers {
  import play.api.libs.json._
  import com.sludg.util.JsonUtils._
  import Reads._

  implicit val er: Reads[ExtensionStats] = Json.reads[ExtensionStats]
  implicit val ew: Writes[ExtensionStats] = Json.writes[ExtensionStats]

  implicit val dashboardReads: Reads[Dashboard] = Json.reads[Dashboard]
  implicit val dashboardWrites: Writes[Dashboard] = Json.writes[Dashboard]

  implicit val unsavedDashboardReads: Reads[UnsavedDashboard] =
    Json.reads[UnsavedDashboard]
  implicit val unsavedDashboardWrites: Writes[UnsavedDashboard] =
    Json.writes[UnsavedDashboard]

  implicit val dashboardDataReads: Reads[DashboardData] =
    Json.reads[DashboardData]
  implicit val dashboardDataWrites: Writes[DashboardData] =
    Json.writes[DashboardData]

  implicit val ups: Format[UserPresenceState] = formatViaMap(
    UserPresenceState.strToObj
  )

}
