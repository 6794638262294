package com.sludg.pages.mainpage.grid.cells

import com.sludg.models.ComponentFacade.DashboardJSComponentData
import com.sludg.pages.mainpage.config.DashboardConfig.{
  ComponentConfiguration,
  UserStatsComponentConfig,
  getComponentInitialSize
}
import com.sludg.pages.mainpage.grid.Grid._
import com.sludg.helpers.Helper.{GridType, isEditing}
import com.sludg.helpers.States
import com.sludg.helpers.States.EditingMode
import com.sludg.util.models.DashboardComponentModels.ComponentType.{
  IncomingStats,
  OutgoingStats,
  UserPresence
}
import com.sludg.util.models.DashboardComponentModels.{
  CallGroupComponentData,
  ComponentType,
  DashboardComponent
}
import com.sludg.util.models.SilhouetteModels.{CallGroup, Tenant}
import com.sludg.util.json.FiltersJsonDeserializers._
import com.sludg.vue.RenderHelpers.{div, p}
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.gridSystem.VueGridItemProps
import com.sludg.vuetify.components.{VButtonProps, VDialogProps, VListProps, VTextFieldProps}
import org.scalajs.dom.Event
import play.api.libs.json.{Json, Reads}
import com.sludg.json.JsonDeserialiser._

import scala.collection.immutable.List
import scala.reflect.ClassTag
import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import com.sludg.pages.mainpage.config.DashboardConfig._
import com.sludg.pages.mainpage.grid.cells.widget_1.components.CallGroup.CallGroupProps
import com.sludg.pages.mainpage.grid.cells.widget_1.GenericComponent.DashboardComponentScopedSlots
import com.sludg.pages.mainpage.grid.cells.widget_1.components.InOutCallStats.CallStatsProps
import com.sludg.pages.mainpage.grid.cells.widget_1.components.UserPresence.UserPresenceProps
import com.sludg.pages.mainpage.grid.cells.widget_1.components.UserStats.UserStatsProps

object ComponentCell {

  def buildComponentCell(
      dashComp: DashboardComponent,
      g: GridType,
      renderer: CreateElement
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    div(
      vueGridItem(
        buildDashboardComponent(g, dashComp, g.reset, g.editMode, g.tenant, renderer),
        RenderOptions(
          props = Some(
            VueGridItemProps(
              isResizable = Some(isEditing(g.editMode)),
              isDraggable = Some(isEditing(g.editMode)),
              minH = Some(getComponentInitialSize(dashComp.componentType).minH),
              minW = Some(getComponentInitialSize(dashComp.componentType).minW),
              maxH = Some(getComponentInitialSize(dashComp.componentType).maxH),
              maxW = Some(getComponentInitialSize(dashComp.componentType).maxW),
              x = Some(dashComp.x),
              y = Some(dashComp.y),
              i = Some(dashComp.id),
              h = Some(dashComp.height),
              w = Some(dashComp.width)
            )
          ),
          on = Some(
            js.Dynamic
              .literal(
                "move" -> ((
                    (
                        i,
                        newX,
                        newY
                    ) =>
                      fireUpdateComponentEvent(
                        g,
                        g.dashboardComponents.map(compo =>
                          if (compo.id == dashComp.id) {
                            compo.copy(
                              x = newX,
                              y = newY
                            )
                          } else compo
                        )
                      )
                ): js.Function3[Event, Int, Int, Unit]),
                "resize" -> ((
                    (
                        i,
                        newH,
                        newW,
                        newHPx,
                        newWPx
                    ) =>
                      fireUpdateComponentEvent(
                        g,
                        g.dashboardComponents.map(compo =>
                          if (compo.id == dashComp.id)
                            compo.copy(
                              height = newH,
                              width = newW
                            )
                          else compo
                        )
                      )
                ): js.Function5[Event, Int, Int, Int, Int, Unit])
              )
              .asInstanceOf[EventBindings]
          )
        )
      )
    )
  }

  //Method which returns a dashboard component name
  def getComponentName(comp: DashboardComponent, g: GridType) = comp.title match {
    case Some(title) if title.nonEmpty => title
    case _ =>
      // if title isEmpty or None
      compTypeToString.get(comp.componentType) match {
        // Call Group requires its name and extension from 'cache' for it to be displayed in the title
        case Some(defaultTitle) if comp.componentType == ComponentType.CallGroup =>
          g.selectedCallGroupsWithIds.get(comp.id) match {
            case Some(callGroup) => s"$defaultTitle: ${callGroup.name} (${callGroup.extension})"
            case None => defaultTitle
          }
        case Some(defaultTitle) => defaultTitle
        case _ => "Unknown Component Type"
      }
  }

  def buildTitle(
      g: GridType,
      widget: DashboardComponent,
      editMode: EditingMode,
      editing: Boolean
  ) = {
    vContainer(
      RenderOptions(
        `class` = List(Left("pa-1 pt-1"))
      ),
      if (isEditing(g.editMode) && editing) {
        val widgetName = getComponentName(widget, g)
        val placeHolder = compTypeToString.getOrElse(widget.componentType, "Component Name")
        vTextField(
          RenderOptions(
            style = Some(
              js.Dynamic.literal(
                "width" -> (if (editing) "80%" else "100%")
              )
            ),
            attrs = Some(
              js.Dynamic
                .literal(
                  "maxlength" -> "40"
                )
                .asInstanceOf[HtmlAttrs]
            ),
            props = Some(
              VTextFieldProps(
                value = Some(widgetName),
                counter = Some(Left(40)),
                placeholder = Some(placeHolder),
                height = Some(Right("10px")),
                `solo-inverted` = Some(true),
                flat = Some(true),
                `hide-details` = Some(true)
              )
            ),
            `class` = List(Left("component-title")),
            on = Some(
              EventBindings(
                change = js.defined(titleInput => {
                  if (js.isUndefined(titleInput) && titleInput != null) {
                    logger.debug(s"Component Input undefined or null: ${titleInput}")
                  } else {
                    logger.debug(s"Component Input Changed: ${titleInput}")

                    fireUpdateComponentEvent(
                      g,
                      g.dashboardComponents.map { compo =>
                        if (compo.id == widget.id) {
                          compo.copy(
                            title = Some(titleInput.toString)
                          )
                        } else compo
                      }
                    )

                  }
                })
              )
            )
          )
        )
      } else {
        val widgetName = getComponentName(widget, g)
        p(widgetName.substring(0, 35), RenderOptions(`class` = List(Left("component-title"))))
      }
    )
  }

  /*

   * * Creation of the generalised dashboard component rectangle
   * Included is a white background and a button which can delete the component

   */
  def buildDashboardComponent(
      g: GridType,
      dashComp: DashboardComponent,
      reset: Boolean,
      editMode: EditingMode,
      tenant: Tenant,
      renderer: CreateElement
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    div(
      RenderOptions(style = Some(js.Dynamic.literal("height" -> "100%"))),
      buildType(dashComp, reset, g.editMode, g.tenant, g.refresh, g, renderer),
      if (isEditing(g.editMode)) {
        div(
          vButton(
            vIcon("delete"),
            RenderOptions(
              props = Some(VButtonProps(icon = Some(true))),
              style = Some(
                js.Dynamic.literal(
                  "background-color" -> "white",
                  "position" -> "fixed",
                  "top" -> "6px",
                  "right" -> "4px",
                  "width" -> "25px",
                  "height" -> "25px",
                  "z-index" -> "20"
                )
              ),
              on = Some(EventBindings(click = js.defined(e => {
                g.selectedYPosition = dashComp.y
                g.selectedXPosition = dashComp.x
                g.deletionDialogVisible = true
              })))
            )
          ),
          vDialog(
            RenderOptions(
              style = Some(js.Dynamic.literal("text-align" -> "center", "box-shadow" -> "0px")),
              props = Some(
                VDialogProps(
                  value = Some(g.deletionDialogVisible),
                  width = Some(Right(200)),
                  scrollable = Some(false),
                  `max-width` = Some(Right(550))
                )
              ),
              on = Some(
                EventBindings(input =
                  js.defined(e => g.deletionDialogVisible = e.asInstanceOf[Boolean])
                )
              )
            ),
            vCard(
              vCardTitle("Delete Component", RenderOptions(`class` = List(Left("headline")))),
              vDivider,
              vFlex(
                p(
                  s"Are you sure you want to delete this component?",
                  RenderOptions(
                    style = Some(
                      js.Dynamic.literal(
                        "padding-left" -> "40px",
                        "padding-right" -> "40px",
                        "padding-top" -> "35px"
                      )
                    )
                  )
                ),
                vCardActions(
                  vSpacer,
                  vButton(
                    "Cancel",
                    EventBindings(click = js.defined(e => g.deletionDialogVisible = false))
                  ),
                  vButton(
                    "Delete",
                    RenderOptions(
                      props = Some(VButtonProps(color = Some("red"))),
                      on = deleteDashboardComponent(g, dashComp)
                    )
                  )
                )
              )
            )
          )
        )
      } else {
        div()
      }
    )
  }

  /*
   * Creation of the dashboard component type
   */

  def buildType(
      dashComp: DashboardComponent,
      reset: Boolean,
      editMode: EditingMode,
      tenant: Tenant,
      refresh: Boolean,
      c: GridType,
      renderer: CreateElement
  ): RenderHelpers.NodeRenderer[_, _, _] = {

    def jsonValidate[T, Y <: VueProps](
        v: T => RenderHelpers.NodeRenderer[Y, EventBindings, ScopedSlots]
    )(implicit
        rds: Reads[T]
    ): RenderHelpers.NodeRenderer[_ >: Y <: VueProps, EventBindings, ScopedSlots] = {
      Json.parse(dashComp.componentConfig.getOrElse("")).validate[T].asOpt match {
        case Some(value) => v(value)
        case None => div("Invalid component config")
      }
    }

    /**
      * Shared render options for each component, including events
      * //TODO Type events for components
      */

    def buildRenderOptions[A <: com.sludg.vue.VueProps](
        a: A
    ): RenderOptions[A, EventBindings, DashboardComponentScopedSlots] = {
      RenderOptions(
        props = Some(a),
        scopedSlots = Some(new DashboardComponentScopedSlots {
          val componentTitle: js.UndefOr[js.Function1[Boolean, VNode]] = js.defined(isEditing => {
            buildTitle(c, dashComp, c.editMode, isEditing).render(renderer)
          })
        }),
        on = Some(
          js.Dynamic
            .literal(
              "updateComponentEventSubscription" -> ((result => {

                fireUpdateComponentEvent(
                  c,
                  c.dashboardComponents
                    .map(compo =>
                      if (compo.id == result._1)
                        compo.copy(
                          filters = result._2.filter,
                          eventSubscription = result._2.eventSubscription,
                          componentConfig = result._2.componentDesign
                        )
                      else
                        compo
                    )
                    .toList
                )

              }): js.Function1[(Int, ComponentConfiguration), Unit]),
              "call-group:selected" -> ((e => {
                val (dashCompId, selectedCallGroup) = e.asInstanceOf[(Int, CallGroup)]
                c.selectedCallGroupsWithIds =
                  c.selectedCallGroupsWithIds + (dashCompId -> selectedCallGroup)
              }): js.Function1[(Int, CallGroupComponentData), Unit])
            )
            .asInstanceOf[EventBindings]
        )
      )
    }

    dashComp.componentType match {
      case UserPresence =>
        userPresence(
          buildRenderOptions(
            new UserPresenceProps(
              reset,
              editMode,
              dashComp.subscribedTenantId,
              dashComp.id,
              dashComp.height,
              dashComp.width,
              dashComp.filters,
              c.userPresenceEvent,
              c.token
            )
          )
        )
      case IncomingStats =>
        callStats(
          buildRenderOptions(
            new CallStatsProps(
              reset,
              editMode,
              dashComp.subscribedTenantId,
              dashComp.id,
              refresh,
              dashComp.height,
              dashComp.width,
              States.IncomingStats,
              dashComp.filters,
              c.token
            )
          )
        )
      case OutgoingStats =>
        callStats(
          buildRenderOptions(
            new CallStatsProps(
              reset,
              editMode,
              dashComp.subscribedTenantId,
              dashComp.id,
              refresh,
              dashComp.height,
              dashComp.width,
              States.OutgoingStats,
              dashComp.filters,
              c.token
            )
          )
        )
      case ComponentType.UserStats =>
        jsonValidate((uscc: UserStatsComponentConfig) =>
          userStats(
            buildRenderOptions(
              new UserStatsProps(
                reset,
                editMode,
                dashComp.subscribedTenantId,
                dashComp.id,
                refresh,
                c.selectedSubscriber,
                dashComp.height,
                dashComp.width,
                dashComp.filters,
                uscc,
                c.token
              )
            )
          )
        )
      case ComponentType.CallGroup =>
        callGroup(
          buildRenderOptions(
            new CallGroupProps(
              reset,
              dashComp.eventSubscription.getOrElse(""),
              editMode,
              dashComp.subscribedTenantId,
              dashComp.id,
              c.userPresenceEvent,
              c.callGroupStatsEvent,
              c.userType,
              dashComp.height,
              dashComp.width,
              c.token
            )
          )
        )
    }
  }
}
