package com.sludg.pages.mainpage.grid.cells

import com.sludg.auth0.SludgToken
import com.sludg.models.ComponentFacade.DashboardJSComponentData
import com.sludg.helpers.Helper.{GridType, isEditing}
import com.sludg.helpers.States.SelectionMode.SelectComponent
import com.sludg.services.ApiCalls
import com.sludg.vue.{EventBindings, RenderHelpers, RenderOptions, ScopedSlots}
import com.sludg.vuetify.VuetifyComponents.{vButton, vIcon, vueGridItem}
import com.sludg.vuetify.components.VButtonProps
import com.sludg.vuetify.components.gridSystem.VueGridItemProps

import scala.collection.immutable.List
import scala.scalajs.js
import monix.execution.Scheduler.Implicits.global
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.VNode

object EmptyCell {

  /**
    * Empty cells are the spaces in-between the dashboard components They are composed of a button that
    * triggers a selection dialog. This dialog allows you to add new dashboard components.
    * These cells are only visible when you are in Edit Mode.
    * When you create a new dashboard, the entire grid system is composed of these cells as there are no components left.
    *
    * These cells often have their position recalculated. We do this to fill the gaps around dashboard components.
    * This allows a user to always add a new component at their desired location by clicking around existing components.
    * If the desired location is out of bounds, there are safe guards in place to place them in the correct position.
    *
    * ** Empty cells position generation ***
    * The empty cells have their positions recalculated whenever the following happens
    * *   * A new dashboard component is added
    * *   * If a component causes another component to change location, pushed
    * *   * A component is deleted and removed from the current dashboard grid
    * *   * A component is being dragged, and it successfully clips to a new grid position
    * *   * A component is being resized, and it clips to a new size
    * *   * A dashboard is reloaded, the dropdown is used or a new dashboard is selected
    * *   * A new dashboard is created, cloned, or deleted
    * *   *
    */
  def buildEmptyCell(
      dashboardComponentJS: DashboardJSComponentData,
      apiCalls: ApiCalls,
      token: SludgToken,
      g: GridType
  ): RenderFunction[VNode] = {
    vueGridItem(
      vButton(
        vIcon("add"),
        RenderOptions(
          `class` = List(Left("button-alive")),
          style = Some(
            js.Dynamic.literal(
              "visibility" -> (if (isEditing(g.editMode)) "visible" else "hidden"),
              "margin" -> "0px",
              "width" -> "100%",
              "height" -> "100%",
              "opacity" -> "0.25",
              "filter" -> "alpha(opacity=50)"
            )
          ),
          props = Some(VButtonProps(flat = Some(true))),
          on = Some(EventBindings(click = js.defined(e => {

            val selectedTid = g.tenantSuperUserIsViewing.map(_.id).getOrElse(g.tenant.id)
            g.addingComponentSelectedTenant = g.tenants.collectFirst {
              case i if i.id == selectedTid => i
            }

            apiCalls
              .getCallGroups(g.addingComponentSelectedTenant.map(_.id).getOrElse(g.tenant.id))(
                implicitly,
                token
              )
              .map {
                case Left(x) => x
                case Right(x) => {
                  g.callGroups = x
                  g.selectedCallGroup = x.headOption
                }
              }

            // updating the grid location selected
            g.selectedYPosition = dashboardComponentJS.y
            g.selectedXPosition = dashboardComponentJS.x
            g.selectionMode = SelectComponent
          })))
        )
      ),
      // transition must be 0s otherwise you will see the dashboard cells fly around the screen every time they are repositioned
      RenderOptions(
        style = Some(js.Dynamic.literal("transition-duration" -> "0s")),
        props = Some(
          VueGridItemProps(
            isResizable = Some(false),
            isDraggable = Some(false),
            x = Some(dashboardComponentJS.x),
            y = Some(dashboardComponentJS.y),
            i = Some(dashboardComponentJS.i),
            h = Some(dashboardComponentJS.h),
            w = Some(dashboardComponentJS.w)
          )
        )
      )
    )
  }
}
