package com.sludg.pages.mainpage.grid.cells.widget_1.components

import com.sludg.FieldExtractor
import com.sludg.helpers.States.{EditingMode, UserType}
import com.sludg.scalajs.DynamicHelper
import com.sludg.services.ApiCalls
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import org.log4s._
import monix.execution.Scheduler

import scala.scalajs.js
import js.JSConverters._
import scala.concurrent.Future
import com.sludg.auth0.SludgToken
import com.sludg.util.models.Events.UserPresenceEventType._
import com.sludg.util.models.Events._
import com.sludg.helpers.LoadingFuture
import com.sludg.models.Models.AccessForbidden
import com.sludg.pages.mainpage.config
import com.sludg.pages.mainpage.config.DashboardConfig.{
  ComponentConfiguration,
  UserStatsComponentConfig,
  getComponentInitialSize
}
import com.sludg.helpers.States.EditingMode.Editing

import scala.collection.immutable.{::, List, Nil}
import com.sludg.helpers.Helper._
import com.sludg.helpers.States.UserType.SuperUser
import com.sludg.util.models.SilhouetteModels.{CallGroup => CallGroupModel}
import com.sludg.util.models.SilhouetteModels.CallGroupType.BroadcastGroup
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vuetify.components.grid.{VFlexProps, VGridProps}
import scala.scalajs.js.|
import cats.data.EitherT
import cats.implicits._
import com.sludg.pages.mainpage.grid.cells.widget_1.GenericComponent.DashboardComponentScopedSlots

object CallGroup {

  import monix.execution.Scheduler.Implicits.global

  val logger: Logger = getLogger

  def callGroupRenderer(
      registrationName: String
  ): RenderHelpers.NodeRenderer[CallGroupProps, EventBindings, ScopedSlots] =
    namedTag[CallGroupProps, EventBindings, ScopedSlots](registrationName)

  def callGroupComponent(
      loader: Vue,
      apiCalls: ApiCalls
  ): VueComponent[_ <: CallGroupProps, _ <: Slots, _ <: DashboardComponentScopedSlots] = {
    VueComponent.builder
      .withData(new CallGroupData)
      .withScopedSlots[DashboardComponentScopedSlots]
      .withPropsAs[CallGroupProps]
      .build(
        watch = new callGroupWatcher(),
        created = js.defined(c => {
          c.api = Some(apiCalls)
          c.loader = Some(loader)

          //Why? @Ali
          val callGroupId: Option[Int] = if (c.event.isEmpty) {
            None
          } else {
            try {
              Some(c.event.toInt)
            } catch {
              case e: Exception => None
            }
          }
          initialiseComponent(callGroupId, c.selectedTenant, c.id, loader, apiCalls, c)
        }),
        templateOrRender = Right((c, renderer) =>
          div(
            RenderOptions(`class` = List(Left("background-white-full"))),
            c.$scopedSlots.componentTitle.foldToNode(x => x(c.editing), nothing),
            editButton(c),
            if (c.editing && c.editMode == Editing) editScreen(apiCalls, loader, renderer, c)
            else mainScreen(renderer, loader, apiCalls, c)
          ).render(renderer)
        )
      )
  }

  def editButton(c: CallGroupType) = {
    if (c.editMode == Editing) {
      vButton(
        if (!c.editing) vIcon("edit") else vIcon("save"),
        RenderOptions(
          `class` = List(Left("background-red")),
          style = Some(
            js.Dynamic.literal(
              "height" -> "25px",
              "width" -> "25px",
              "position" -> "absolute",
              "right" -> "30px",
              "top" -> "6px",
              "z-index" -> "15"
            )
          ),
          props = Some(VButtonProps(icon = Some(true))),
          on = Some(EventBindings(click = js.defined(e => c.editing = !c.editing)))
        )
      )
    } else {
      div()
    }
  }

  def editScreen(
      apiCalls: ApiCalls,
      loader: Vue,
      renderer: CreateElement,
      c: CallGroupType
  ): RenderFunction[VNode] = {
    div(
      if (c.userType == SuperUser) {
        implicit val token = c.token
        div(
          vButton(
            "Reset Call Group Stats",
            RenderOptions(
              on = Some(EventBindings(click = js.defined(e => {
                LoadingFuture.withLoading(
                  loader,
                  apiCalls.deleteCallGroupCallStats(
                    c.selectedTenant,
                    c.callGroupExtension.toInt
                  ) map {
                    case Left(x) => logger.debug("Access forbidden.")
                    case Right(x) =>
                      x match {
                        case Some(x) =>
                          c.ringing = x.ringing
                          c.waiting = x.waiting
                          c.talking = x.talking
                        case None => logger.debug("Unknown response.")
                      }
                  }
                )
              })))
            )
          ),
          p("Click here to reset Tenant CallGroup data")
        )
      } else {
        nothing
      },
      selectCallGroupScreen(c, apiCalls, loader, c.selectedTenant)
    )

  }

  def mainScreen(
      renderer: CreateElement,
      loader: Vue,
      apiCalls: ApiCalls,
      c: CallGroupType
  ): RenderFunction[VNode] = {
    vContainer(
      RenderOptions(
        props = Some(
          VGridProps(
            fluid = true
          )
        ),
        `class` = List(
          Left("scroll-y"),
          Left(
            if (c.editing && c.editMode == EditingMode.Editing) ""
            else "pa-0 ma-0 pt-0"
          )
        ),
        style = Some(
          js.Dynamic.literal(
            "max-height" -> s"${(c.height * config.DashboardConfig.gridRowHeight) - config.DashboardConfig.titleHeight}px"
          )
        )
      ),
      vCard(
        RenderOptions(
          `class` = if (c.height < 3 && !c.editing) {
            List(Left("margin-top-50"))
          } else {
            List(Left("margin-top-50q3r"))
          }
        ),
        vLayout(
          RenderOptions(`class` = List(Left("center-text2"))),
          vFlexRow("Ringing", "Waiting", "Talking")
        ),
        vLayout(
          RenderOptions(`class` = List(Left("center-text2"))),
          vFlexRow(c.ringing.toString, c.waiting.toString, c.talking.toString)
        ),
        if (c.height > 2) {
          div(
            VDataTable.vDataTable(
              RenderOptions[
                VDataTableProps[TableItems, VDataTableHeader],
                EventBindings,
                VDataTableScopedSlots[TableItems, VDataTableHeader]
              ](
                style = Some(js.Dynamic.literal("height" -> "100%")),
                props = Some(
                  VDataTableProps[TableItems, VDataTableHeader](
                    dense = Some(true),
                    headers = Some(
                      List(
                        new VDataTableHeader(sortable = false, text = "Extension"),
                        new VDataTableHeader(sortable = false, text = "Name"),
                        new VDataTableHeader(sortable = false, text = "In-Group"),
                        new VDataTableHeader(sortable = false, text = "Free")
                      )
                    ),
                    items = c.tableItems,
                    `hide-actions` = Some(true)
                  )
                ),
                scopedSlots = Some(
                  new VDataTableScopedSlots[TableItems, VDataTableHeader](
                    items = js.defined(i => {
                      val extension = i.item.extension
                      val inGroup: Boolean = !i.item.inGroup
                      val realStatus = i.item.extensionUserPresence
                      val name = i.item.name
                      tr(
                        td(RenderOptions(`class` = List(Left("style_7"))), extension),
                        td(RenderOptions(`class` = List(Left("style_7"))), name),
                        td(
                          RenderOptions(`class` = List(Left("fourty-five-width"))),
                          vButton(
                            RenderOptions(
                              style = Some(js.Dynamic.literal("background-color" -> "#eaeaea")),
                              props = Some(VButtonProps(icon = Some(true))),
                              on = updateAvailability(
                                c.event,
                                c.selectedTenant,
                                c.id,
                                c,
                                apiCalls,
                                loader,
                                extension,
                                inGroup,
                                if (inGroup) i.item.extensionUserPresence else false,
                                realStatus
                              )(c.token)
                            ),
                            loadIcons(i.item.inGroup)
                          )
                        ),
                        td(
                          RenderOptions(`class` = List(Left("style_7"))),
                          loadIcons(i.item.free)
                        )
                      ).render(renderer)

                    })
                  )
                )
              )
            ),
            vLayout(
              RenderOptions(`class` = List(Left("center-text"))),
              table(
                RenderOptions(`class` = List(Left("fill-width"))),
                tr(
                  td(vFlex(RenderOptions(`class` = List(Left("center-text"))), "Total")),
                  td(
                    vFlex(
                      c.tableItems
                        .getOrElse(List())
                        .count(_.inGroup == true)
                        .toString()
                    )
                  ),
                  td(
                    vFlex(
                      c.tableItems
                        .getOrElse(List())
                        .count(_.free == true)
                        .toString()
                    )
                  )
                )
              )
            )
          )
        } else {
          div()
        }
      )
    )
  }

  def selectCallGroupScreen(
      g: CallGroupType,
      apiCalls: ApiCalls,
      loader: Vue,
      tenantId: Int
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    if (g.callGroups.nonEmpty) {
      div(
        vLayout(
          RenderOptions(`class` = List(Left("full-width-bottom"))),
          vFlex(
            RenderOptions(style = Some(js.Dynamic.literal("background" -> "white"))),
            vAutocomplete(
              RenderOptions[VAutocompleteProps[
                CallGroupModel
              ], VAutocompleteEventBindings, VAutocompleteScopedSlots[CallGroupModel]](
                style = Some(
                  js.Dynamic.literal(
                    "padding-left" -> "40px",
                    "padding-right" -> "40px",
                    "float" -> "bottom"
                  )
                ),
                props = Some(
                  js.Dynamic
                    .literal(
                      "label" -> "Select Call-Group",
                      "items" -> g.callGroups.toJSArray,
                      "item-text" -> ((
                          (itemText) =>
                            itemText.asInstanceOf[CallGroupModel].name + " " + itemText
                              .asInstanceOf[CallGroupModel]
                              .extension
                      ): js.Function1[js.Array[CallGroupModel] | CallGroupModel, String]),
                      "value" -> g.selectedCallGroup
                        .getOrElse(CallGroupModel(0, BroadcastGroup, "", ""))
                        .asInstanceOf[js.Any],
                      "return-object" -> true,
                      "autocomplete" -> true,
                      "placeholder" -> "Select call group",
                      "clearable" -> true,
                      "flat" -> true,
                      "solo-invertd" -> true,
                      "hide-details" -> true
                    )
                    .asInstanceOf[VAutocompleteProps[CallGroupModel]]
                ),
                on = Some(VAutocompleteEventBindings(input = js.defined(e => {
                  val callGroup = e.asInstanceOf[CallGroupModel]
                  g.selectedCallGroup = Some(callGroup)

                  g.$emit(
                    "updateComponentEventSubscription",
                    (
                      g.id,
                      ComponentConfiguration(
                        g.selectedTenant,
                        eventSubscription = Some(callGroup.id.toString),
                        title = Some(s"Call Group: ${callGroup.name} (${callGroup.extension})")
                      )
                    )
                  )
                  initialiseComponent(
                    Some(callGroup.id),
                    g.selectedTenant,
                    g.id,
                    loader,
                    apiCalls,
                    g
                  )
                })))
              )
            )
          )
        )
      )
    } else {
      div()
    }
  }

  def initialiseComponent(
      event: Option[Int],
      tenantId: Int,
      id: Int,
      loader: Vue,
      apiCalls: ApiCalls,
      c: CallGroupType
  )(implicit scheduler: Scheduler): Future[Either[AccessForbidden, Unit]] = {
    import scala.concurrent.ExecutionContext.Implicits.global
    implicit val token = c.token
    LoadingFuture.withLoading(
      loader,
      (for {
        callGroups <- EitherT(apiCalls.getCallGroups(c.selectedTenant))
        callGroupData <- EitherT(
          apiCalls
            .getCallGroupMembers(c.selectedTenant, event.getOrElse(callGroups.head.id))
            .map(processResult)
        )
        extensions = callGroupData.callGroupMembers.map(_.subscriber.extension)
        userPresenceMap <- EitherT.right[AccessForbidden](
          extensions
            .map(sub => apiCalls.getUserPresence(tenantId, sub))
            .sequence
            .map(statusCalls =>
              (extensions zip statusCalls).collect {
                case (extension, Right(status)) if status.isDefined => (extension, status)
              }.toMap
            )
        )
      } yield {
        c.callGroups = callGroups
        c.callGroupExtension = callGroupData.callGroup.extension.toString
        c.callGroupName = Some(callGroupData.callGroup.name)
        c.selectedCallGroup = Some(callGroupData.callGroup)
        c.callGroupId = callGroupData.callGroup.id
        c.tableItems = Some(
          callGroupData.callGroupMembers
            .sortBy(_.subscriber.extension)
            .map(i => {
              val userPresence: Option[UserPresenceState] =
                userPresenceMap.get(i.subscriber.extension).flatten
              new TableItems(
                i.subscriber.extension,
                i.subscriber.firstName.getOrElse(""),
                i.callGroupMemberData.loggedIn,
                if (i.callGroupMemberData.loggedIn && userPresence.contains(UserPresenceState.Idle))
                  true
                else false,
                if (userPresence.contains(UserPresenceState.Idle)) true else false
              )
            })
        )

        // Happens on initial lode
        // if call group is already saved, shouldn't send anything
        // if call group
        c.$emit("call-group:selected", (c.id, callGroupData.callGroup))

        apiCalls
          .getCallGroupCallStats(tenantId, callGroupData.callGroup.extension.toInt)
          .map(processResult)
          .map {
            case Left(result) => logger.debug("getCallGroupCallStats error " + result)
            case Right(callGroupCallStats) => {
              c.ringing = callGroupCallStats.ringing
              c.waiting = callGroupCallStats.waiting
              c.talking = callGroupCallStats.talking
            }
          }
        ()
      }).value
    )
  }

  def updateComponentPresence(
      c: CallGroupType,
      extension: String,
      userPresenceEventType: UserPresenceEventType
  ) = {
    c.tableItems = Some(
      c.tableItems.getOrElse(List()).map {
        /* updating the ingroup status */
        case e if e.extension == extension => {
          val newRealFreeStatus = if (userPresenceEventType == Idle) true else false
          new TableItems(
            e.extension,
            e.name,
            e.inGroup,
            if (e.inGroup) newRealFreeStatus else false,
            newRealFreeStatus
          )
        };
        case e => e
      }
    )
  }

  def updateComponentGroup(
      c: CallGroupType,
      extension: String,
      userPresenceEventType: UserPresenceEventType
  ) = {
    /* updating the ingroup status */
    c.tableItems = Some(c.tableItems.getOrElse(List()).map {
      case e if e.extension == extension => {
        val oldRealStatus = e.extensionUserPresence
        val newInGroupStatus = if (userPresenceEventType == JoinedGroup) true else false
        val newFreeStatus: Boolean = if (newInGroupStatus) oldRealStatus else false
        new TableItems(
          e.extension,
          e.name,
          newInGroupStatus,
          newFreeStatus,
          oldRealStatus
        )
      }
      case e => e
    })
  }

  def updateAvailability(
      event: String,
      tenant: Int,
      id: Int,
      c: CallGroupType,
      apiCalls: ApiCalls,
      loader: Vue,
      extension: String,
      inGroup: Boolean,
      status: Boolean,
      realStatus: Boolean
  )(implicit token: SludgToken): Option[EventBindings] = {
    Some(
      EventBindings(
        click = js.defined(e =>
          LoadingFuture.withLoading(
            loader,
            apiCalls
              .updateCallGroupMember(c.selectedTenant, c.callGroupId, extension.toInt, inGroup)
              .map {
                case Left(x) => logger.debug("Access forbidden.")
                case Right(x) =>
                  logger.debug("success")
                  c.tableItems = Some(
                    c.tableItems.getOrElse(List()).map {
                      case e if e.extension.contains(extension) =>
                        new TableItems(
                          e.extension,
                          e.name,
                          inGroup,
                          status,
                          realStatus
                        )
                      case e => e
                    }
                  )
              }
          )
        )
      )
    )
  }

  class callGroupWatcher extends js.Object {
    def editMode(value: js.Any): Unit = this.asInstanceOf[CallGroupType].editing = false

    def presenceEvent(value: js.Any): Unit = {
      val c = this.asInstanceOf[CallGroupType]
      val event = value.asInstanceOf[UserPresenceEvent]

      if (event.event_type == Idle || event.event_type == Busy) {
        updateComponentPresence(c, event.extension, event.event_type)
      } else if (event.event_type == LeftGroup || event.event_type == JoinedGroup) {
        updateComponentGroup(c, event.extension, event.event_type)
      }
    }

    def reset(): Unit = {
      val c = this.asInstanceOf[CallGroupType]

      /* dropdown */
      c.selectedCallGroup = None
      c.callGroups = Nil

      // Resets component
      (c.api, c.loader) match {
        case (Some(apiCalls), Some(loader)) =>
          initialiseComponent(
            (if (c.event.isEmpty) {
               None
             } else {
               try {
                 Some(c.event.toInt)
               } catch {
                 case e: Exception => None
               }
             }),
            c.selectedTenant,
            c.id,
            loader,
            apiCalls,
            c
          )
        case _ => logger.debug("Could not load data in Call Group")
      }
    }

    def callGroupStats(value: js.Any): Unit = {
      val c = this.asInstanceOf[CallGroupType]
      val newEvent = value.asInstanceOf[CallGroupStatsEvent]

      if (newEvent.callGroupExtension == c.callGroupExtension) {
        c.ringing = newEvent.callGroupStats.ringing
        c.waiting = newEvent.callGroupStats.waiting
        c.talking = newEvent.callGroupStats.talking
      }
    }

  }

  class TableItems(
      val extension: String,
      val name: String,
      val inGroup: Boolean,
      val free: Boolean,
      val extensionUserPresence: Boolean
  ) extends js.Object

  class CallGroupData extends js.Object {
    var editing = true
    var ringing, waiting, talking, callGroupId = 0
    var tableItems: Option[List[TableItems]] = Some(List())
    var callGroupName: Option[String] = None
    var callGroupExtension: String = ""
    var selectedCallGroup: Option[CallGroupModel] = None
    var callGroups: List[CallGroupModel] = Nil
    var api: Option[ApiCalls] = None
    var loader: Option[Vue] = None
  }

  class CallGroupProps(
      val reset: Boolean,
      val event: String,
      val editMode: EditingMode,
      val selectedTenant: Int,
      val id: Int,
      val presenceEvent: UserPresenceEvent,
      val callGroupStats: CallGroupStatsEvent,
      val userType: UserType,
      val height: Int,
      val width: Int,
      val token: SludgToken
  ) extends VueProps
}
