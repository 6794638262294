package com.sludg.pages.mainpage.config
import com.sludg.helpers.States
import com.sludg.helpers.States.Ordering.Neutral
import com.sludg.helpers.States.PriorityOrder
import com.sludg.util.models.DashboardComponentModels.ComponentType
import com.sludg.util.models.DashboardComponentModels.ComponentType._
import com.sludg.util.models.ReportModels.Filter
import com.sludg.util.models.ReportModels.Filter.RelativeDateFilter

import scala.collection.immutable.List

// A place to keep the dashboard configuration and default settings */

object DashboardConfig {
  // Grid width and height
  val gridWidth  = 12
  val gridHeight = 15

  // Setting this to the same number as the gridHeight, prevents the grid from growing in size.
  val maxHeight = gridHeight

  // grid row height
  val gridRowHeight = 95

  // Component Title Height
  val titleHeight = 56

  // LocalStorage Dashboard Key
  val dashboardStoreKey = "LastSelectedDashboard"

  // Last accessed url (before re-auth)
  val lastAccessedUrlStoreKey = "LastAccessedUrl"

  case class Coordinate(x: Int, y: Int)

  // every co-ordinate in the grid
  //TODO Replace with case class
  val entireGridLocations: List[(Int, Int)] = for (x <- List.range(0, gridHeight); y <- List.range(0, gridWidth)) yield (x, y)

  /** A config class to facilitate unique configurations for different components
    * Type of component
    * Minimum height
    * Minimum width
    * The start height
    * The start width
    * Maximum height
    * Maximum width
    * If the component is resizable
    */
  case class ComponentConfig(componentType: ComponentType,
                              minH: Int,
                              minW: Int,
                              startHeight: Int,
                              startWidth: Int,
                              maxH: Int,
                              maxW: Int,
                              resizable: Boolean,
                              defaultName: String = "", //TODO move default name
                            )

  /* Unique Component configurations */
  val callGroupConfig: ComponentConfig = ComponentConfig(CallGroup, 2, 2, 3, 3, 12, 12, true)
  val userStatsConfig: ComponentConfig = ComponentConfig(UserStats, 2, 4, 4, 4, 12, 12, true)
  val userPresenceConfig: ComponentConfig = ComponentConfig(UserPresence, 2, 3, 2, 3, 12, 12, true)
  val incomingStatsConfig: ComponentConfig = ComponentConfig(IncomingStats, 2, 2, 3, 3, 12, 12, true)
  val outgoingStatsConfig: ComponentConfig = ComponentConfig(OutgoingStats, 2, 2, 3, 3, 12, 12, true)
  val defaultComponentConfig: ComponentConfig = ComponentConfig(UserPresence, 1, 1, 1, 1, 10, 10, true)

  val compTypeToString: Map[ComponentType, String] = Map(
    UserPresence -> "User Presence",
    IncomingStats -> "Incoming Stats",
    OutgoingStats -> "Outgoing Stats",
    ComponentType.CallGroup -> s"Call Group",
    ComponentType.UserStats -> "User Stats"
  )

  /* Method which returns the component configuration based on the component type provided */
  def getComponentInitialSize(componentType: ComponentType): ComponentConfig =
    componentType match {
      case ComponentType.UserPresence => userPresenceConfig
      case ComponentType.IncomingStats => incomingStatsConfig
      case ComponentType.OutgoingStats => outgoingStatsConfig
      case ComponentType.CallGroup => callGroupConfig
      case ComponentType.UserStats => userStatsConfig
      case _ => defaultComponentConfig
    }

  case class ComponentConfiguration(
                                     tenantId: Int,
                                     filter: List[Filter] = List(),
                                     eventSubscription: Option[String] = None,
                                     componentDesign: Option[String] = None,
                                     title: Option[String] = None
                                   )

  case class UserStatsComponentConfig(checkBoxes: List[Boolean] = Nil, prioritySorting: List[PriorityOrder] = List.fill(14)(PriorityOrder(Neutral, 0)))

}
