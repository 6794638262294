package com.sludg.pages

import java.util.TimerTask

import cats.data.EitherT
import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.helpers.AppSetup.{VtslAppProps, VtslAppScopedSlots}
import com.sludg.helpers.{DashboardsApp, LoadingFuture}
import com.sludg.models.Config.AppLink
import com.sludg.models.Model.DashboardConfig
import com.sludg.models.Models.AccessForbidden
import com.sludg.helpers.Helper.{PageOneType, checkUserType}
import com.sludg.helpers.States.UserType.{AdminUser, StandardUser, SuperUser}
import com.sludg.helpers.States.{EditingMode, UserType}
import com.sludg.pages.mainpage.grid.Grid
import com.sludg.pages.mainpage.grid.Grid.{GridComponentEvents, GridComponentProps}
import com.sludg.pages.mainpage.toolbar.Toolbar
import com.sludg.pages.mainpage.toolbar.Toolbar.{ToolbarEvents, ToolbarProps}
import com.sludg.services.ApiCalls
import com.sludg.services.Websocket._
import com.sludg.util.models.CallGroupCounterModels.CallGroupCallStats
import com.sludg.util.models.DashboardComponentModels.ComponentType.UserPresence
import com.sludg.util.models.DashboardComponentModels.DashboardComponent
import com.sludg.util.models.DashboardModels.{Dashboard, DashboardData}
import com.sludg.util.models.Events.{CallGroupStatsEvent, UserPresenceEvent, UserPresenceEventType}
import com.sludg.util.models.SilhouetteModels.{Subscriber, Tenant}
import com.sludg.util.json.DashboardJsonDeserializers._
import com.sludg.vue.RenderHelpers.{div, namedTag, p}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue._
import org.log4s.getLogger
import org.scalajs.dom.WebSocket
import org.scalajs.dom.ext.{LocalStorage, Storage}

import scala.concurrent.Future
import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.UndefOr
import monix.execution.Scheduler.Implicits.global
import play.api.libs.json.Json
import com.sludg.util.models.RoleModels.AssignedRoles
import com.sludg.util.models.RoleModels.Role.DashboardAdmin
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.VButtonProps
import com.sludg.pages.mainpage.config.DashboardConfig.{dashboardStoreKey, lastAccessedUrlStoreKey}
import com.sludg.util.models.DashboardComponentModels
import monix.execution.Scheduler

import scala.util.Try
import _root_.cats.effect.IO
import scala.concurrent.ExecutionContext

object PageOne {
  private[this] val logger = getLogger

  val dashboardsApp: RenderHelpers.NodeRenderer[VtslAppProps, EventBindings, ScopedSlots] =
    DashboardsApp.dashboardsAppRenderer("DashboardsApp")
  val toolbar: RenderHelpers.NodeRenderer[ToolbarProps, EventBindings, ScopedSlots] =
    Toolbar.toolbarRenderer(registrationName = "Toolbar")
  val grid: RenderHelpers.NodeRenderer[GridComponentProps, Grid.GridComponentEvents, ScopedSlots] =
    Grid.GridComponentRenderer(registrationName = "Grid")

  def pageOneRenderer(
      registrationName: String
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] =
    namedTag[VueProps, EventBindings, ScopedSlots]("PageOne")

  def pageOneComponent(
      config: DashboardConfig,
      apiCalls: ApiCalls,
      security: Security,
      loader: Vue,
      otherApps: List[AppLink],
      token: SludgToken
  ) = {
    buildDashboards(config, apiCalls, security, loader, otherApps, token, checkUserType(token))
  }

  private def buildDashboards(
      config: DashboardConfig,
      apiCalls: ApiCalls,
      security: Security,
      loader: Vue,
      otherApps: List[AppLink],
      token: SludgToken,
      myuserType: UserType
  ) = {
    VueComponent.builder
      .withData(new PageOneData(myuserType, config))
      .build(
        watch = new PageOneWatcher(),
        created = js.defined(p => {
          p.a = Some(apiCalls)
          p.l = Some(loader)
          p.token = Some(token)
          p.subscriberId = token.subscribers.headOption.map(_.sid)
          p.subscriberSuperUserIsViewingId = p.subscriberId
          p.tenant = token.subscribers.headOption.map(a => Tenant(a.tid.toInt, "", ""))
          p.userType = myuserType

          /** Here we check if a user is a standard user or admin/super user
            * If they are a standard user, we check their roles. If they have the correct role. We call the created method.
            */
          myuserType match {
            case StandardUser =>
              LoadingFuture.withLoading(
                loader,
                apiCalls
                  .getUserRoles(token.subscribers.head.tid.toInt, token.subscribers.head.sid)(
                    implicitly,
                    token
                  )
                  .map {
                    case Right(Some(AssignedRoles(_, roles))) if roles.contains(DashboardAdmin) => {
                      p.userType = AdminUser
                      p.standardUserRoleChecked = true
                      created(config, apiCalls, security, loader, token, AdminUser, p)
                    }
                    case a => {
                      p.standardUserRoleChecked = true
                      logger.info("No access." + a)
                    }
                  }
              )
            case _ => created(config, apiCalls, security, loader, token, myuserType, p)
          }
        }),
        components = js.Dynamic.literal(
          "DashboardsApp" -> DashboardsApp
            .dashboardsAppComponent(apiCalls, security, loader, otherApps),
          "Toolbar" -> Toolbar.ToolbarComponent(apiCalls, loader, config, token),
          "Grid" -> Grid.GridComponentComponent(apiCalls, loader, config, token)
        ),
        templateOrRender = Right((po, renderer) => {
          dashboardsApp(
            RenderOptions(
              on = Some(js.Dynamic.literal().asInstanceOf[EventBindings]),
              props = Some(
                new VtslAppProps(
                  title = js.defined("Dashboards"),
                  spacedToolbar = true,
                  vContentIsFluid = true,
                  token = po.token
                )
              ),
              scopedSlots = Some(new VtslAppScopedSlots {
                override val toolbar: UndefOr[js.Function1[Option[Tenant], VNode]] =
                  js.defined(tenantSuperUserIsViewing => {
                    ((po.subscriberId, po.tenant, po.token) match {
                      case (Some(s), Some(t), Some(token)) =>
                        div(
                          RenderOptions(style =
                            Some(
                              js.Dynamic.literal(
                                "position" -> "absolute",
                                "margin-top" -> "-28px",
                                "margin-left" -> "100px"
                              )
                            )
                          ),
                          po.userType match {
                            case StandardUser => div("")
                            case _ =>
                              addToolbar(
                                po,
                                renderer,
                                apiCalls,
                                loader,
                                tenantSuperUserIsViewing,
                                t,
                                s,
                                po.subscriberSuperUserIsViewing,
                                po.userType,
                                token
                              )
                          }
                        )
                      case _ => div()
                    }).render(renderer)
                  })
                override val default: UndefOr[js.Function1[Option[Tenant], VNode]] =
                  js.defined(tenantSuperUserIsViewing => {

                    ((po.subscriberId, po.tenant, po.token) match {
                      case (Some(s), Some(t), Some(token)) =>
                        div(
                          (po.userType, po.standardUserRoleChecked) match {
                            case (StandardUser, true) =>
                              div(
                                vContainer(
                                  vCardText(
                                    "Access to Dashboards requires administrator privileges.",
                                    vIcon(
                                      "dashboard",
                                      RenderOptions(style =
                                        Some(
                                          js.Dynamic
                                            .literal("position" -> "fixed", "margin-top" -> "5px")
                                        )
                                      )
                                    ),
                                    RenderOptions(`class` = List(Left("headline")))
                                  ),
                                  p(
                                    RenderOptions(style =
                                      Some(js.Dynamic.literal("padding-left" -> "16px"))
                                    ),
                                    "Please talk to your administrator if you believe this to be an error."
                                  ),
                                  p(
                                    RenderOptions(style =
                                      Some(js.Dynamic.literal("padding-left" -> "16px"))
                                    ),
                                    "Your administrator can grant you access through the User Portal."
                                  )
                                )
                              )
                            case (StandardUser, false) => div()
                            case _ =>
                              addMainPage(
                                po,
                                renderer,
                                apiCalls,
                                loader,
                                tenantSuperUserIsViewing,
                                t,
                                s,
                                po.subscriberSuperUserIsViewing,
                                po.userType,
                                token
                              )
                          }
                        )
                      case _ => div()
                    }).render(renderer)
                  })
              })
            )
          ).render(renderer)
        })
      )
  }

  private def created(
      config: DashboardConfig,
      apiCalls: ApiCalls,
      security: Security,
      loader: Vue,
      token: SludgToken,
      myuserType: UserType,
      p: PageOneType
  )(implicit ec: ExecutionContext) = {
    loadAndSet(
      p.subscriberId,
      p.tenant,
      p.tenantSuperUserIsViewing,
      apiCalls,
      loader,
      myuserType,
      token,
      p
    ).map {
      case Left(error) => logger.debug("Error, Could not load dashboard:" + error)
      case Right(_) => {
        logger.debug("Successfully loaded")

        //Initialises event connection
        makeConnection(
          p.config,
          extractTenantIds(p.dashboardComponents),
          extractEventTypes(p.dashboardComponents.map(_.componentType)),
          p
        )(token, ec)

      }
    }

    tokenRefresher(
      Security.getExpirationInMillis(token.tokenString) - System.currentTimeMillis(),
      (token: SludgToken) => {
        p.token = Some(token)
      },
      security
    )
  }

  /**
    * @param expireTime The time for the token to expire
    * @param setToken   function which sets the token
    * @param security   The security class
    */
  def tokenRefresher(expireTime: Long, setToken: SludgToken => Unit, security: Security): Unit = {
    val t = new java.util.Timer()
    val logger = getLogger
    logger.debug(s"Token Expiring in ${expireTime / 1000} seconds.")

    val task: TimerTask = new java.util.TimerTask {
      def run(): Unit = {
        logger.debug("Checking s.")

        security.checkSession().map {
          case Some(token) => {
            setToken(new SludgToken(token.accessToken))
            t.cancel()
            tokenRefresher(
              Security.getExpirationInMillis(token.accessToken) - System.currentTimeMillis(),
              setToken,
              security
            )
          }
          case None => logger.debug("Error no token!")
        }
      }
    }
    t.schedule(task, expireTime)
  }

  def addToolbar(
      p: PageOneType,
      renderer: CreateElement,
      apiCalls: ApiCalls,
      loader: Vue,
      tenantSuperUserIsViewing: Option[Tenant],
      tenant: Tenant,
      subscriber: Int,
      subscriberSuperUserIsViewing: Option[Subscriber],
      userType: UserType,
      t: SludgToken
  ): RenderHelpers.NodeRenderer[ToolbarProps, EventBindings, ScopedSlots] = {
    import cats.data._
    import cats.implicits._

    toolbar(
      RenderOptions(
        props = Some(
          ToolbarProps(
            tenant,
            tenantSuperUserIsViewing,
            subscriber,
            p.subscriberSuperUserIsViewing,
            p.dashboards,
            p.editMode,
            p.selectedDashboard,
            p.userType,
            p.subscribersOfTenantSuperUserIsViewing,
            t,
            p.hasUnsavedChanges
          )
        ),
        on = Some(
          ToolbarEvents(
            createDashboardEvent = js.defined(e =>
              createDashboard(
                apiCalls,
                loader,
                p,
                e.unsavedDashboard.tenantId,
                e.unsavedDashboard.subscriberId,
                e.unsavedDashboard.name,
                e.unsavedDashboard.shared,
                e.unsavedDashboard.favourited,
                e._2,
                t
              )
            ),
            updateDashboardEvent = js.defined(e => {
              updateDashboard(
                apiCalls,
                loader,
                p,
                Some(e.dashboard.shared),
                Some(e.dashboard.favourited),
                Some(e.dashboard.name),
                false,
                e.savingNewComponents
              )(t)
            }),
            cancelEditing = js.defined(e => {
              cancelEditing(p, apiCalls, loader, t)
            }),
            deleteDashboard = js.defined(e =>
              e match {
                case Some(dashboard) =>
                  deleteDashboard(
                    apiCalls,
                    loader,
                    p,
                    dashboard.id,
                    dashboard.subscriberId,
                    dashboard.tenantId,
                    p.selectedDashboard.map(_.id)
                  )(t)
                case None => logger.debug("Dashboard not selected for deleting")
              }
            ),
            /**
              * Remove the now private dashboard from the list
              * Reset component tracking
              * Reset selected dashboard
              */
            forceUnshare = js.defined(d => {
              p.dashboards = p.dashboards.filterNot(_.id == d.id)

              val currentSelected = p.selectedDashboard
              p.selectedDashboard = p.dashboards.headOption
              //If the selection has changed, set components to Nil
              if (currentSelected != p.selectedDashboard) {
                p.dashboardComponents = List()
              }

              p.selectedDashboard match {
                case Some(newlySelectedDashboard) =>
                  if (newlySelectedDashboard.id != d.id) {

                    LoadingFuture
                      .withLoading(
                        loader,
                        (for {
                          dashboardData <- EitherT(
                            apiCalls.getDashboard(
                              newlySelectedDashboard.tenantId,
                              d.subscriberId,
                              d.id
                            )(implicitly, t)
                          )
                          _ <- EitherT(
                            apiCalls
                              .updateDashboard(
                                d.tenantId,
                                d.subscriberId,
                                d.id,
                                dashboardData.map(_.dashboard).getOrElse(List()),
                                d.name,
                                shared = false,
                                favourited = d.favourited
                              )(implicitly, t)
                          )
                          get <- EitherT(
                            apiCalls.getDashboard(
                              newlySelectedDashboard.tenantId,
                              p.subscriberId.getOrElse(0),
                              newlySelectedDashboard.id
                            )(implicitly, t)
                          )
                        } yield {
                          get
                        }).value
                      )
                      .map {
                        case Left(x) => logger.debug("Access forbidden")
                        case Right(x) =>
                          x match {
                            case Some(newDashboardLoaded) =>
                              p.dashboardComponents = newDashboardLoaded.dashboard
                            case None => logger.debug("New Dashboard could not be parsed.")
                          }
                      }
                  }
                case None => Future.successful(Left("No dashboard selected."))
              }
            }),
            selectedDashboard = js.defined(e => reloadComponents(apiCalls, loader, p, e, t)),
            activateEditMode = js.defined(e => {
              p.lastSavedDashboardComponents = p.dashboardComponents
              p.editMode = EditingMode.Editing
            })
          )
        )
      )
    )
  }

  /**
    * Checks if a user is a super user
    * Returns a boolean
    *
    * @return
    */
  def isSuperUser(): Boolean = this.asInstanceOf[PageOneType].userType == SuperUser

  def addMainPage(
      p: PageOneType,
      renderer: CreateElement,
      apiCalls: ApiCalls,
      loader: Vue,
      tenantSuperUserIsViewing: Option[Tenant],
      tenant: Tenant,
      subscriber: Int,
      subscriberSuperUserIsViewing: Option[Subscriber],
      userType: UserType,
      t: SludgToken
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] =
    div(
      grid(
        RenderOptions(
          props = Some(
            new GridComponentProps(
              subscriber,
              subscriberSuperUserIsViewing,
              p.addersReload,
              p.dashboardComponents.toJSArray,
              p.selectedDashboard,
              p.editMode,
              tenant,
              tenantSuperUserIsViewing,
              userType,
              p.userPresenceEvent,
              p.callGroupStatsEvent,
              p.resetComponents,
              t
            )
          ),
          on = Some(
            GridComponentEvents(
              updateComponents = js.defined(e => {

                p.dashboardComponents = e.dashboardComponents

                /* if you dont want to save, then just reload */
                if (e.saveImmediately) {
                  updateDashboard(apiCalls, loader, p, None, None, None, true)(t)
                  resetConnection(t, p, p.dashboardComponents)
                }
              }),
              /**
                * When the tenant selector prop is updated, an event is received
                * This event comes with a new tenant, and triggers the loadAndSet method
                * New dashboards are loaded based on this tenant
                * This should only happen if you are a ~~Super-User~~
                */

              updateSuperUserSelectedTenant = js.defined(newTenant => {
                p.tenantSuperUserIsViewing = newTenant

                (p.a, p.l, p.token) match {
                  case (Some(apiCalls), Some(y), Some(t)) => {
                    loadAndSet(p.subscriberId, p.tenant, newTenant, apiCalls, y, p.userType, t, p)
                      .map {
                        case Left(error) => logger.debug("Error, Could not load dashboard:" + error)
                        case Right(_) => logger.debug("Successfully loaded")
                      }
                  }
                  case _ => logger.debug("Dashboards could not be reloaded.")
                }
              })
            )
          )
        )
      )
    )

  def loadSubscribers(
      token: SludgToken,
      tenantId: Int,
      apiCalls: ApiCalls,
      l: Vue
  ): Future[Either[String, Some[List[Subscriber]]]] = {
    LoadingFuture.withLoading(
      l,
      apiCalls.getSubscribers(tenantId)(implicitly, token).map {
        case Left(x) => Left(x.toString())
        case Right(x) => Right(Some(x))
      }
    )
  }

  def loadAndSet(
      s: Option[Int],
      t: Option[Tenant],
      theTenantTheSuperUserIsViewing: Option[Tenant],
      apiCalls: ApiCalls,
      l: Vue,
      userType: UserType,
      token: SludgToken,
      p: PageOneType
  ): Future[Either[String, Unit]] = {

    import cats.data._
    import cats.implicits._

    import scala.concurrent.ExecutionContext.Implicits.global
    import scala.concurrent.Future

    type Error = String
    type FutureEither[A] = EitherT[Future, Error, A]

    val getUserAccessKeys: FutureEither[(Int, Tenant)] = EitherT.fromEither[Future]((s, t) match {
      case (Some(s), Some(t)) =>
        logger.debug("Found valid credentials." + "Sid:" + s + " Tid:" + t)
        Right(s, t)
      case _ => Left("User access keys (sid, tid) not found.")
    })

    def getSubscribers(
        token: SludgToken,
        tenant: Tenant
    ): FutureEither[Option[List[Subscriber]]] = {
      EitherT(
        if (userType == SuperUser) loadSubscribers(token, tenant.id, apiCalls, l)
        else Future.successful(Right(None))
      )
    }

    def loadDashboard(
        subscriberId: Int,
        t: Tenant
    ): FutureEither[(List[Dashboard], List[DashboardComponent])] =
      EitherT(loadDashboards(apiCalls, l, p, subscriberId, t.id, userType, token).map {
        case Left(x) => Left("Access forbidden when loading dashboards.")
        case Right(x) => {
          logger.info("Retrieved valid dashboard data.")
          Right(x)
        }
      })

    (for {
      userAccessKeys <- getUserAccessKeys
      //If you are a super-user and are viewing a tenant, then get those subs, otherwise get them from your own tenant
      tenantToGetDashboards = (userType, theTenantTheSuperUserIsViewing) match {
        case (SuperUser, Some(t)) => t
        case _ => userAccessKeys._2
      }
      subscribers <- getSubscribers(token, tenantToGetDashboards)
      loadedApiData <- loadDashboard(userAccessKeys._1, tenantToGetDashboards)
      setValidData <- setDashboardData(
        loadedApiData._1,
        loadedApiData._2,
        subscribers.getOrElse(List()),
        p
      ).pure[FutureEither]
    } yield setValidData).value
  }

  def loadDashboards(
      apiCalls: ApiCalls,
      loader: Vue,
      p: PageOneType,
      subscriberId: Int,
      tenantId: Int,
      userType: UserType,
      token: SludgToken
  ): Future[Either[AccessForbidden, (List[Dashboard], List[DashboardComponent])]] = {
    import cats.data._
    import cats.implicits._

    LoadingFuture.withLoading(
      loader,
      f = (for {
        dashboards <- EitherT(p.userType match {
          case SuperUser => apiCalls.getTenantDashboards(tenantId)(implicitly, token)
          case AdminUser =>
            apiCalls.getUserAcessibleDashboards(tenantId, subscriberId)(implicitly, token)
          case _ => apiCalls.getSubscriberDashboards(tenantId, subscriberId)(implicitly, token)
        }): EitherT[Future, AccessForbidden, List[Dashboard]]
        //If your a super user, you need a subscriberId from the tenant to get components
        superUserLocalPerspective =
          if (userType == SuperUser) {
            dashboards.headOption.map(_.subscriberId)
          } else {
            None
          }
        //If you are not a super user, you use your own id
        s = superUserLocalPerspective.getOrElse(subscriberId)
        /* Categorize into shared and private, then grouped by your dashboards, then sorted by name */
        categorisedDashboards = {
          dashboards.filter(x => x.subscriberId == s && !x.shared).sortBy(_.name) :::
            dashboards.filter(x => x.subscriberId == s && x.shared).sortBy(_.name) :::
            dashboards.filter(x => x.shared && x.subscriberId != s).sortBy(_.name) :::
            //If you are a super user you get to see private dashboards that arent yours
            (if (userType == SuperUser)
               dashboards.filter(x => !x.shared && x.subscriberId != s).sortBy(_.name)
             else List())
        }
        selectedDashboardData <- EitherT {

          val selectedDashboardOpt = getDashboardToLoad(p, categorisedDashboards)
          // Getting Dashboard
          getDashboardData(p, apiCalls, selectedDashboardOpt, tenantId)(token)
        }
      } yield {
        p.subscriberSuperUserIsViewing =
          superUserLocalPerspective.map(s => Subscriber(0, "", s, None, "", "", true))

        (categorisedDashboards, selectedDashboardData.map(_.dashboard).getOrElse(List()))
      }).value
    )
  }

  def getDashboardData(
      p: PageOneType,
      apiCalls: ApiCalls,
      selectedDashboardOpt: Option[Dashboard],
      tenantId: Int
  )(implicit token: SludgToken) = {
    selectedDashboardOpt match {
      case Some(selectedDashboard) =>
        apiCalls
          .getDashboard(tenantId, selectedDashboard.subscriberId, selectedDashboard.id)
          .map({
            case r @ Right(Some(d)) => p.selectedDashboard = Some(selectedDashboard); r
            case l => l
          })
      case None =>
        logger.debug("Dashboard doesn't exist")
        Future.successful(Right(None))
    }
  }

  def getDashboardToLoad(
      p: PageOneType,
      categorisedDashboards: List[Dashboard]
  ): Option[Dashboard] = {
    val idParamOpt: String = p.$route.asInstanceOf[js.Dynamic].params.dashboardId.toString
    val routeDashboardIdOpt = Try(idParamOpt.toInt).toOption

    def getLocalDashboardIfValid: Option[Dashboard] =
      LocalStorage(dashboardStoreKey).flatMap(json => Json.parse(json).validate[Dashboard].asOpt)

    def getExistingDashboard(routeDashboardIdOpt: Option[Int]): Option[Dashboard] =
      routeDashboardIdOpt
        .flatMap(routeDashboardId => categorisedDashboards.find(_.id == routeDashboardId))
        .orElse(getLocalDashboardIfValid.flatMap(d => categorisedDashboards.find(_.id == d.id)))
        .orElse(categorisedDashboards.headOption)

    getExistingDashboard(routeDashboardIdOpt)
  }

  def setDashboardData(
      categorisedDashboards: List[Dashboard],
      dashboardComponents: List[DashboardComponent],
      subscribers: List[Subscriber],
      p: PageOneType
  ): Unit = {
    //Setting subscribers
    logger.debug("Setting subscribers of tenant")
    p.subscribersOfTenantSuperUserIsViewing = Some(subscribers)

    //Setting the list of dashboards
    logger.debug("Setting dashboards")
    p.dashboards = categorisedDashboards

    //Setting the dashboards components of the currently selected dashboard
    p.dashboardComponents = dashboardComponents

    //Selected dashboard owner
    p.subscriberSuperUserIsViewing =
      if (p.dashboards.isEmpty) subscribers.headOption
      else
        subscribers.collectFirst {
          case i if i.subscriberId == p.selectedDashboard.map(_.subscriberId).getOrElse(0) => i
        }
  }

  def createDashboard(
      apiCalls: ApiCalls,
      loader: Vue,
      c: PageOneType,
      tenantId: Int,
      subscriberId: Int,
      name: String,
      shared: Boolean,
      favourite: Boolean,
      cloneSelected: Boolean = false,
      token: SludgToken
  ): Unit = {
    LoadingFuture.withLoading(
      loader,
      apiCalls
        .createDashboard(
          tenantId,
          subscriberId,
          DashboardData(
            if (cloneSelected)
              c.dashboardComponents
            else Nil,
            name,
            shared,
            favourite
          )
        )(implicitly, token)
        .map {
          case Left(x) => Left(x.toString)
          case Right(x) =>
            x match {
              case Some(x) =>
                val newlyClonedDashboard =
                  Dashboard(x, tenantId, subscriberId, name, shared, favourite)
                c.dashboards ::= newlyClonedDashboard
                c.selectedDashboard = Some(newlyClonedDashboard)
                if (!cloneSelected) {
                  c.dashboardComponents = List()
                }
                reloadComponents(apiCalls, loader, c, newlyClonedDashboard, token)
              case None => logger.debug("Dashboard could not be creted. But why?")
            }
        }
    )
  }

  def cancelEditing(p: PageOneType, apiCalls: ApiCalls, loader: Vue, token: SludgToken) = {
    p.selectedDashboard match {
      case Some(d) =>
        p.editMode = EditingMode.Fixed
        p.dashboardComponents = p.lastSavedDashboardComponents
        p.resetComponents = !p.resetComponents
      case None => logger.debug("No Dashboard selected")
    }
  }

  def updateDashboard(
      apiCalls: ApiCalls,
      loader: Vue,
      p: PageOneType,
      newShareValue: Option[Boolean],
      newFavouriteValue: Option[Boolean],
      newName: Option[String],
      recalculateAdders: Boolean = false,
      savingNewComponents: Boolean = false
  )(implicit token: SludgToken): Future[Either[String, Unit]] = {
    if (savingNewComponents) resetConnection(token, p, p.dashboardComponents)
    p.selectedDashboard match {
      case None => Future.successful(Left("No dashboard selected"))
      case Some(selectedDashboard) =>
        if (recalculateAdders) {
          p.addersReload = !p.addersReload
        }
        LoadingFuture.withLoading(
          loader,
          apiCalls
            .updateDashboard(
              selectedDashboard.tenantId,
              selectedDashboard.subscriberId,
              selectedDashboard.id,
              p.dashboardComponents,
              newName.getOrElse(selectedDashboard.name),
              newShareValue.getOrElse(selectedDashboard.shared),
              newFavouriteValue.getOrElse(selectedDashboard.favourited)
            )
            .map {
              case Left(x) => Left(x.message)
              case Right(x) =>
                if (x) {
                  val updatedDashboard = Dashboard(
                    selectedDashboard.id,
                    selectedDashboard.tenantId,
                    selectedDashboard.subscriberId,
                    newName.getOrElse(selectedDashboard.name),
                    newShareValue.getOrElse(selectedDashboard.shared),
                    newFavouriteValue.getOrElse(selectedDashboard.favourited)
                  )

                  p.selectedDashboard = Some(updatedDashboard)

                  p.dashboards = p.dashboards.map {
                    case x if x.id == selectedDashboard.id => updatedDashboard;
                    case x => x
                  }

                  p.editMode = EditingMode.Fixed
                  Right(())
                } else {
                  Left("Could not parse result when updating dashboard.")
                }
            }
        )
    }
  }

  def deleteDashboard(
      apiCalls: ApiCalls,
      loader: Vue,
      data: PageOneType,
      idToDelete: Int,
      sid: Int,
      tid: Int,
      previouslySelectedDashboardId: Option[Int]
  )(implicit token: SludgToken): Future[Either[String, Unit]] = {
    /* If you delete the dashboard currently selected, a new dashboard is loaded with new components */
    LoadingFuture.withLoading(
      loader,
      for {
        delete <- apiCalls.deleteDashboard(tid, sid, idToDelete).map {
          case Left(_) =>
            Left("Dashboard could not be deleted. Access forbidden.")
          case Right(x) =>
            if (x) {
              data.dashboards = data.dashboards.filterNot(_.id == idToDelete)

              // if selectedDashboard changes then loads the component for the newly selected dashboard
              data.selectedDashboard = data.dashboards.headOption

              data.selectedDashboard match {
                case Some(dashboard)
                    if previouslySelectedDashboardId.exists(id => dashboard.id != id) =>
                  data.dashboardComponents = Nil
                case _ => () // nothing
              }
              Right(())
            } else {
              Left("Dashboard could not be deleted.")
            }
        }
        maybeLoadNew <- data.selectedDashboard match {
          case Some(newlySelectedDashboard) =>
            if (previouslySelectedDashboardId.contains(newlySelectedDashboard.id)) {
              // Do nothing
              Future.successful(delete) // matching types
            } else {
              apiCalls
                .getDashboard(tid, newlySelectedDashboard.subscriberId, newlySelectedDashboard.id)
                .map {
                  case Left(x) => Left("New dashboard could not be loaded.")
                  case Right(x) =>
                    x match {
                      case Some(newDashboardLoaded) =>
                        data.dashboardComponents = newDashboardLoaded.dashboard
                        Right(())
                      case None => Left("New Dashboard could not be parsed.")
                    }
                }
            }
          case None => Future.successful(Left("No dashboard selected."))
        }
      } yield maybeLoadNew
    )
  }

  def reloadComponents(
      apiCalls: ApiCalls,
      loader: Vue,
      p: PageOneType,
      d: Dashboard,
      token: SludgToken
  ): Future[Either[AccessForbidden, Unit]] = {

    p.selectedDashboard = Some(d)
    LocalStorage.update(dashboardStoreKey, Json.toJson(d).toString())
    LoadingFuture.withLoading(
      loader,
      apiCalls
        .getDashboard(d.tenantId, d.subscriberId, d.id)(implicitly, token)
        .map(_.map(res => {

          p.dashboardComponents = res
            .map(_.dashboard)
            .getOrElse(List())

          p.resetComponents = !p.resetComponents
          resetConnection(token, p, p.dashboardComponents)
          Right(())
        }))
    )
  }

  def resetConnection(
      token: SludgToken,
      p: PageOneType,
      dashboardComponents: List[DashboardComponent]
  )(implicit ec: ExecutionContext) = {
    //reset connection only if needed
    closeConnection(p)
    makeConnection(
      p.config,
      extractTenantIds(dashboardComponents),
      extractEventTypes(
        dashboardComponents.filterNot(_.eventSubscription.contains("[stub]")).map(_.componentType)
      ),
      p
    )(token, ec)
  }

  class PageOneWatcher() extends js.Object {
    def selectedDashboard(dashboardOpt: Option[Dashboard]) = {
      if (js.isUndefined(dashboardOpt)) {} else {
        val c = this.asInstanceOf[PageOneType]
        dashboardOpt match {
          case Some(dashboard) => c.$router.push(s"${dashboard.id}")
          case None => logger.debug(s"No Selected Dashboard: ${dashboardOpt}")
        }
      }
    }

    def updatedDashboardComponentsWithStubs(updatedDashboard: List[DashboardComponent]) = {
      if (js.isUndefined(updatedDashboard)) {} else {
        val c = this.asInstanceOf[PageOneType]
        val updated = updatedDashboard.filterNot(_.eventSubscription.contains("[stub]"))
        val originalDashboard = c.dashboardComponents
        c.hasUnsavedChanges = !updated.forall(originalDashboard contains _)
      }
    }
  }

  class PageOneData(userTypeIn: UserType, var config: DashboardConfig) extends js.Object {

    // Storing for use in watcher
    var a: Option[ApiCalls] = None
    var l: Option[Vue] = None
    var token: Option[SludgToken] = None

    // Data
    var dashboardComponents: List[DashboardComponent] = List()
    var lastSavedDashboardComponents: List[DashboardComponent] = List()

    var hasUnsavedChanges: Boolean = false

    var dashboards: List[Dashboard] = List()
    var selectedDashboard: Option[Dashboard] = None
    var lastSelectedDashboard: Option[Dashboard] = None

    //Flag used to indicate if you want to reload the grid cells
    var addersReload = false
    var selectedSubscribers: List[Subscriber] = List()
    var selected: Option[Subscriber] = None
    var resetComponents = false

    /* State */
    var editMode: EditingMode = EditingMode.Fixed
    var userType: UserType = userTypeIn
    var standardUserRoleChecked: Boolean = false

    /* Passed as props */
    var subscriberId: Option[Int] = None
    var tenant: Option[Tenant] = None
    var tenantSuperUserIsViewing: Option[Tenant] = None
    var subscriberSuperUserIsViewingId: Option[Int] = None
    var subscriberSuperUserIsViewing: Option[Subscriber] = None

    /* Events */
    var socketCloser: Option[IO[Unit]] = None

    //SuperUser Subscribers
    var subscribersOfTenantSuperUserIsViewing: Option[List[Subscriber]] = None

    var userPresenceEvent: UserPresenceEvent =
      UserPresenceEvent(0L, "", "", None, UserPresenceEventType.Idle)
    var callGroupStatsEvent: CallGroupStatsEvent =
      CallGroupStatsEvent("", 0L, "", CallGroupCallStats(0, 0, 0))
  }

}
